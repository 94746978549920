<template>
  <v-container fluid>
    <v-row align="start" dense>
      <v-col class="d-flex" cols="3" xs="2" dense>
        <v-select
          v-model="selectedSearchField"
          :items="searchFields"
          label="Search Field"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="2" xs="2" dense>
        <v-select v-model="selectedOperator" :items="operators" label="Operator" outlined dense></v-select>
      </v-col>
      <v-col v-if="searchType == 'text'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model.trim="searchQuery"
          label="Search Text"
          outlined
          dense
          @keyup.enter="searchCareEvents"
        ></v-text-field>
      </v-col>
      <v-col v-if="searchType == 'float'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model="searchQuery"
          lazy-validation
          label="Search Value"
          outlined
          dense
          @keyup.enter="searchCareEvents"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="searchType == 'constant' || searchType == 'searchableConstant'"
        class="d-flex"
        cols="5"
        xs="4"
        dense>
        <v-autocomplete
          v-model="searchQuery"
          :items="constantItems"
          :label="constantLabel"
          outlined
          dense
          @keyup.enter="searchCareEvents"
        ></v-autocomplete>
      </v-col>
      <v-col align="start" justify="start" class="d-flex" dense>
        <v-btn
          v-if="searchType == 'text' || searchType == 'float'"
          color="blue darken-2"
          @click="searchCareEvents"
          dark
          dense
        >Search</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="careEventsDataTable != null"
      :headers="headers"
      :items="careEventsDataTable"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :items-per-page="100"
      dense
      item-key="publicName"
      class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDialogCareEventDetails(item)">mdi-magnify</v-icon>

        <!-- status=revise actions -->
        <template v-if="isTibiCareEventStatusRevise(item.id)">
          <v-icon small class="mr-3" @click="setTibiCareEventStatusReject(item.id)">mdi-close-box-outline</v-icon>
          <v-icon small class="mr-3" @click="setTibiCareEventStatusApprove(item.id)">mdi-checkbox-marked-outline</v-icon>
        </template>

        <!-- status=submit actions -->
        <template v-if="isTibiCareEventStatusSubmit(item.id)">
          <v-icon small class="mr-3" @click="setTibiCareEventStatusRevise(item.id)">mdi-rotate-left</v-icon>
          <v-icon small class="mr-3" @click="setTibiCareEventStatusReject(item.id)">mdi-close-box-outline</v-icon>
          <v-icon small class="mr-3" @click="setTibiCareEventStatusApprove(item.id)">mdi-checkbox-marked-outline</v-icon>
        </template>

        <!-- status=approve actions --> <!-- DISABLED as 11/22
        <template v-if="isTibiCareEventStatusApprove(item.id)">
          <v-icon small class="mr-3" @click="setTibiCareEventStatusRevise(item.id)">mdi-rotate-left</v-icon>
          <v-icon small class="mr-3" @click="setTibiCareEventStatusReject(item.id)">mdi-close-box-outline</v-icon>
        </template> -->

        <!-- status=reject actions -->
        <template v-if="isTibiCareEventStatusReject(item.id)">
          <v-icon small class="mr-3" @click="setTibiCareEventStatusRevise(item.id)">mdi-rotate-left</v-icon>
          <v-icon small @click="setTibiCareEventStatusApprove(item.id)">mdi-checkbox-marked-outline</v-icon>
        </template>

      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000">
      <v-card>
          <v-card-title>
            <span class="headline">{{ this.dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <strong>ID: </strong>{{dialogTibiCareEvent.id}}<br />
            <strong>eventName: </strong>{{dialogTibiCareEvent.eventName}}<br />
            <strong>eventDescription: </strong>{{dialogTibiCareEvent.eventDescription}}<br />
            <strong>eventCategory: </strong>{{dialogTibiCareEvent.eventCategory}}<br />
            <strong>eventType: </strong>{{dialogTibiCareEvent.eventType}}<br />
            <strong>providerSub: </strong>{{dialogTibiCareEvent.sub}}<br />
            <strong>providerEmail: </strong>{{dialogTibiCareEvent.email}}<br />
            <strong>providerPublicName: </strong>{{dialogTibiCareEvent.providerPublicName}}<br />

            <strong>subjectCategories:</strong>
            <ul>
              <li v-for="(subjectCategory, i) in dialogTibiCareEvent.subjectCategories" :key="i">
                <template>{{ subjectCategory }}</template>
              </li>
            </ul>

            <strong>primaryTopics:</strong>
            <ul>
              <li v-for="(primaryTopic, i) in dialogTibiCareEvent.primaryTopics" :key="i">
                <template>{{ primaryTopic }}</template>
              </li>
            </ul>

            <strong>participantProfiles:</strong>
            <ul>
              <li v-for="(participantProfile, i) in dialogTibiCareEvent.participantProfiles" :key="i">
                <template>{{ participantProfile }}</template>
              </li>
            </ul>

            <strong>eventGoals:</strong>
            <ul>
              <li v-for="(eventGoal, i) in dialogTibiCareEvent.eventGoals" :key="i">
                <template>{{ eventGoal }}</template>
              </li>
            </ul>

            <strong>sessionCount: </strong>{{dialogTibiCareEvent.sessionCount}}<br />
            <strong>sessionDuration: </strong>{{dialogTibiCareEvent.sessionDuration}}<br />
            <template v-if="dialogTibiCareEvent.sessionFee">
              <strong>sessionFee: </strong>${{dialogTibiCareEvent.sessionFee / 100}}<br />
            </template>
            <template v-if="dialogTibiCareEvent.seriesFee">
              <strong>seriesFee: </strong>${{dialogTibiCareEvent.seriesFee / 100}}<br />
            </template>

            <template v-if="dialogTibiCareEvent.groupType && dialogTibiCareEvent.groupType.length > 0">
              <strong>groupType: </strong>{{dialogTibiCareEvent.groupType}}<br />
            </template>
            <strong>groupSize: </strong>{{dialogTibiCareEvent.groupSize}}<br />

            <strong>tibiEventType: </strong>{{dialogTibiCareEvent.tibiEventType}}<br />

            <strong>status: </strong>{{dialogTibiCareEvent.status}}<br />
            <strong>statusHistory: </strong>{{dialogTibiCareEvent.statusHistory}}<br />

            <template v-if="dialogTibiCareEvent.meetingFrequency && dialogTibiCareEvent.meetingFrequency.length > 0">
              <strong>meetingFrequency: </strong>{{dialogTibiCareEvent.meetingFrequency}}<br />
            </template>
            <strong>desiredMeetingDayAndTimes:</strong>
            <ul>
              <li v-for="(item, i) in dialogTibiCareEvent.desiredMeetingDayAndTimes" :key="i">
                <template>{{ item }}</template>
              </li>
            </ul>
            <strong>desiredEventStartDateRange: </strong>{{desiredEventStartDateRange}}<br />
            <template v-if="dialogTibiCareEvent.sessionTimeUtcs && dialogTibiCareEvent.sessionTimeUtcs.length > 0">
              <strong>sessionTimeUtcs:</strong>
              <ul>
                <li v-for="(sessionTimeUtc, i) in dialogTibiCareEvent.sessionTimeUtcs" :key="i">
                  <template>{{ sessionTimeUtc }}</template>
                </li>
              </ul>
            </template>

            <strong>calendarId: </strong>{{dialogTibiCareEvent.calendarId}}<br />
            <strong>appointmentTypeId: </strong>{{dialogTibiCareEvent.appointmentTypeId}}<br />

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <!-- status=revise actions -->
              <template v-if="isTibiCareEventStatusRevise(dialogTibiCareEvent.id)">
                <v-icon small class="mr-3" @click="setTibiCareEventStatusReject(dialogTibiCareEvent.id)">mdi-close-box-outline</v-icon>
                <v-icon small class="mr-3" @click="setTibiCareEventStatusApprove(dialogTibiCareEvent.id)">mdi-checkbox-marked-outline</v-icon>
              </template>

              <!-- status=submit actions -->
              <template v-if="isTibiCareEventStatusSubmit(dialogTibiCareEvent.id)">
                <v-icon small class="mr-3" @click="setTibiCareEventStatusRevise(dialogTibiCareEvent.id)">mdi-rotate-left</v-icon>
                <v-icon small class="mr-3" @click="setTibiCareEventStatusReject(dialogTibiCareEvent.id)">mdi-close-box-outline</v-icon>
                <v-icon small class="mr-3" @click="setTibiCareEventStatusApprove(dialogTibiCareEvent.id)">mdi-checkbox-marked-outline</v-icon>
              </template>

              <!-- status=approve actions --> <!-- DISABLED as 11/22
              <template v-if="isTibiCareEventStatusApprove(dialogTibiCareEvent.id)">
                <v-icon small class="mr-3" @click="setTibiCareEventStatusRevise(dialogTibiCareEvent.id)">mdi-rotate-left</v-icon>
                <v-icon small class="mr-3" @click="setTibiCareEventStatusReject(dialogTibiCareEvent.id)">mdi-close-box-outline</v-icon>
              </template> -->

              <!-- status=reject actions -->
              <template v-if="isTibiCareEventStatusReject(dialogTibiCareEvent.id)">
                <v-icon small class="mr-3" @click="setTibiCareEventStatusRevise(dialogTibiCareEvent.id)">mdi-rotate-left</v-icon>
                <v-icon small class="mr-3" @click="setTibiCareEventStatusApprove(dialogTibiCareEvent.id)">mdi-checkbox-marked-outline</v-icon>
              </template>

              <!-- close dialog box -->
              <v-icon small class="mr-3" @click="dialogClose">mdi-cancel</v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarMessages[snackbarState] }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div class="text-center" v-if="showProgressCircular">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";
import * as Utils from "../utils/Utils.js";

export default {
  name: "reviewProposals",
  data() {
    return {
      searchFields: [
        "All Fields",
        "Status",
        "EventName",
        "EventCategory",
        "EventType",
        "Sessions",
      ],
      selectedSearchField: "All Fields",

      // placeholder operators, updates the selected operator via v-model
      operators: ["Contains", "IsEqualTo", "StartsWith", "EndsWith"],
      selectedOperator: "Contains",

      // applicable operators depending on they type, e.g text, float and constants
      textOperators: ["Contains", "StartsWith", "EndsWith", "IsEqualTo"],
      constantOperators: ["IsEqualTo"],
      searchableConstantOperators: [
        "IsEqualTo",
        "Contains",
        "StartsWith",
        "EndsWith",
      ],
      floatOperators: [
        "EqualTo",
        "LessThan",
        "LessThanOrEqualTo",
        "GreaterThan",
        "GreaterThanOrEqualTo",
      ],

      // initiallize search type and query and also constants used for fixed fields (e.g publicName etc)
      searchType: "text",
      searchQuery: "",
      constantItems: [],
      constantLabel: "",
      constantItemsMap: {},

      // stores tibiProvider objects fetched from TibiProvider DDB
      tibiProviderDict: {}, // key:<sub>.public, value:TibiProvider

      // stores tibiCareEvent (proposals) fetched from TibiCareEvent DDB
      tibiCareEventDict: {}, // key:id, value:TibiCareEvent
      selectedTibiCareEvents: [], // selected tibi care events (proposals) based on search criteria
      includeAllTibiCareEventStatuses: false, // set to true to include TIBI_CARE_EVENT_STATUSES even if they do not exist

      // stores tibiAppointmentType objects fetched from TibiAppointmentType DDB keyed on appointmentTypeId
      tibiAppointmentTypeDict: {}, // key:appointmentTypeId, value:TibiAppointmentType

      // set to true when all required care data is fetched and loaded
      isCareDataLoaded: false,

      // when a proposal is about to be approved, its session start times will be fetched from Acuity and set to below
      sessionTimeUtcs: [],

      // provider (results) data table column headers
      headers: [
      {
        text: "EventName",
        align: "start",
        sortable: true,
        value: "eventName",
      },
     {
        text: "EventCategory",
        align: "start",
        sortable: true,
        value: "eventCategory",
      },
     {
        text: "EventType",
        align: "start",
        sortable: true,
        value: "eventType",
      },
     {
        text: "Sessions",
        align: "start",
        sortable: true,
        value: "sessions",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "status",
      },
      { text: "Actions",
        align: "start",
        sortable: false,
        value: "actions"
      },
    ],
    careEventsDataTable: null,

    // dialog that shows careEvent details, it allows admins to set status={revise, approve, reject}
    dialog: false,
    dialogTitle: "Care Event Details",
    dialogTibiCareEvent: null,

    // snackbar to show a message based on actions taken in data table
    snackbarState: 0,
    snackbarMessages: [
      /* 0 */ "Please enter a valid number to query careEvents based on number of sessions they have.",
      /* 1 */ "Succesfully updated the careEvent status!",
      /* 2 */ "Failed to update the careEvent status!",
      /* 3 */ "Failed to retrieve appointmentTypes from Acuity",
      /* 4 */ "AppintmentTypeID you entered is not found on Acuity",
      /* 5 */ "Failed to retrieve classAvailability from Acuity",
      /* 6 */ "Failed validation: eventName does not match NAME on Acuity",
      /* 7 */ "Failed validation: eventDescription does not match DESCRIPTION on Acuity",
      /* 8 */ "Failed validation: sessionDuration does not match DURATION on Acuity",
      /* 9 */ "Failed validation: ACCESS is not set to Private on Acuity",
      /* 10*/ "Failed validation: groupSize does not match 'Max number of people' on Acuity",
      /* 11*/ "Failed validation: groupType:open/closed does not match 'Clients must sign up for all' check on Acuity",
      /* 12*/ "Failed validation: sessionCount does not match number of sessions on Acuity (also make sure an earlier appointmentTypeID is NOT being reused)",
      /* 13*/ "Failed validation: last session start time goes beyond 365 days from now",
      /* 14*/ "Failed validation: event is currently not offered on Acuity",
      /* 15*/ "Failed validation: Zoom license is not available for following session(s):", // will be set dynamically
      /* 16*/ "Failed to allocate Zoom license for at least one of the sessions",
      /* 17 */ "Succesfully submitted the careEvent approval, please check your email for final status (or errors if any)",
    ],
    snackbarFlag: false,
    snackbarTimeout: 10000,
    snackbarColorSuccess: "green darken-2",
    snackbarColorFailure: "red darken-2",
    snackbarColor: "green darken-2",
    snackbarUnavailableZoomLicenseMessage: "Failed validation: Zoom license is not available for following session(s):",

    // to show spinner while long (~30 secs) setTibiCareEventStatusApprove is running
    showProgressCircular: false,
    };
  },
  computed: {
    desiredEventStartDateRange: function() {
      if (this.dialogTibiCareEvent == null
          || this.dialogTibiCareEvent.desiredEventStartDateRange == null
          || this.dialogTibiCareEvent.desiredEventStartDateRange.length < 2) {
        return "";
      }
      return `${this.dialogTibiCareEvent.desiredEventStartDateRange[0]} ~ ${this.dialogTibiCareEvent.desiredEventStartDateRange[1]}`;
     }
  },
  async created() {
    await this.fetchCareData();
  },
  methods: {
    async fetchCareData() {
      console.time("proposalsTimer");
      this.showProgressCircular = true;
      const providerPromise = this.updateTibiProviders();
      const careEventsPromise = this.updateTibiCareEvents();
      const appointmentTypesPromise = this.updateTibiAppointmentTypes();
      await Promise.all([providerPromise, appointmentTypesPromise, careEventsPromise]).then((results) => {
        this.isCareDataLoaded = true;
        this.showProgressCircular = false;
        console.timeEnd("proposalsTimer");
      });
    },
    async updateTibiProviders() {
      let response = await API.getPublicTibiProviderApi();
      (response.value || []).map(tibiProvider => this.tibiProviderDict[tibiProvider.id] = tibiProvider);
    },
    async updateTibiCareEvents() {
      let response = await API.getAllTibiCareEventsApi();
      (response.value || []).map(tibiCareEvent => this.tibiCareEventDict[tibiCareEvent.id] = tibiCareEvent);

      // update statuses of TibiCareEvents statuses to be shown in drop-down (add all constants)
      let statusConstants = [Constants.ACTION_REQUIRED];
      statusConstants = statusConstants.concat(Utils.getSortedUniqueValues(Object.values(this.tibiCareEventDict), "status"));
      this.constantItemsMap["Status"] = statusConstants;
      if (this.includeAllTibiCareEventStatuses) {
        for (let status of Constants.TIBI_CARE_EVENT_STATUSES) {
          if (this.constantItemsMap["Status"].includes(status) == false) {
            this.constantItemsMap["Status"].push(status);
          }
        }
        this.constantItemsMap["Status"] = this.constantItemsMap["Status"].sort();
      }

      // update TibiCareEvent event names to be shown in drop-down
      this.constantItemsMap["EventName"] = Utils.getSortedUniqueValues(
        Object.values(this.tibiCareEventDict), "eventName");

      // update TibiCareEvent event categories to be shown in drop-down
      this.constantItemsMap["EventCategory"] = Utils.getSortedUniqueValues(
        Object.values(this.tibiCareEventDict), "eventCategory");

      // update TibiCareEvent event types to be shown in drop-down
      this.constantItemsMap["EventType"] = Utils.getSortedUniqueValues(
        Object.values(this.tibiCareEventDict), "eventType");
    },
    async updateTibiAppointmentTypes() {
      let response = await API.getTibiAppointmentTypeScanApi();
      this.tibiAppointmentTypeDict = {};
      response.value.forEach(appointmentType => {
        this.tibiAppointmentTypeDict[appointmentType.appointmentTypeId] = appointmentType;
      });
    },
    async searchCareEvents() {
      if (!this.isCareDataLoaded) {
        await this.fetchCareData();
      }

      // apply search criteria and update result table based on selected tibi providers
      this.selectedTibiCareEvents = [];
      const field = this.selectedSearchField;
      if (field == "All Fields") {
        this.selectedTibiCareEvents = this.searchAllFields();
      } else if (field == "Status") {
        this.selectedTibiCareEvents = this.searchStatusField();
      } else if (field == "EventName") {
        this.selectedTibiCareEvents = this.searchEventNameField();
      } else if (field == "EventCategory") {
        this.selectedTibiCareEvents = this.searchConstantField("eventCategory");
      } else if (field == "EventType") {
        this.selectedTibiCareEvents = this.searchConstantField("eventType");
      } else if (field == "Sessions") {
        this.selectedTibiCareEvents = this.searchSessionsField();
      }
      this.constructCareEventsDataTable();
    },
    searchAllFields() {
      var results = [];
      let query = this.searchQuery.toLowerCase().trim(); // case-insensitive search
      let operator = this.selectedOperator;
      for (let tibiCareEvent of Object.values(this.tibiCareEventDict)) {
        if (Utils.objectHasTextMatch(tibiCareEvent, operator, query)) {
          results.push(tibiCareEvent);
          continue;
        }
        let tibiAppointmentType = this.tibiAppointmentTypeDict[tibiCareEvent.appointmentTypeId] || {};
        if (Utils.objectHasTextMatch(tibiAppointmentType, operator, query)) {
          results.push(tibiCareEvent);
          continue;
        }
        let tibiProviderKey = tibiCareEvent.sub + ".public";
        let tibiProvider = this.tibiProviderDict[tibiProviderKey] || {};
        if (Utils.objectHasTextMatch(tibiProvider, operator, query)) {
          results.push(tibiCareEvent);
          continue;
        }
      }
      return results;
    },
    searchStatusField() {
      var results = [];
      for (let tibiCareEvent of Object.values(this.tibiCareEventDict)) {
        if (tibiCareEvent.status == this.searchQuery) { // since this is constantField, constantOperators=[IsEqual]
          results.push(tibiCareEvent);
        } else if (this.searchQuery == Constants.ACTION_REQUIRED) {
          if (tibiCareEvent.status == Constants.TIBI_CARE_EVENT_SUBMIT) {
            results.push(tibiCareEvent);
          }
        }
      }
      return results;
    },
    searchEventNameField() {
      var results = [];
      let query = this.searchQuery.toLowerCase().trim(); // case-insensitive search
      let operator = this.selectedOperator;
      for (let tibiCareEvent of Object.values(this.tibiCareEventDict)) {
        let valueString = String(tibiCareEvent.eventName).toLowerCase().trim();
        if (operator == "Contains" && valueString.includes(query)) {
          results.push(tibiCareEvent);
          continue;
        }
        if (operator == "IsEqualTo" && valueString == query) {
          results.push(tibiCareEvent);
          continue;
        }
        if (operator == "StartsWith" && valueString.startsWith(query)) {
          results.push(tibiCareEvent);
          continue;
        }
        if (operator == "EndsWith" && valueString.endsWith(query)) {
          results.push(tibiCareEvent);
          continue;
        }
      }
      return results;
    },
    searchConstantField(fieldName) {
      var results = [];
      for (let tibiCareEvent of Object.values(this.tibiCareEventDict)) {
        if (tibiCareEvent[fieldName] == this.searchQuery) { // since this is constantField, constantOperators=[IsEqual]
          results.push(tibiCareEvent);
        }
      }
      return results;
    },
    searchSessionsField() {
      var results = [];
      let query = isNaN(this.searchQuery) || this.searchQuery.length == 0 ? -1 : parseInt(this.searchQuery);
      if (query < 0) {
        this.snackbarState = 0;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return [];
      }
      let operator = this.selectedOperator;
      for (let tibiCareEvent of Object.values(this.tibiCareEventDict)) {
        let sessionCount = tibiCareEvent.sessionCount;
        if (Utils.valueNumberMatch(sessionCount, operator, query)) {
          results.push(tibiCareEvent);
        }
      }
      return results;
    },
    constructCareEventsDataTable() {
      this.careEventsDataTable = [];
      this.selectedTibiCareEvents.sort((a, b) => a.eventName - b.eventName);
      this.selectedTibiCareEvents.forEach(tibiCareEvent => {
        var row = {};
        row["id"] = tibiCareEvent.id;
        row["eventName"] = tibiCareEvent.eventName;
        row["status"] = tibiCareEvent.status;
        row["eventCategory"] = tibiCareEvent.eventCategory;
        row["eventType"] = tibiCareEvent.eventType;
        row["sessions"] = tibiCareEvent.sessionCount;
        this.careEventsDataTable.push(row);
      });
    },
    getDialogCareEventDetails(row) {
      let tibiCareEvent = this.tibiCareEventDict[row.id];
      this.dialogTibiCareEvent = { ...tibiCareEvent }
      let tibiProviderKey = tibiCareEvent.sub + ".public";
      let tibiProvider = this.tibiProviderDict[tibiProviderKey];
      this.dialogTibiCareEvent["providerPublicName"] = tibiProvider.publicName;
      this.dialog = true;
    },
    isTibiCareEventStatusSubmit(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      return tibiCareEvent.status == "submit";
    },
    isTibiCareEventStatusApprove(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      return tibiCareEvent.status == "approve";
    },
    isTibiCareEventStatusReject(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      return tibiCareEvent.status == "reject";
    },
    isTibiCareEventStatusRevise(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      return tibiCareEvent.status == "revise";
    },
    async setTibiCareEventStatusApprove(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      this.dialogTibiCareEvent = { ...tibiCareEvent }; // used in allocateZoomLicenses
      let promptText = `Approve "${tibiCareEvent.eventName}" and allocate Zoom licenses?\n`;
      promptText += `\nPlease enter appointmentTypeID created on Acuity.`;
      let appointmentTypeId = prompt(promptText);
      this.dialogClose();
      this.showProgressCircular = true;
      console.time("setTibiCareEventStatusApproveTimer");
      if (appointmentTypeId != null
          && await this.isAcuityEntryValid(tibiCareEvent, appointmentTypeId)
          && await this.allocateZoomLicenses()) {
        // api call takes ~40secs and times out, so do not wait for the response (it will be emails)
        API.approveTibiCareEventApi(id, appointmentTypeId, this.sessionTimeUtcs.join(Constants.STRING_SEPARATOR));
        this.snackbarState = 17;
        this.snackbarColor = this.snackbarColorSuccess;
        this.snackbarFlag = true;
        const providerPromise = this.updateTibiProviders();
        const careEventsPromise = this.updateTibiCareEvents();
        const appointmentTypesPromise = this.updateTibiAppointmentTypes();
        await Promise.all([providerPromise, appointmentTypesPromise, careEventsPromise]).then((results) => {
          this.isCareDataLoaded = true;
        });
        this.resetSearch();
        this.searchCareEvents();
      }
      this.showProgressCircular = false;
      console.timeEnd("setTibiCareEventStatusApproveTimer");
    },
    async setTibiCareEventStatusReject(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      if (confirm(`Reject "${tibiCareEvent.eventName}" to be on Tibi Health?`)) {
        let response = await API.updateTibiCareEventFieldApi(id, "status", "reject");
        await this.processUpdateTibiCareEventResponse(response);
        this.dialogClose();
      }
    },
    async setTibiCareEventStatusRevise(id) {
      let tibiCareEvent = this.tibiCareEventDict[id];
      if (confirm(`Request revision "${tibiCareEvent.eventName}" to be on Tibi Health?`)) {
        let response = await API.updateTibiCareEventFieldApi(id, "status", "revise");
        await this.processUpdateTibiCareEventResponse(response);
        this.dialogClose();
      }
    },
    async isAcuityEntryValid(tibiCareEvent, appointmentTypeId) {
      console.time("isAcuityEntryValidTimer");
      let appointmentTypesResponse = await API.getAcuityAppointmentTypesApi();
      if (appointmentTypesResponse.status != 0) {
        this.snackbarState = 3;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }
      // console.log(`appointmentTypesResponse: ${JSON.stringify(appointmentTypesResponse)}`);
      let appointmentType = appointmentTypesResponse.value.filter(a => a["id"].toString() == appointmentTypeId);
      if (appointmentType.length != 1) {
        this.snackbarState = 4;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }
      appointmentType = appointmentType[0];
      // console.log(`appointmentType: ${JSON.stringify(appointmentType)}`);

      let classAvailabilityResponse = await API.getAcuityClassAvailabilityApi(appointmentTypeId);
      if (classAvailabilityResponse.status != 0) {
        this.snackbarState = 5;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }
      let classAvailability = classAvailabilityResponse.value;
      // console.log(`classAvailability: ${JSON.stringify(classAvailability)}`);

      // make sure event is offered on Acuity
      if (classAvailability.length == 0) {
        this.snackbarState = 14;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate eventName
      let acuityName = classAvailability.length > 0 ? classAvailability[0].name : "";
      if (tibiCareEvent.eventName != acuityName) {
        this.snackbarState = 6;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate eventDescription
      let acuityDescription = classAvailability.length > 0 ? classAvailability[0].description : "";
      if (tibiCareEvent.eventDescription != acuityDescription) {
        this.snackbarState = 7;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate sessionDuration
      let acuityDuration = appointmentType.duration || -1;
      if (tibiCareEvent.sessionDuration != acuityDuration) {
        this.snackbarState = 8;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate ACCESS=private on acuity
      let acuityPrivate = appointmentType.private || false;
      if (acuityPrivate == false) {
        this.snackbarState = 9;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate groupSize
      let acuityClassSize = appointmentType.classSize || -1;
      if (tibiCareEvent.groupSize != acuityClassSize) {
        this.snackbarState = 10;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate groupType
      let acuityType = appointmentType.type || "";
      if (tibiCareEvent.groupType == Constants.TIBI_CARE_EVENT_GROUP_TYPE_OPEN && acuityType != "class"
        || tibiCareEvent.groupType == Constants.TIBI_CARE_EVENT_GROUP_TYPE_CLOSED && acuityType != "series") {
        this.snackbarState = 11;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate sessionCount (and unique appointmentTypeId via includeUnavailable flag)
      if (tibiCareEvent.sessionCount != classAvailability.length) {
        this.snackbarState = 12;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // validate last session is not beyond 365 days from now
      let lastSessionStartIso = classAvailability[classAvailability.length - 1].time;
      let lastSessionStartUtc = new Date(lastSessionStartIso)
      var oneYearFromNow = new Date();
      oneYearFromNow.setDate((new Date()).getDate() + 365);
      // console.log(`lastSessionStartUtc: ${lastSessionStartUtc} vs oneYearFromNow:${oneYearFromNow}`);
      if (lastSessionStartUtc > oneYearFromNow) {
        this.snackbarState = 13;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // compute sessionTimeUtcs (locally) to check and allocate the zoom licenses
      let sessionTimeUtcs = [];
      for (let availability of classAvailability) {
        sessionTimeUtcs.push(availability["time"].replace("+0000", ".000Z"));
      }
      // console.log(`sessionTimeUtcs: ${sessionTimeUtcs}`);

      // validate zoom licenses are available for all the sessions in this careEvent and if so allocate
      let checkZoomLicenseAvailabilityPromises = [];
      for (let sessionTimeUtc of sessionTimeUtcs) {
        checkZoomLicenseAvailabilityPromises.push(API.checkZoomLicenseAvailability(sessionTimeUtc, acuityDuration));
      }
      let unavailableZoomLicenses = [];
      await Promise.all(checkZoomLicenseAvailabilityPromises).then((results) => {
        for (let result of results) {
          if (!result.isZoomLicenseAvailable) {
            unavailableZoomLicenses.push(result.timeUtc);
          }
        }
      });
      if (unavailableZoomLicenses.length > 0) {
        let unavailableTimeUtcs = [];
        for (let timeUtc of unavailableZoomLicenses) {
          unavailableTimeUtcs.push(timeUtc);
        }
        this.snackbarState = 15;
        this.snackbarMessages[15] = this.snackbarUnavailableZoomLicenseMessage + " " + unavailableTimeUtcs.join(", ");
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }

      // all the checks have passed, set sessionTimeUtcs so it can be set in TibiCareEvent during approval
      this.sessionTimeUtcs = sessionTimeUtcs;
      console.timeEnd("isAcuityEntryValidTimer");
      return true;
    },
    async allocateZoomLicenses() {
      console.time("allocateZoomLicensesTimer");
      // zoom licenses are validated (available for all sessions), allocate them (schedule them to be assigned)
      let allocateZoomLicensePromises = [];
      for (let sessionTimeUtc of this.sessionTimeUtcs) {
        allocateZoomLicensePromises.push(API.allocateTibiZoomLicense(
          this.dialogTibiCareEvent.sub,
          this.dialogTibiCareEvent.calendarId,
          this.dialogTibiCareEvent.appointmentTypeId,
          this.dialogTibiCareEvent.eventName,
          (this.dialogTibiCareEvent.groupType == "closed" ? "series" : "class"), // type: {'service', 'class', 'series'}
          sessionTimeUtc,
          this.dialogTibiCareEvent.sessionDuration
        ));
      }
      let zoomLicensesAllocated = true;
      await Promise.all(allocateZoomLicensePromises).then((results) => {
        for (let result of results) {
          if (result.status != 0) {
            zoomLicensesAllocated = false;
          }
        }
      });
      if (!zoomLicensesAllocated) {
        this.snackbarState = 16;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return false;
      }
      console.timeEnd("allocateZoomLicensesTimer");
      return true;
    },
    async processUpdateTibiCareEventResponse(response) {
      //console.log(`processUpdateTibiCareEventResponse, response: ${JSON.stringify(response)}`);
      console.time("processUpdateTibiCareEventResponseTimer");
      if (response && response.status == 0) {
        const providerPromise = this.updateTibiProviders();
        const careEventsPromise = this.updateTibiCareEvents();
        const appointmentTypesPromise = this.updateTibiAppointmentTypes();
        await Promise.all([providerPromise, appointmentTypesPromise, careEventsPromise]).then((results) => {
          this.isCareDataLoaded = true;
        });
        this.snackbarState = 1;
        this.snackbarColor = this.snackbarColorSuccess;
      } else {
        this.snackbarState = 2;
        this.snackbarColor = this.snackbarColorFailure;
      }
      this.resetSearch();
      this.searchCareEvents();
      this.snackbarFlag = true;
      console.timeEnd("processUpdateTibiCareEventResponseTimer");
    },
    resetSearch() {
      this.careEventsDataTable = null;
      this.selectedSearchField = "All Fields";
      this.selectedOperator = "Contains";
      this.searchType = "text";
      this.searchQuery = "";
    },
    dialogClose() {
      this.dialog = false;
    },
  },
  watch: {
    selectedSearchField: function (newField, oldField) {
      this.searchQuery = "";
      this.careEventsDataTable = null;
      if (newField == "All Fields") {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      } else if (newField == "EventName") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "searchableConstant";
        this.operators = this.searchableConstantOperators;
        this.selectedOperator = this.searchableConstantOperators[0];
      } else if (newField == "Sessions") {
        this.searchQuery = "0";
        this.searchType = "float";
        this.operators = this.floatOperators;
        this.selectedOperator = this.floatOperators[this.floatOperators.length - 1]; // GreaterThanOrEqualTo
      } else if (newField == "Status" || newField == "EventCategory" || newField == "EventType") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "constant";
        this.operators = this.constantOperators;
        this.selectedOperator = this.constantOperators[0];
        this.searchQuery = this.constantItemsMap[newField][0] || "";
      } else {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      }
      this.searchCareEvents();
    },
    selectedOperator: function (newOperator, oldOperator) {
      this.careEventsDataTable = null;
      if (newOperator == "IsEqualTo") {
        this.constantLabel = this.selectedSearchField;
        this.constantItems = this.constantItemsMap[this.selectedSearchField] || [];
        if (this.selectedSearchField == "EventName") {
          this.searchType = "searchableConstant";
          this.operators = this.searchableConstantOperators;
        } else if (this.selectedSearchField == "Status"
            || this.selectedSearchField == "EventCategory"
            || this.selectedSearchField == "EventType") {
          this.searchType = "constant";
          this.operators = this.constantOperators;
        }
      } else if (newOperator == "Contains" || newOperator == "StartsWith" || newOperator == "EndsWith") {
        this.searchQuery = " ";
        this.searchType = "text";
      }
      this.searchCareEvents();
    },
    searchQuery: function (newQuery, oldQuery) {
      if (this.careEventsDataTable != null) {
        this.careEventsDataTable = null;
      }
      if ((this.searchType == "constant" || this.searchType == "searchableConstant")
          && newQuery && newQuery.trim().length > 0) {
        this.searchCareEvents();
      }
      this.searchCareEvents(); // remove this to require explicitly clicking on Search button
    },
  },
};
</script>
