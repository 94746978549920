<template>
  <v-container fluid class="mx-0 px-0 my-0 py-0" dense>
    <!--p>LocalTargetComponent, value: {{value}}</p-->
    <v-row align="center" dense>
      <v-col cols="auto" dense justify="start" class="my-0 py-0">
        <div style="color:#616161">LocalTarget:</div>
      </v-col>
      <v-col align-self="auto" dense justify="end" class="my-0 py-0">
        <v-autocomplete
          v-model="value.local_question_id"
          :items="questionTextIdPairs"
          label="Question"
          dense
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col v-if="value.local_question_id > 0" align-self="auto" dense class="my-0 py-0">
        <v-autocomplete
          v-model="value.any_answer_id"
          :items="anyAnswerIdTextPairs"
          label="Any"
          multiple
          dense
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col v-if="value.local_question_id > 0" align-self="auto" dense class="my-0 py-0">
        <v-autocomplete
          v-model="value.required_answer_id"
          :items="requiredAnswerIdTextPairs"
          label="Required"
          multiple
          dense
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col v-if="value.local_question_id > 0" align-self="auto" dense class="my-0 py-0">
        <v-autocomplete
          v-model="value.unallowed_answer_id"
          :items="unallowedAnswerIdTextPairs"
          label="Unallowed"
          multiple
          dense
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  data: () => ({
    answerIdTextPairs: [{ text: "", value: 0 }],
    anyAnswerIdTextPairs: [{ text: "", value: 0 }],
    requiredAnswerIdTextPairs: [{ text: "", value: 0 }],
    unallowedAnswerIdTextPairs: [{ text: "", value: 0 }],
  }),
  created() {
    this.updateAnswerIdTextPairs();
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    questions: Object,
    questionIds: Array,
    answers: Object
  },
  methods: {
    updateAnswerIdTextPairs() {
      this.answerIdTextPairs = this.getAnswerIdTextPairs();
      this.anyAnswerIdTextPairs = []
      for (let i = 0; i < this.answerIdTextPairs.length; i++) {
        if (this.value.required_answer_id.includes(this.answerIdTextPairs[i].value)) {
          continue
        }
        if (this.value.unallowed_answer_id.includes(this.answerIdTextPairs[i].value)) {
          continue
        }
        this.anyAnswerIdTextPairs.push(this.answerIdTextPairs[i])
      }
      // console.log("LOCALTARGET-COMPONENT 2.1, anyAnswerIdTextPairs: ", this.anyAnswerIdTextPairs)

      this.requiredAnswerIdTextPairs = []
      for (let i = 0; i < this.answerIdTextPairs.length; i++) {
        if (this.value.any_answer_id.includes(this.answerIdTextPairs[i].value)) {
          continue
        }
        if (this.value.unallowed_answer_id.includes(this.answerIdTextPairs[i].value)) {
          continue
        }
        this.requiredAnswerIdTextPairs.push(this.answerIdTextPairs[i])
      }
      // console.log("LOCALTARGET-COMPONENT 2.2, requiredAnswerIdTextPairs: ", this.requiredAnswerIdTextPairs)

      this.unallowedAnswerIdTextPairs = []
      for (let i = 0; i < this.answerIdTextPairs.length; i++) {
        if (this.value.required_answer_id.includes(this.answerIdTextPairs[i].value)) {
          continue
        }
        if (this.value.any_answer_id.includes(this.answerIdTextPairs[i].value)) {
          continue
        }
        this.unallowedAnswerIdTextPairs.push(this.answerIdTextPairs[i])
      }
      // console.log("LOCALTARGET-COMPONENT 2.3, unallowedAnswerIdTextPairs: ", this.unallowedAnswerIdTextPairs)
    },
    getAnswerIdTextPairs() {
      var result = [];
      if (this.value.local_question_id > 0) {
        let localQuestion = this.questions[this.value.local_question_id];
        if (localQuestion === undefined) {
          console.log("..LocalTarget, localQuestion not loaded yet");
          return [];
        }
        let separatedAnswerIds = localQuestion.question_separated_answer_ids;
        let answerIds = separatedAnswerIds.replace(/-/g, ",").split(",");
        answerIds.forEach((answerId) => {
          let pair = {
            text: this.answers[answerId].answer_text,
            value: this.answers[answerId].answer_id
          };
          result.push(pair);
        });
      }
      return result;
    }
  },
  computed: {
    questionTextIdPairs() {
      var result = [];
      this.questionIds.forEach(questionId => {
        let question = this.questions[questionId]
        let pair = {
          text: question.question_text,
          value: question.question_id
        };
        result.push(pair);
      });
      // console.log("LocalTarget, questionTextIdPairs:", result);
      return result;
    },
  },
  watch: {
    'value.local_question_id': function (newField, oldField) {
      // console.log("LocalTarget, WATCHER local_question_id");
      this.updateAnswerIdTextPairs();
      if (oldField != -1 && newField != oldField) {
        // question was initialized/selected (not -1) and now a new one is selected, reset localtarget answers
        this.value.any_answer_id = []
        this.value.required_answer_id = []
        this.value.unallowed_answer_id = []
      }
    },
    'value.any_answer_id': function (newField, oldField) {
      this.updateAnswerIdTextPairs();
    },
    'value.required_answer_id': function (newField, oldField) {
      this.updateAnswerIdTextPairs();
    },
    'value.unallowed_answer_id': function (newField, oldField) {
      this.updateAnswerIdTextPairs();
    },
  },
};
</script>
