export const QUESTION_TABLE = 'question';
export const QUESTION_TEXT = 'question_text';
export const QUESTION_TYPE = 'question_type';
export const QUESTION_TYPE_DEFAULT_LIST = [
    "single", "singleOther", "multi", "multiOther", "shortText", "longText", "voice", "mmddyyyyDate"];
export const QUESTION_ANSWER_ORDERING = ["ordered", "shuffle"];
export const QUESTION_PROFILE_KEY = 'question_profile_key';

export const TARGET_TABLE = 'target';
export const TARGET_NAME = 'target_name';
export const TARGET_DESCRIPTION = 'target_description';

export const ANSWER_TABLE = 'answer';
export const ANSWER_TEXT = 'answer_text';
export const ANSWER_PROFILE_VALUE = 'answer_profile_value';

export const SURVEY_TABLE = 'survey';
export const SURVEY_QUESTION_ORDERING = ["ordered", "shuffle"];
export const SURVEY_NAME = 'survey_name';
export const SURVEY_DESCRIPTION= 'survey_description';
export const SURVEY_ID = 'survey_id';
export const SURVEY_PUBLISHED = 'survey_published';

export const POST_TABLE = 'post';
export const POST_TITLE = 'post_title';
export const POST_DESCRIPTION= 'post_description';
export const POST_ID = 'post_id';
export const POST_PUBLISHED = 'post_published';

export const LINK_TABLE = 'link';
export const LINK_TEXT = 'link_text';

export const DERIVED_TABLE = 'derived';
export const DERIVED_NAME = 'derived_name';

export const PREDICATE_TARGET_COMPARISON_PAIRS = [
    { text: "EqualTo", value: "StringEqualTo" },
    { text: "NotEqualTo", value: "NotEqualTo" }
];

export const PREDICATE_QUESTION_COMPARISON_PAIRS = [
    { text: "EqualTo", value: "EqualTo" },
    { text: "NotEqualTo", value: "NotEqualTo" }
];

export const PREDICATE_BOOLEAN_COMPARISON_PAIRS = [
    { text: "EqualTo", value: "EqualTo" }
];

export const PREDICATE_NUMERICAL_COMPARISON_PAIRS = [
    { text: "EqualTo", value: "EqualTo" },
    { text: "NotEqualTo", value: "NotEqualTo" },
    { text: "LessThan", value: "LessThan" },
    { text: "LessThanOrEqualTo", value: "LessThanOrEqualTo" },
    { text: "GreaterThan", value: "GreaterThan" },
    { text: "GreaterThanOrEqualTo", value: "GreaterThanOrEqualTo" }
];

export const PREDICATE_STRING_COMPARISON_PAIRS = [
    { text: "EqualTo", value: "StringEqualTo" },
    { text: "NotEqualTo", value: "StringNotEqualTo" }
];

export const PREDICATE_KEY = 'predicate_key';
export const PREDICATE_COMPARISON_FUNCTIONS = 'predicate_comparison_function';
export const PREDICATE_VALUE = 'predicate_value';

// later add: "Healthkit" (predicateKey: WalkSteps etc), "Derived" (predicateKey: GestationWeeks, etc)
export const PREDICATE_TYPE_QUESTION = "Question";
export const PREDICATE_TYPE_TARGET = "Target";
export const PREDICATE_TYPE_HEALTHKIT = "Healthkit";
export const PREDICATE_TYPE_DERIVED = "Derived";
export const PREDICATE_TYPES_FIXED = [PREDICATE_TYPE_QUESTION, PREDICATE_TYPE_TARGET, PREDICATE_TYPE_DERIVED];

export const TAG_TEXT = "tag_text";
export const SURVEY_TAG_TABLE = 'survey_tag';
export const POST_TAG_TABLE = 'post_tag';
export const QUESTION_TAG_TABLE = 'question_tag';

export const KEYWORD_TABLE = "keyword";
export const KEYWORD_TEXT = "keyword_text";
export const POST_KEYWORD_TABLE = "post_keyword";

// cognito admin group
export const ADMIN = "admin";

// links used in posts
export const LINK_PREFIX = "__";
export const LINK_SUFFIX = "__";
export const LINK_KEY_PREFIX = "_post_";
export const LINK_TYPE_IMAGE = "Image";
export const LINK_TYPE_VIDEO = "Video";
export const LINK_TYPE_URL = "Url";
export const LINK_TYPE_DOCUMENT = "Document";
export const LINK_TYPES_FIXED = [LINK_TYPE_IMAGE, LINK_TYPE_VIDEO, LINK_TYPE_URL, LINK_TYPE_DOCUMENT];
export const S3_LINK_EXPIRATION_SECS = 24 * 60 * 60; // 24 hours

// target_presence_in_profile used in DB which are {"any", "required", "unallowed"}
export const ANY = "any"
export const REQUIRED = "required"
export const UNALLOWED = "unallowed"

// tab names in Pago which will be used to define where a survey should be published to
export const ME_TAB = "me"
export const CIRCLE_TAB = "circle"
export const SURVEY_TABS = [ME_TAB, CIRCLE_TAB];

export const TIBI_PROVIDER_STATUSES = ["applied", "active", "rejected"];

export const TIBI_CARE_EVENT_STATUSES = ["draft", "delete", "submit", "revise", "approve", "reject"];
export const TIBI_CARE_EVENT_SUBMIT = "submit";

export const TIBI_CARE_EVENT_GROUP_TYPE_OPEN = "open";
export const TIBI_CARE_EVENT_GROUP_TYPE_CLOSED = "closed";

export const COMMA_SEPARATOR = ",";
export const STRING_SEPARATOR = "_";

// TibiProvider status
export const STATUS = "status";
export const APPLIED_PREFIX = "applied_round";
export const APPLIED_ROUND0 = "applied_round0";
export const APPLIED_ROUND1 = "applied_round1";
export const APPLIED_ROUND2 = "applied_round2";
export const DECLINED_PREFIX = "declined_round";
export const DECLINED_ROUND0 = "declined_round0";
export const DECLINED_ROUND1 = "declined_round1";
export const DECLINED_ROUND2 = "declined_round2";
export const ACTIVE = "active";
export const DECLINED_ACTIVE = "declined_active";
export const ACTIVE_RENEW = "active_renew";
export const DECLINED_ACTIVE_RENEW = "declined_active_renew"; // will delete (<sub>.public.renew) TibiProvider
export const ACTION_REQUIRED = "ACTION_REQUIRED"; // dummy status to list providers that are waiting for admin action

export const SERVICE_AVAILABILITY_NEXT_N_DAYS_TIBI_AVAILABILITY = 14;

// snack bar constants
export const SNACKBAR_TIMEOUT = 5000; // 5 secs
export const SNACKBAR_COLOR_SUCCESS = "green darken-2";
export const SNACKBAR_COLOR_FAILURE = "red darken-2";
