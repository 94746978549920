<template>
  <v-container fluid>
    <v-row align="start" dense>
      <v-col class="d-flex" cols="3" xs="2" dense>
        <v-select
          v-model="selectedSearchField"
          :items="searchFields"
          label="Search Field"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="2" xs="2" dense>
        <v-select v-model="selectedOperator" :items="operators" label="Operator" outlined dense></v-select>
      </v-col>
      <v-col v-if="searchType == 'text'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model.trim="searchQuery"
          label="Search Text"
          outlined
          dense
          @keyup.enter="searchProviders"
        ></v-text-field>
      </v-col>
      <v-col v-if="searchType == 'float'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model="searchQuery"
          lazy-validation
          label="Search Value"
          outlined
          dense
          @keyup.enter="searchProviders"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="searchType == 'constant' || searchType == 'searchableConstant'"
        class="d-flex"
        cols="5"
        xs="4"
        dense>
        <v-autocomplete
          v-model="searchQuery"
          :items="constantItems"
          :label="constantLabel"
          outlined
          dense
          @keyup.enter="searchProviders"
        ></v-autocomplete>
      </v-col>
      <v-col align="start" justify="start" class="d-flex" dense>
        <v-btn
          v-if="searchType == 'text' || searchType == 'float'"
          color="blue darken-2"
          @click="searchProviders"
          dark
          dense
        >Search</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="providersDataTable != null"
      :headers="headers"
      :items="providersDataTable"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :items-per-page="100"
      dense
      item-key="id"
      class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDialogProviderDetails(item)">mdi-magnify</v-icon>
        <v-icon v-if="!isTibiProviderStatusActive(item.id)" small class="mr-3"
          @click="approveTibiProvider(item.id)">mdi-checkbox-marked-outline</v-icon>
        <v-icon v-if="!isTibiProviderStatusDeclined(item.id)" small class="mr-3"
          @click="declineTibiProvider(item.id)">mdi-close-box-outline</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <strong>ID: </strong>{{dialogTibiProvider.id}}<br>
          <strong>status: </strong>{{dialogTibiProvider.status}}<br>
          <strong>statusHistory: </strong>{{dialogTibiProvider.statusHistory}}<br>
          <template v-if="dialogTibiProvider.dailyUpcomingEventSummaryEmailSentTimeUtc && dialogTibiProvider.dailyUpcomingEventSummaryEmailSentTimeUtc.length > 0">
            <strong>dailyUpcomingEventSummaryEmailSentTimeUtc: </strong>{{dialogTibiProvider.dailyUpcomingEventSummaryEmailSentTimeUtc}}<br>
          </template>

          <!-- applied_round0 info provided via /apply -->
          <br>
          <u><strong>applied_round0</strong></u>
          <br>
          <template v-if="dialogTibiProvider.firstName && dialogTibiProvider.firstName.length > 0">
            <strong>firstName: </strong>{{dialogTibiProvider.firstName}}<br>
          </template>
          <template v-if="dialogTibiProvider.middleName && dialogTibiProvider.middleName.length > 0">
            <strong>middleName: </strong>{{dialogTibiProvider.middleName}}<br>
          </template>
          <template v-if="dialogTibiProvider.lastName && dialogTibiProvider.lastName.length > 0">
            <strong>lastName: </strong>{{dialogTibiProvider.lastName}}<br>
          </template>

          <template v-if="dialogTibiProvider.governmentIdSignedUrl && dialogTibiProvider.governmentIdSignedUrl.length > 0">
            <strong>governmentId: </strong>
            <a :href="dialogTibiProvider.governmentIdSignedUrl" target="_blank" rel="noopener noreferrer">fileUploaded</a>
            <br>
          </template>

          <strong>email: </strong>{{dialogTibiProvider.email}}<br>

          <template v-if="dialogTibiProvider.degrees && dialogTibiProvider.degrees.length > 0">
            <strong>degrees:</strong>
            <ul>
              <li v-for="(degree, i) in dialogTibiProvider.degrees" :key="i">
                <template>{{ degree }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.credentials && dialogTibiProvider.credentials.length > 0">
            <strong>credentials:</strong>
            <ul>
              <li v-for="(credential, i) in dialogTibiProvider.credentials" :key="i">
                <template>{{ credential }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.stateLicenses && dialogTibiProvider.stateLicenses.length > 0">
            <strong>stateLicenses:</strong>
            <ul>
              <li v-for="(stateLicense, i) in dialogTibiProvider.stateLicenses" :key="i">
                <template><strong>state:</strong> {{ stateLicense.state }}</template>
                <template><strong> expiration:</strong> {{ stateLicense.expiration }}</template>
                <strong> license: </strong>
                <template v-if="stateLicense.providerApplyKey.length == 0">fileNotUploaded</template>
                <a v-if="stateLicense.providerApplyKey.length > 0"
                  :href="stateLicense.signedUrl" target="_blank" rel="noopener noreferrer">fileUploaded
                </a>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.providerTitles && dialogTibiProvider.providerTitles.length > 0">
            <strong>providerTitles:</strong>
            <ul>
              <li v-for="(providerTitle, i) in dialogTibiProvider.providerTitles" :key="i">
                <template>{{ providerTitle }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.specializations && dialogTibiProvider.specializations.length > 0">
            <strong>specializations:</strong>
            <ul>
              <li v-for="(specialization, i) in dialogTibiProvider.specializations" :key="i">
                <template>{{ specialization }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.employmentStatuses && dialogTibiProvider.employmentStatuses.length > 0">
            <strong>employmentStatuses:</strong>
            <ul>
              <li v-for="(employmentStatus, i) in dialogTibiProvider.employmentStatuses" :key="i">
                <template>{{ employmentStatus }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.resumeSignedUrl && dialogTibiProvider.resumeSignedUrl.length > 0">
            <strong>resume: </strong>
            <a :href="dialogTibiProvider.resumeSignedUrl" target="_blank" rel="noopener noreferrer">fileUploaded</a>
            <br>
          </template>

          <strong>tibiJoinReason: </strong>{{dialogTibiProvider.tibiJoinReason}}<br>

          <template v-if="dialogTibiProvider.servicesToOffer && dialogTibiProvider.servicesToOffer.length > 0">
            <strong>servicesToOffer:</strong>
            <ul>
              <li v-for="(service, i) in dialogTibiProvider.servicesToOffer" :key="i">
                <template>{{ service }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.tibiProfessionalGoals && dialogTibiProvider.tibiProfessionalGoals.length > 0">
            <strong>tibiProfessionalGoals:</strong>
            <ul>
              <li v-for="(tibiProfessionalGoal, i) in dialogTibiProvider.tibiProfessionalGoals" :key="i">
                <template>{{ tibiProfessionalGoal }}</template>
              </li>
            </ul>
          </template>

          <template v-if="dialogTibiProvider.tibiUtilizationPlan && dialogTibiProvider.tibiUtilizationPlan.length > 0">
            <strong>tibiUtilizationPlan:</strong>
            <ul>
              <li v-for="(plan, i) in dialogTibiProvider.tibiUtilizationPlan" :key="i">
                <template>{{ plan }}</template>
              </li>
            </ul>
          </template>

          <strong>tibiTimeAllocation: </strong>{{dialogTibiProvider.tibiTimeAllocation}}<br>

          <strong>confirmedMissionVision: </strong>{{dialogTibiProvider.confirmedMissionVision}}<br>

          <strong>confirmedCoreValues: </strong>{{dialogTibiProvider.confirmedCoreValues}}<br>

          <template v-if="dialogTibiProvider.tibiHeardFrom && dialogTibiProvider.tibiHeardFrom.length > 0">
            <strong>tibiHeardFrom:</strong>
            <ul>
              <li v-for="(source, i) in dialogTibiProvider.tibiHeardFrom" :key="i">
                <template>{{ source }}</template>
              </li>
            </ul>
          </template>

          <!-- applied_round1 info provided via /services/registration -->
          <template v-if="!dialogTibiProvider.status.includes('round0')">
            <br>
            <u><strong>applied_round1</strong></u>
            <br>
            <template v-if="dialogTibiProvider.publicName && dialogTibiProvider.publicName.length > 0">
              <strong>publicName: </strong>{{dialogTibiProvider.publicName}}<br>
            </template>

            <template v-if="dialogTibiProvider.introduction && dialogTibiProvider.introduction.length > 0">
              <strong>introduction: </strong>{{dialogTibiProvider.introduction}}<br>
            </template>

            <template v-if="dialogTibiProvider.pronouns && dialogTibiProvider.pronouns.length > 0">
              <strong>pronouns: </strong>{{dialogTibiProvider.pronouns}}<br>
            </template>

            <template v-if="dialogTibiProvider.stateLicenses && dialogTibiProvider.stateLicenses.length > 0">
              <strong>stateLicenses:</strong>
              <ul>
                <li v-for="(stateLicense, i) in dialogTibiProvider.stateLicenses" :key="i">
                  <template><strong>state:</strong> {{ stateLicense.state }}</template>
                  <template><strong> expiration:</strong> {{ stateLicense.expiration }}</template>
                  <strong> license: </strong>
                  <template v-if="stateLicense.providerApplyKey.length == 0">fileNotUploaded</template>
                  <a v-if="stateLicense.providerApplyKey.length > 0"
                    :href="stateLicense.signedUrl" target="_blank" rel="noopener noreferrer">fileUploaded
                  </a>
                </li>
              </ul>
            </template>

            <template v-if="dialogTibiProvider.services && dialogTibiProvider.services.length == 0">
              <strong>services (one-on-one):</strong> None
              <br/>
            </template>
            <template v-if="dialogTibiProvider.services && dialogTibiProvider.services.length > 0">
              <strong>services (one-on-one):</strong>
              <ul>
                <li v-for="(service, i) in dialogTibiProvider.services" :key="i">
                  <template>"{{ tibiAppointmentTypeDict[service.appointmentTypeId].name}}" - Fee: ${{service.fee/100.0}} (status:{{service.status}}) </template>
                </li>
              </ul>
            </template>

            <strong>chatMessageNotificationViaSms: </strong>{{dialogTibiProvider.chatMessageNotificationViaSms || false}}<br>
            <strong>chatMessageNotificationViaEmail: </strong>{{dialogTibiProvider.chatMessageNotificationViaEmail || false}}<br>

            <template v-if="dialogTibiProvider.initialDailyBusinessHours && dialogTibiProvider.initialDailyBusinessHours.length > 0">
            <strong>initialDailyBusinessHours:</strong>
              <ul>
                <li v-for="(triplet, i) in getInitialDailyBusinessHours(dialogTibiProvider.initialDailyBusinessHours)" :key="i">
                  <strong>{{triplet[0]}}: </strong> <template>{{ triplet[1] }} - {{ triplet[2] }}</template>
                </li>
              </ul>
            </template>

            <template v-if="dialogTibiProvider.timezoneName && dialogTibiProvider.timezoneName.length > 0">
              <strong>timezoneName: </strong>{{dialogTibiProvider.timezoneName}}<br>
            </template>

            <!-- TODO: add raceEthnicity question to /services/registration -->
            <template v-if="dialogTibiProvider.raceEthnicity && dialogTibiProvider.raceEthnicity.length > 0">
              <strong>raceEthnicity:</strong>
              <ul>
                <li v-for="(raceEthnicity, i) in dialogTibiProvider.raceEthnicity" :key="i">
                  <template>{{ raceEthnicity }}</template>
                </li>
              </ul>
            </template>

            <!--TODO: add insurance question if needed -->
            <template v-if="dialogTibiProvider.insurances && dialogTibiProvider.insurances.length > 0">
              <strong>insurances:</strong>
              <ul>
                <li v-for="(insurance, i) in dialogTibiProvider.insurances" :key="i">
                  <template>{{ insurance }}</template>
                </li>
              </ul>
            </template>

            <strong>[AcuitySetup] calendarId: </strong>{{dialogTibiProvider.calendarId}}<br>

            <strong>[AcuitySetup] appointmentTypeIds: </strong>{{dialogTibiProvider.appointmentTypeIds || []}}<br>
            <template v-if="dialogTibiProvider.appointmentTypeIds && dialogTibiProvider.appointmentTypeIds.length > 0">
              <ul>
                <li v-for="(appointmentTypeId, i) in dialogTibiProvider.appointmentTypeIds" :key="i">
                  <template>{{ tibiAppointmentTypeDict[appointmentTypeId].name}} (type:{{ tibiAppointmentTypeDict[appointmentTypeId].type }})</template>
                </li>
              </ul>
            </template>
          </template>

          <!-- applied_round2 info provided via /account -->
          <template v-if="!dialogTibiProvider.status.includes('round0') && !dialogTibiProvider.status.includes('round1')">
            <br>
            <u><strong>applied_round2</strong></u>
            <br>
            <template v-if="dialogTibiProvider.addressLine && dialogTibiProvider.addressLine.length > 0">
              <strong>addressLine: </strong>{{dialogTibiProvider.addressLine}}<br>
            </template>
            <template v-if="dialogTibiProvider.addressCity && dialogTibiProvider.addressCity.length > 0">
              <strong>addressCity: </strong>{{dialogTibiProvider.addressCity}}<br>
            </template>
            <template v-if="dialogTibiProvider.addressState && dialogTibiProvider.addressState.length > 0">
              <strong>addressState: </strong>{{dialogTibiProvider.addressState}}<br>
            </template>
            <template v-if="dialogTibiProvider.addressZipcode && dialogTibiProvider.addressZipcode.length > 0">
              <strong>addressZipcode: </strong>{{dialogTibiProvider.addressZipcode}}<br>
            </template>
            <template v-if="dialogTibiProvider.stripeAccountId && dialogTibiProvider.stripeAccountId.length > 0">
              <strong>stripeAccountId: </strong>{{dialogTibiProvider.stripeAccountId}}<br>
            </template>
            <template v-if="dialogTibiProvider.stripeCustomerId && dialogTibiProvider.stripeCustomerId.length > 0">
              <strong>stripeCustomerId: </strong>{{dialogTibiProvider.stripeCustomerId}}<br>
            </template>
            <template v-if="dialogTibiProvider.confirmedAcuitySetup != null">
              <strong>confirmedAcuitySetup: </strong>{{dialogTibiProvider.confirmedAcuitySetup}}<br>
            </template>
            <template v-if="dialogTibiProvider.confirmedServiceAgreementSignature != null">
              <strong>confirmedServiceAgreementSignature: </strong>{{dialogTibiProvider.confirmedServiceAgreementSignature}}<br>
            </template>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon
            v-if="!isTibiProviderStatusActive(dialogTibiProvider.id)" small class="mr-3"
            @click="approveTibiProvider(dialogTibiProvider.id)">mdi-checkbox-marked-outline</v-icon>
          <v-icon
            v-if="!isTibiProviderStatusDeclined(dialogTibiProvider.id)" small class="mr-3"
            @click="declineTibiProvider(dialogTibiProvider.id)">mdi-close-box-outline</v-icon>
          <v-icon small class="mr-3" @click="dialogClose">mdi-cancel</v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarMessages[snackbarState] }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div class="text-center" v-if="showProgressCircular">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";
import * as Utils from "../utils/Utils.js";

export default {
  name: "reviewProviders",
  data() {
    return {
      searchFields: [
        "All Fields",
        "Status",
        "PublicName",
        "Email",
        "Services",
        "CareEvents",
      ],
      selectedSearchField: "All Fields",

      // placeholder operators, updates the selected operator via v-model
      operators: ["Contains", "IsEqualTo", "StartsWith", "EndsWith"],
      selectedOperator: "Contains",

      // applicable operators depending on they type, e.g text, float and constants
      textOperators: ["Contains", "StartsWith", "EndsWith", "IsEqualTo"],
      constantOperators: ["IsEqualTo"],
      searchableConstantOperators: [
        "IsEqualTo",
        "Contains",
        "StartsWith",
        "EndsWith",
      ],
      floatOperators: [
        "EqualTo",
        "LessThan",
        "LessThanOrEqualTo",
        "GreaterThan",
        "GreaterThanOrEqualTo",
      ],

      // initiallize search type and query and also constants used for fixed fields (e.g publicName etc)
      searchType: "text",
      searchQuery: "",
      constantItems: [],
      constantLabel: "",
      constantItemsMap: {},

      // stores tibiProvider objects fetched from TibiProvider DDB
      tibiProviderDict: {}, // key:<sub>.public, value:TibiProvider
      selectedTibiProviders: [], // selected tibi providers based on search criteria
      includeAllTibiProviderStatuses: false, // set to true to include TIBI_PROVIDER_STATUSES even if they do not exist

      // stores tibiAppointmentType objects fetched from TibiAppointmentType DDB keyed on appointmentTypeId
      tibiAppointmentTypeDict: {}, // key:appointmentTypeId, value:TibiAppointmentType

      // set to true when all required care data is fetched and loaded
      isCareDataLoaded: false,

      // provider (results) data table column headers
      headers: [
      {
        text: "PublicName",
        align: "start",
        sortable: true,
        value: "publicName",
      },
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: "Services",
        align: "start",
        sortable: true,
        value: "services",
      },
      {
        text: "CareEvents",
        align: "start",
        sortable: true,
        value: "careEvents",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "status",
      },
      { text: "Actions",
        align: "start",
        sortable: false,
        value: "actions"
      },
      ],
      providersDataTable: null,

      // dialog that shows provider details, it allows to move the provider up in the process towards status=active
      // or decline at any stage (see schema.graphql for details)
      dialog: false,
      dialogTitle: "Provider Details",
      dialogTibiProvider: null,

      // snackbar to show a message based on actions taken in data table
      snackbarState: 0,
      snackbarMessages: [
        /* 0 */ "Please enter a valid number to query providers based on number of services they offer!",
        /* 1 */ "Please enter a valid number to query providers based on number of care events they offer!",
        /* 2 */ "Succesfully updated the provider status!",
        /* 3 */ "Failed to update the provider status!",
        /* 4 */ "Failed to start provider subscription!", // will set it dynamically with the error code
      ],
      snackbarFlag: false,
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,

      // spinner to show while waiting for API calls
      showProgressCircular: false,
    };
  },
  computed: {
  },
  async created() {
    console.time("providersTimer");
    await this.fetchCareData();
    console.timeEnd("providersTimer");
  },
  methods: {
    async fetchCareData() {
      this.isCareDataLoaded = false;
      this.showProgressCircular = true;
      const providerPromise = this.updateTibiProviders();
      const appointmentTypesPromise = this.updateTibiAppointmentTypes();
      await Promise.all([providerPromise, appointmentTypesPromise]).then((results) => {
        this.isCareDataLoaded = true;
        this.showProgressCircular = false;
      });
    },
    async updateTibiProviders() {
      this.tibiProviderDict = {};
      let response = await API.getPublicTibiProviderApi();
      (response.value || []).map(tibiProvider => this.tibiProviderDict[tibiProvider.id] = tibiProvider);

      // update statuses of TibiProviders statuses to be shown in drop-down (add all constants)
      let statusConstants = [Constants.ACTION_REQUIRED];
      statusConstants = statusConstants.concat(Utils.getSortedUniqueValues(Object.values(this.tibiProviderDict), "status"));
      this.constantItemsMap["Status"] = statusConstants;
      if (this.includeAllTibiProviderStatuses) {
        for (let status of Constants.TIBI_PROVIDER_STATUSES) {
          if (this.constantItemsMap["Status"].includes(status) == false) {
            this.constantItemsMap["Status"].push(status);
          }
        }
        this.constantItemsMap["Status"] = this.constantItemsMap["Status"].sort();
      }

      // update TibiProvider public names to be shown in drop-down
      this.constantItemsMap["PublicName"] = Utils.getSortedUniqueValues(Object.values(this.tibiProviderDict), "publicName");

      // update TibiProvider emails to be shown in drop-down
      this.constantItemsMap["Email"] = Utils.getSortedUniqueValues(Object.values(this.tibiProviderDict), "email");
    },
    async updateTibiAppointmentTypes() {
      this.tibiAppointmentTypeDict = {};
      let response = await API.getTibiAppointmentTypeScanApi();
      response.value.forEach(appointmentType => {
        this.tibiAppointmentTypeDict[appointmentType.appointmentTypeId] = appointmentType;
      });
    },
    async searchProviders() {
      if (!this.isCareDataLoaded) {
        await this.fetchCareData();
      }

      // apply search criteria and update result table based on selected tibi providers
      this.selectedTibiProviders = [];
      const field = this.selectedSearchField;
      if (field == "All Fields") {
        this.selectedTibiProviders = this.searchAllFields();
      } else if (field == "Status") {
        this.selectedTibiProviders = this.searchStatusField();
      } else if (field == "Email") {
        this.selectedTibiProviders = this.searchEmailField();
      } else if (field == "PublicName") {
        this.selectedTibiProviders = this.searchPublicNameField();
      } else if (field == "Services") {
        this.selectedTibiProviders = this.searchServicesField();
      } else if (field == "CareEvents") {
        this.selectedTibiProviders = this.searchCareEventsField();
      }
      this.constructProvidersDataTable();
    },
    searchAllFields() {
      var results = [];
      let query = this.searchQuery.toLowerCase().trim(); // case-insensitive search
      let operator = this.selectedOperator;
      for (let tibiProvider of Object.values(this.tibiProviderDict)) {
        if (Utils.objectHasTextMatch(tibiProvider, operator, query)) {
          results.push(tibiProvider);
          continue;
        }
        let appointmentTypeIds = tibiProvider.appointmentTypeIds || [];
        for (let appointmentTypeId of appointmentTypeIds) {
          let tibiAppointmentType = this.tibiAppointmentTypeDict[appointmentTypeId] || {};
          if (Utils.objectHasTextMatch(tibiAppointmentType, operator, query)) {
            results.push(tibiProvider);
            break;
          }
        }
      }
      return results;
    },
    searchStatusField() {
      var results = [];
      for (let tibiProvider of Object.values(this.tibiProviderDict)) {
        if (tibiProvider.status == this.searchQuery) { // since status is constant (constantOperators=[IsEqual])
          results.push(tibiProvider);
        }
        if (this.searchQuery == Constants.ACTION_REQUIRED) {
          let publicName = tibiProvider.publicName || "";
          let stripeAccountId = tibiProvider.stripeAccountId || "";
          if (tibiProvider.status == Constants.APPLIED_ROUND0
            || (tibiProvider.status == Constants.APPLIED_ROUND1 && publicName.length > 0)
            || (tibiProvider.status == Constants.APPLIED_ROUND2 && stripeAccountId.length > 0)
            || tibiProvider.status == Constants.ACTIVE_RENEW) {
            results.push(tibiProvider);
          }
        }
      }
      return results;
    },
    searchEmailField() {
      var results = [];
      let query = this.searchQuery.toLowerCase().trim(); // case-insensitive search
      let operator = this.selectedOperator;
      for (let tibiProvider of Object.values(this.tibiProviderDict)) {
        let valueString = String(tibiProvider.email).toLowerCase().trim();
        if (operator == "Contains" && valueString.includes(query)) {
          results.push(tibiProvider);
          continue;
        }
        if (operator == "IsEqualTo" && valueString == query) {
          results.push(tibiProvider);
          continue;
        }
        if (operator == "StartsWith" && valueString.startsWith(query)) {
          results.push(tibiProvider);
          continue;
        }
        if (operator == "EndsWith" && valueString.endsWith(query)) {
          results.push(tibiProvider);
          continue;
        }
      }
      return results;
    },
    searchPublicNameField() {
      var results = [];
      let query = this.searchQuery.toLowerCase().trim(); // case-insensitive search
      let operator = this.selectedOperator;
      for (let tibiProvider of Object.values(this.tibiProviderDict)) {
        let valueString = String(tibiProvider.publicName).toLowerCase().trim();
        if (operator == "Contains" && valueString.includes(query)) {
          results.push(tibiProvider);
          continue;
        }
        if (operator == "IsEqualTo" && valueString == query) {
          results.push(tibiProvider);
          continue;
        }
        if (operator == "StartsWith" && valueString.startsWith(query)) {
          results.push(tibiProvider);
          continue;
        }
        if (operator == "EndsWith" && valueString.endsWith(query)) {
          results.push(tibiProvider);
          continue;
        }
      }
      return results;
    },
    searchServicesField() {
      var results = [];
      let query = isNaN(this.searchQuery) || this.searchQuery.length == 0 ? -1 : parseInt(this.searchQuery);
      if (query < 0) {
        this.snackbarState = 0;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return [];
      }
      let operator = this.selectedOperator;
      for (let tibiProvider of Object.values(this.tibiProviderDict)) {
        let serviceCount = this.getOfferedServicesCount(tibiProvider);
        if (Utils.valueNumberMatch(serviceCount, operator, query)) {
          results.push(tibiProvider);
        }
      }
      return results;
    },
    searchCareEventsField() {
      var results = [];
      let query = isNaN(this.searchQuery) || this.searchQuery.length == 0 ? -1 : parseInt(this.searchQuery);
      if (query < 0) {
        this.snackbarState = 1;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return [];
      }
      let operator = this.selectedOperator;
      for (let tibiProvider of Object.values(this.tibiProviderDict)) {
        let careEventCount = this.getOfferedCareEventsCount(tibiProvider);
        if (Utils.valueNumberMatch(careEventCount, operator, query)) {
          results.push(tibiProvider);
        }
      }
      return results;
    },
    constructProvidersDataTable() {
      this.providersDataTable = [];
      this.selectedTibiProviders.sort((a, b) => ((a.publicName || "") < (b.publicName || "")) ? -1 : 1);
      this.selectedTibiProviders.forEach(tibiProvider => {
        var row = {};
        row["id"] = tibiProvider.id;
        row["email"] = tibiProvider.email;
        row["publicName"] = tibiProvider.publicName;
        row["services"] = this.getOfferedServicesCount(tibiProvider);
        row["careEvents"] = this.getOfferedCareEventsCount(tibiProvider);
        row["status"] = tibiProvider.status;
        this.providersDataTable.push(row);
      });
    },
    getOfferedServicesCount(tibiProvider) {
      let serviceCount = 0;
      for (let appointmentTypeId of tibiProvider.appointmentTypeIds || []) {
        let tibiAppointmentType = this.tibiAppointmentTypeDict[appointmentTypeId] || {};
        if (tibiAppointmentType.type == "service") {
          serviceCount += 1;
        }
      }
      return serviceCount;
    },
    getOfferedCareEventsCount(tibiProvider) {
      let careEventCount = 0;
      for (let appointmentTypeId of tibiProvider.appointmentTypeIds || []) {
        let tibiAppointmentType = this.tibiAppointmentTypeDict[appointmentTypeId] || {};
        if (tibiAppointmentType.type == "class" || tibiAppointmentType.type == "series") {
          careEventCount += 1;
        }
      }
      return careEventCount;
    },
    getDialogProviderDetails(row) {
      let tibiProvider = this.tibiProviderDict[row.id] || {};
      this.dialogTibiProvider = { ...tibiProvider }
      // console.log(`dialogTibiProvider: ${JSON.stringify(this.dialogTibiProvider)}`);
      this.dialog = true;
    },
    isTibiProviderStatusActive(id) {
      let tibiProvider = this.tibiProviderDict[id] || {};
      return tibiProvider.status == Constants.ACTIVE;
    },
    isTibiProviderStatusDeclined(id) {
      let tibiProvider = this.tibiProviderDict[id] || {};
      let status = tibiProvider.status || "";
      return (status.startsWith(Constants.DECLINED_PREFIX) || status == Constants.DECLINED_ACTIVE);
    },
    async approveTibiProvider(id) {
      let tibiProvider = this.tibiProviderDict[id] || {};
      let newStatus = this.getNextApprovedStatus(tibiProvider.status);
      let message = "";
      if (tibiProvider.status == Constants.ACTIVE_RENEW) {
        message = `Approve renewed registration for ${tibiProvider.email}?`;
      } else if (tibiProvider.status == Constants.APPLIED_ROUND2 && newStatus == Constants.ACTIVE) {
        message = `Approve ${tibiProvider.email} for status=${newStatus} and process the first subscription payment?`
      } else {
        message = `Approve ${tibiProvider.email} for status=${newStatus}`;
      }
      if (confirm(message)) {
        this.dialogClose();
        this.showProgressCircular = true;
        if (tibiProvider.status == Constants.APPLIED_ROUND2 && newStatus == Constants.ACTIVE) {
          let response = await API.startProviderSubscription(tibiProvider.sub, tibiProvider.email);
          //console.log(`startProviderSubscription.response: ${JSON.stringify(response)}`);
          if (response && response.status != 0) {
            this.snackbarState = 4;
            this.snackbarMessages[4] = `Failed to start provider subscription (errorCode: ${response.status})!`;
            this.snackbarColor = this.snackbarColorFailure;
            this.snackbarFlag = true;
            this.showProgressCircular = false;
            return;
          }
        }
        let acuityUpdates = "";
        if (newStatus == Constants.ACTIVE) {
          // check if this update requires provider's Acuity calendar to be updated, if so, ask for confirmation
          let providerName = tibiProvider.publicName;
          let acuityAppointmentTypeIds = tibiProvider.appointmentTypeIds || []; // appointmentTypeIds on acuity
          for (let service of tibiProvider.services) {
            let serviceAppointmentTypeId = service.appointmentTypeId; // provider entered a serviceFee for this
            let tibiAppointmentType = this.tibiAppointmentTypeDict[serviceAppointmentTypeId] || {};
            if (tibiAppointmentType.type != "service") {
              continue; // this is not a one-on-one service, skip
            }
            if (!acuityAppointmentTypeIds.includes(serviceAppointmentTypeId)) {
              if (acuityUpdates.length > 0) {
                acuityUpdates += "\n";
              }
              let appointmentTypeName = this.tibiAppointmentTypeDict[serviceAppointmentTypeId].name;
              acuityUpdates += `ADDED "${appointmentTypeName}" to "${providerName}"s Acuity calendar`;
            }
          }
          for (let acuityAppointmentTypeId of acuityAppointmentTypeIds) {
            let tibiAppointmentType = this.tibiAppointmentTypeDict[acuityAppointmentTypeId] || {};
            if (tibiAppointmentType.type != "service") {
              continue; // this is not a one-on-one service, skip
            }
            if (tibiProvider.services.find(s => s.appointmentTypeId == acuityAppointmentTypeId) == null) {
              if (acuityUpdates.length > 0) {
                acuityUpdates += "\n";
              }
              let appointmentTypeName = this.tibiAppointmentTypeDict[acuityAppointmentTypeId].name;
              acuityUpdates += `REMOVED "${appointmentTypeName}" from "${providerName}"s Acuity calendar`;
            }
          }
          if (acuityUpdates.length > 0) {
            message = `Please confirm below updates are completed on provider's Acuity:\n\n`;
            message += acuityUpdates;
            if (!confirm(message)) {
              this.showProgressCircular = false;
              return;
            }
          }
        }
        let response = await API.updateTibiProviderFieldApi(id, Constants.STATUS, newStatus);
        if (response.status == 0 && newStatus == Constants.ACTIVE) {
          // invoke updateCareData to update TibiAvailability
          let updateCareDataParams = {};
          updateCareDataParams["calendarId"] = tibiProvider.calendarId;
          updateCareDataParams["updateTibiAppointmentTypeAndTibiProvider"] = true;
          updateCareDataParams["updateServiceAvailabilityNextNDays"] = Constants.SERVICE_AVAILABILITY_NEXT_N_DAYS_TIBI_AVAILABILITY;
          updateCareDataParams["updateClassAndSeriesAvailabilityNextNDays"] = 365;
          API.updateCareData(updateCareDataParams); // do not wait as it will take ~40secs
        }
        //console.log(`updateTibiProviderField.response: ${JSON.stringify(response)}`);
        await this.processUpdateTibiProviderResponse(response);
      }
    },
    getNextApprovedStatus(status) {
      if (status == Constants.APPLIED_ROUND0 || status == Constants.DECLINED_ROUND0) {
        return Constants.APPLIED_ROUND1;
      }
      if (status == Constants.APPLIED_ROUND1 || status == Constants.DECLINED_ROUND1) {
        return Constants.APPLIED_ROUND2;
      }
      if (status == Constants.APPLIED_ROUND2
        || status == Constants.DECLINED_ROUND2
        || status == Constants.DECLINED_ACTIVE
        || status == Constants.ACTIVE_RENEW) {
        return Constants.ACTIVE;
      }
      throw new Error("getNextApprovedStatus, unknown/invalid TibiProvider.status:", status);
    },
    async declineTibiProvider(id) {
      let tibiProvider = this.tibiProviderDict[id];
      let newStatus = this.getDeclinedStatus(tibiProvider.status);
      let message = (tibiProvider.status == Constants.ACTIVE_RENEW)
        ? `Decline renewed registration for ${tibiProvider.email}?`
        : `Decline ${tibiProvider.email} with status=${newStatus}?`
      if (tibiProvider.status == Constants.ACTIVE) {
        message += "\n\nNotes:\n";
        message += "Provider's subscription will NOT be canceled, please make cancelSubscription call to cancel.\n\n";
        message += "If the provider has any scheduled 1:1 appointments or care events, they will NOT be canceled automatically.\n\n";
        message += "Please either arrange new providers or make cancelAppointment or cancelSession calls.";
      }
      if (confirm(message)) {
        let response = await API.updateTibiProviderFieldApi(id, Constants.STATUS, newStatus);
        await this.processUpdateTibiProviderResponse(response);
        this.dialogClose();
      }
    },
    getDeclinedStatus(status) {
      if (status.startsWith(Constants.APPLIED_PREFIX)) {
        return status.replace(Constants.APPLIED_PREFIX, Constants.DECLINED_PREFIX);
      }
      if (status == Constants.ACTIVE) {
        return Constants.DECLINED_ACTIVE;
      }
      if (status == Constants.ACTIVE_RENEW) {
        return Constants.DECLINED_ACTIVE_RENEW;
      }
      throw new Error("getNextDeclinedStatus, unknown/invalid TibiProvider.status:", status);
    },
    getInitialDailyBusinessHours(initialDailyBusinessHours) {
      // each triplet is in the form of day_start_end (e.g Monday_9:00am_5:00pm) and separated by comma
      let tripletsArray = [];
      for (let triplet of initialDailyBusinessHours.split(Constants.COMMA_SEPARATOR) || []) {
        tripletsArray.push(triplet.split(Constants.STRING_SEPARATOR));
      }
      return tripletsArray;
    },
    async processUpdateTibiProviderResponse(response) {
      if (response && response.status == 0) {
        this.snackbarState = 2;
        this.snackbarColor = this.snackbarColorSuccess;
        await this.fetchCareData(); // refresh tibiProviders and tibiAppointmentTypes
      } else {
        this.snackbarState = 3;
        this.snackbarColor = this.snackbarColorFailure;
      }
      this.resetSearch();
      this.snackbarFlag = true;
      this.showProgressCircular = false;
    },
    resetSearch() {
      this.providersDataTable = null;
      this.selectedSearchField = "All Fields";
      this.selectedOperator = "Contains";
      this.searchType = "text";
      this.searchQuery = "";
    },
    dialogClose() {
      this.dialog = false;
    },
  },
  watch: {
    selectedSearchField: function (newField, oldField) {
      this.searchQuery = "";
      this.providersDataTable = null;
      if (newField == "All Fields") {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      } else if (newField == "Email" || newField == "PublicName") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "searchableConstant";
        this.operators = this.searchableConstantOperators;
        this.selectedOperator = this.searchableConstantOperators[0];
      } else if (newField == "Services" || newField == "CareEvents") {
        this.searchQuery = "0";
        this.searchType = "float";
        this.operators = this.floatOperators;
        this.selectedOperator = this.floatOperators[this.floatOperators.length - 1]; // GreaterThanOrEqualTo
      } else if (newField == "Status") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "constant";
        this.operators = this.constantOperators;
        this.selectedOperator = this.constantOperators[0];
        this.searchQuery = this.constantItemsMap[newField][0] || "";
      } else {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      }
      this.searchProviders();
    },
    selectedOperator: function (newOperator, oldOperator) {
      this.providersDataTable = null;
      if (newOperator == "IsEqualTo") {
        this.constantLabel = this.selectedSearchField;
        this.constantItems = this.constantItemsMap[this.selectedSearchField] || [];
        if (this.selectedSearchField == "Email" || this.selectedSearchField == "PublicName") {
          this.searchType = "searchableConstant";
          this.operators = this.searchableConstantOperators;
        } else if (this.selectedSearchField == "Status") {
          this.searchType = "constant";
          this.operators = this.constantOperators;
        }
      } else if (newOperator == "Contains" || newOperator == "StartsWith" || newOperator == "EndsWith") {
        this.searchQuery = " ";
        this.searchType = "text";
      }
      this.searchProviders();
    },
    searchQuery: function (newQuery, oldQuery) {
      if (this.providersDataTable != null) {
        this.providersDataTable = null;
      }
      if ((this.searchType == "constant" || this.searchType == "searchableConstant")
          && newQuery && newQuery.trim().length > 0) {
        this.searchProviders();
      }
      this.searchProviders(); // remove this to require explicitly clicking on Search button
    },
  },
};
</script>
