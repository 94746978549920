<template>
  <v-app id="inspire">
    <!--p>question: {{question}}</p-->
    <v-form ref="form" lazy-validation dense>
      <v-text-field
        v-if="question.question_id != null && command === 'edit'"
        v-model="question.question_id"
        label="Question ID"
        dense
        disabled
      ></v-text-field>
      <v-text-field
        v-model.trim="question.question_text"
        :counter="511"
        :rules="questionTextRules"
        label="*Question"
        lazy-validation
        dense
      ></v-text-field>
      <v-autocomplete
        v-model="question.question_type"
        :items="questionTypes"
        :rules="questionTypeRules"
        label="*Question Type"
        required
        dense
      ></v-autocomplete>
      <div v-if="question.question_type.startsWith('single') || question.question_type.startsWith('multi')">
        <!--p>PARENT question.answers[{{i}}]: {{question.answers[i]}}</p-->
        <!--p>PARENT question_answer_ordering: {{question.question_answer_ordering}}</p-->
        <!--p>PARENT question.answers.length: {{question.answers.length}}</p-->
        <div v-for="(answer, i) in this.question.answers" :key="i">
          <!--p>PARENT question.answers[{{i}}]: {{question.answers[i]}}</p-->
          <Answer
            :question_answer_ordering="question.question_answer_ordering"
            :targetIdNamePairs="targetIdNamePairs"
            :questions="questions"
            :questionIds="questionIds"
            :answers="answers"
            :answerIndex="i"
            v-model.trim="question.answers[i]"
            @onAddEmptyAnswer="addEmptyAnswer"
            @onRemoveAnswer="removeAnswer"
            @onRemoveEmptyAnswer="removeEmptyAnswer"
            @onUpdateAnswerOrdering="updateAnswerOrdering"
          />
        </div>
      </div>
      <!--p>PARENT localtarget[0]: {{question.localtarget[0]}}</p-->
      <v-row align="center" dense>
        <v-col align-self="auto" dense justify="start" class="my-0 py-0">
          <Target
            v-model.trim="question"
            targetLabel="Question Targets:"
            :targetIdNamePairs="targetIdNamePairs"
          />
        </v-col>
        <v-col align-self="auto" dense justify="start" class="my-0 py-0">
          <LocalTarget
            v-model.trim="question.localtarget[0]"
            :questions="questions"
            :questionIds="questionIds"
            :answers="answers"
          />
        </v-col>
      </v-row>
      <v-autocomplete
        v-model="question.question_survey_id"
        :items="surveyIdNamePairs"
        label="Surveys"
        multiple
        dense
        clearable
      ></v-autocomplete>
      <v-combobox
        v-model="question.question_tag"
        :items="tags"
        chips
        dense
        clearable
        label="Tags"
        multiple
        height="25px"
      ><template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            color="success"
            text-color="white"
            small
            :input-value="selected"
            close
            @click="select"
            @click:close="removeTag(item)"
          >{{ item }}</v-chip>
        </template>
      </v-combobox>
      <br />
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="validate">Validate</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="resetValidation">Reset Validation</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="save">Save</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="reset">Reset Form</v-btn>
    </v-form>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarMessages[snackbarState] }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Answer from "../components/Answer";
import Target from "../components/Target";
import LocalTarget from "../components/LocalTarget";
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  components: {
    Answer,
    Target,
    LocalTarget
  },
  data: () => ({
    name: "addQuestion",
    valid: false,
    questionTextRules: [
      v => !!v || "Required",
      v => (v && v.length <= 511) || "Text cannot have more than 511 chars"
    ],
    questionTypeRules: [v => !!v || "Required"],
    questionTypes: [],

    // to localtargets, we fetch all active questions and their answers from db
    questionIds: [], // array of question ids returned from db
    questions: {}, // key:question_id, value:question (fetched from db)
    questionTextToIdDict: {}, // key:question_text, value:question_id
    answers: {}, // key:answer_id, value:answer
    questionTextIdPairs: [{ text: "", value: 0 }], // text: "questionText", value: questionId
    answerTextIdPairs: [{ text: "", value: 0 }], // text: "answerText", value: answerId

    defaultEmtpyAnswerCount: 1,
    question: {
      question_id: null,
      question_text: "",
      question_type: "",
      question_answer_ordering: "ordered",
      answers: [],
      any_target_id: [],
      required_target_id: [],
      unallowed_target_id: [],
      localtarget: [{ // to support multiple LocalTargets later
        local_question_id: -1,
        any_answer_id: [],
        required_answer_id: [],
        unallowed_answer_id: []
      }],
      question_survey_id: undefined,
      question_tag: []
    },
    originalQuestion: null, // used when the question is being edited

    // snackbar to show a message based on actions taken in data table
    snackbarState: 0,
    snackbarMessages: [
      /* 0 */ "Please add a new question!",
      /* 1 */ "Please fix entries in the required fields!",
      /* 2 */ "All required fields are valid! Please click Save!",
      /* 3 */ "Succesfully saved the question!",
      /* 4 */ "Failed to save the question!",
      /* 5 */ "Please edit and save as a new question!",
      /* 6 */ "Please edit the question!",
      /* 7 */ "Please select Any/Required/Unallowed or unselect LocalTarget Question!",
      /* 8 */ "Only one answer is selected for subshuffle, at least two consecutive ones required!",
      /* 9 */ "Only one answer is selected for suborder, at least two consecutive ones required!",
      /* 10 */ "A question with the exact same text already exists, please reuse it or update the text!",
    ],
    snackbarFlag: false,
    snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
    snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
    snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
    snackbarTimeout: Constants.SNACKBAR_TIMEOUT,

    // target and survey metadata fetched from db
    targetIdNamePairs: [{ text: "", value: 0 }],
    surveyIdNamePairs: [{ text: "", value: 0 }],

    // tags fetched from db which can be optionally used to tag questions (and surveys)
    tags: [], // array of tag_text
    tagIdToTextMap: {}, // key:tag_id, value:tag_text

    // this view can be called from Search Questions with command = {edit, clone}
    command: ""
  }),
  created() {
    if (this.$route.params.question_id) {
      // editing or cloning an existing question
      console.log("AddQuestion, this.$route.params:", this.$route.params);
      this.command = this.$route.params.command;
      this.fetchAndUpdateInputFields(); // note: below async calls are made within this function
      this.snackbarState = this.command === "clone" ? 5 : 6;
      this.snackbarColor = this.snackbarColorSuccess;
      // this.snackbarFlag = true;
    } else {
      // TODO: can we identify and avoid unnecessary fetches? or just make a batch call
      // note: below async calls are made within fetch and update input fields function for edit/clone
      this.searchQuestions();
      this.updateQuestionTypes();
      this.updateTargetIdNamePairs();
      this.updateSurveyIdNamePairs();
      this.updateTags();

      // adding a brand new  question
      for (let i = 0; i < this.defaultEmtpyAnswerCount; i++) {
        this.addEmptyAnswer();
      }
      this.snackbarState = 0;
      this.snackbarColor = this.snackbarColorSuccess;
      // this.snackbarFlag = true;
    }
  },
  watch: {
   'question.question_type': function (newValue, oldValue) {
      if (this.question.answers.length == 0
          && (this.question.question_type.startsWith('single') || this.question.question_type.startsWith('multi'))) {
        this.addEmptyAnswer();
      }
    },
  },
  methods: {
    async searchQuestions() {
      //  fetch all active questions and their answers (to enable localtargets)
      this.questions = {};
      this.questionIds = [];
      var response = await API.searchQuestionsByAllTextColumnsApi("LIKE", `%%`);
      console.log("\n response:", JSON.stringify(response));

      var result = [];
      response.questions.forEach((question) => {
        if (!(question.question_id in this.questions)) {
          this.questionIds.push(question.question_id);
          this.questions[question.question_id] = question;
          let pair = {
            text: question.question_text,
            value: question.question_id
          };
          result.push(pair);
        }
        this.questionTextToIdDict[question.question_text] = question.question_id;
      });
      this.questionTextIdPairs = result;
      console.log("\n1) this.questions:", this.questions);
      console.log("\n2) this.questionTextIdPairs:", this.questionTextIdPairs);
      console.log("\n3) this.questionTextToIdDict:", this.questionTextToIdDict);

      this.answers = {};
      response.answers.forEach((answer) => {
        this.answers[answer.answer_id] = answer;
      });
      console.log("\n4) this.answers:", this.answers);
    },
    async fetchAndUpdateInputFields() {
      // TODO: since we now fetch all active questions (to support localtargets), we do not need this fetch anymore
      // just construct in internal structures from this.questions and this.answers for this.$route.params.question_id

      // REFACTOR, this see searchQuestions(). note: answers here is [] not {}, so need to convert to {}
      let response = await API.searchByQuestionIdApi(
        Constants.QUESTION_TABLE,
        "=",
        this.$route.params.question_id
      );
      console.log("..0 fetchAndUpdateInputFields \nresponse:", JSON.stringify(response));

      await this.searchQuestions(); // wait for this call as below needs to access questions and answers
      await this.updateTags(); // wait for this call as below needs to access it to lookup tagTexts
      this.updateQuestionTypes();
      this.updateTargetIdNamePairs();
      this.updateSurveyIdNamePairs();

      // populate the fields from question table
      response.questions.forEach(question => {
        // when editing/cloning an existing question, adding/removing answers is not reactive
        // to fix this we need to keep the original question.answers[] for now
        this.question.question_id = question.question_id;
        this.question.question_text = question.question_text;
        this.question.question_type = question.question_type;
        this.question.question_answer_ordering = question.question_answer_ordering;
        this.question.answers = [];
      });

      // populate question (global) target fields, if exist, based on target_presence_in_profile
      this.question.any_target_id = [];
      this.question.required_target_id = [];
      this.question.unallowed_target_id = [];
      response.questionTargets.forEach(target => {
        if (target.target_presence_in_profile == Constants.ANY) {
          this.question.any_target_id.push(target.target_id);
        } else if (target.target_presence_in_profile == Constants.REQUIRED) {
          this.question.required_target_id.push(target.target_id);
        } else if (target.target_presence_in_profile == Constants.UNALLOWED) {
          this.question.unallowed_target_id.push(target.target_id);
        }
      });
      console.log("\n..1 fetchAndUpdateInputFields \nthis.question:", this.question);

      // populate question local target fields, if exist, based on target_presence_in_profile
      this.question.localtarget = [{
        local_question_id: -1,
        any_answer_id: [],
        required_answer_id: [],
        unallowed_answer_id: []
      }];
      response.questionLocalTargets.forEach(target => {
        // all these targets should be for the same local question id (since we require at most one localtarget)
        this.question.localtarget[0].local_question_id = target.local_question_id;
        if (target.target_presence_in_profile == Constants.ANY) {
          this.question.localtarget[0].any_answer_id.push(target.local_answer_id);
        } else if (target.target_presence_in_profile == Constants.REQUIRED) {
          this.question.localtarget[0].required_answer_id.push(target.local_answer_id);
        } else if (target.target_presence_in_profile == Constants.UNALLOWED) {
          this.question.localtarget[0].unallowed_answer_id.push(target.local_answer_id);
        }
      });
      console.log("\n..1.2 fetchAndUpdateInputFields \nthis.question:", this.question);

      // note: make sure question_answer_ordering is propaged to each answer, especially answers[0]
      // this is already done since answers' read it from question.question_answer_ordering

      // populate answer (global and local) target fields, if exist, based on target_presence_in_profile
      response.answers.forEach(answer => {
        // answer (global) targets
        // console.log("..1.3.0 fetchAndUpdateInputFields answer:", answer);
        answer.any_target_id = [];
        answer.required_target_id = [];
        answer.unallowed_target_id = [];
        response.answerTargets.forEach(target => {
          if (answer.answer_id === target.answer_id) {
            if (target.target_presence_in_profile == Constants.ANY) {
              answer.any_target_id.push(target.target_id);
            } else if (target.target_presence_in_profile == Constants.REQUIRED) {
              answer.required_target_id.push(target.target_id);
            } else if (target.target_presence_in_profile == Constants.UNALLOWED) {
              answer.unallowed_target_id.push(target.target_id);
            }
          }
        });

        // answer (local) targets
        answer.localtarget = [{
          local_question_id: -1,
          any_answer_id: [],
          required_answer_id: [],
          unallowed_answer_id: []
        }];
        response.answerLocalTargets.forEach(target => {
          if (answer.answer_id === target.answer_id) {
            // all these targets should be for the same local answer id (since we require at most one localtarget)
            //  console.log("..1.3.1 fetchAndUpdateInputFields LOCALtarget:", target);
            answer.localtarget[0].local_question_id = target.local_question_id;
            if (target.target_presence_in_profile == Constants.ANY) {
              answer.localtarget[0].any_answer_id.push(target.local_answer_id);
            } else if (target.target_presence_in_profile == Constants.REQUIRED) {
              answer.localtarget[0].required_answer_id.push(target.local_answer_id);
            } else if (target.target_presence_in_profile == Constants.UNALLOWED) {
              answer.localtarget[0].unallowed_answer_id.push(target.local_answer_id);
            }
          }
        });

        // push the updated answer to answers array
        this.question.answers.push(answer);
        // console.log("..1.3.2 fetchAndUpdateInputFields PUSH answer:", answer);
        if (this.question.answers.length == 1) {
          this.question.answers[0].first_answer = true;
        }
      });
      console.log("\n..1.4 fetchAndUpdateInputFields \nthis.question:", this.question);

      // update answers' subcheck flags, if answer ids were separated by "-" character
      // note: this means answers' subcheck should be true (indicating subshuffle or suborder)
      // depending on if question's answer ordering is selected to be ordered or shuffle
      response.questions.forEach(question => {
        let separatedAnswerIds = question.question_separated_answer_ids;
        if (separatedAnswerIds.includes("-")) {
          let answerIds = separatedAnswerIds.replace(/-/g, ",").split(",");
          console.log("\n updating subchecks for answerIds:", answerIds);
          answerIds.forEach(answerId => {
            // note: once we convert answers=[] to {}, we can do simple lookups as in searchQuestions()
            for (let i = 0; i < this.question.answers.length; i++) {
              if (this.question.answers[i].answer_id === parseInt(answerId)) {
                this.question.answers[i].answer_subcheck = false;
                if (
                  separatedAnswerIds.includes("-" + answerId) ||
                  separatedAnswerIds.includes(answerId + "-")
                ) {
                  console.log(
                    " set subcheck = true, for answer:",
                    this.question.answers[i]
                  );
                  this.question.answers[i].answer_subcheck = true;
                }
              }
            }
          });
        }
      });

      // populate survey fields, if exist
      response.surveys.forEach(survey => {
        if (this.question.question_survey_id == undefined) {
          this.question.question_survey_id = [];
        }
        this.question.question_survey_id.push(survey.survey_id);
      });

      // populate tags, if exist
      response.tags.forEach(tag => {
        if (this.question.question_tag == undefined) {
          this.question.question_tag = [];
        }
        let tagText = this.tagIdToTextMap[tag.tag_id]
        this.question.question_tag.push(tagText);
      });

      console.log("\n..FINAL fetchAndUpdateInputFields \nthis.question:", this.question);
      this.originalQuestion = JSON.parse(JSON.stringify(this.question));
      console.log("\n.. fetchAndUpdateInputFields \nthis.originalQuestion:",this.originalQuestion);
    },
    validate() {
      this.valid = this.$refs.form.validate();

      // check if questionText already exists, notes (as of 09/23):
      // - we do not allow exact same questionText to be used in another question
      // - the reason is questionText->questionId is looked up within suicide/psychosis protocols
      // - so having the same questionText (with different answers) may break this lookup/protocol
      // if this restriction becomes a problem (i.e if we want to use the same questionText with multiple answer sets):
      // - review the questionTexts used in suicide/psychosis protocols
      // - if they are unique, remove this restriction here and add a validation to TibiGenericData publication
      // - i.e require the uniqueness only for questions used in these protocols (or some other solution)
      if (this.question.question_text in this.questionTextToIdDict
          && this.question.question_id != this.questionTextToIdDict[this.question.question_text]) {
        this.valid = false;
        this.snackbarState = 10;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return;
      }

      // if there are any subchecks, make sure they are part of at least two consecutive subchecks
      for (let i = 0; i < this.question.answers.length; i++) {
        let answer = this.question.answers[i];
        if (answer.answer_subcheck) {
          let consecutiveChecks = false;
          let prevAnswer = i > 0 ? this.question.answers[i - 1] : null;
          if (prevAnswer != null && prevAnswer.answer_subcheck) {
            consecutiveChecks = true;
          } else {
            let nextAnswer =
              i < this.question.answers.length - 1
                ? this.question.answers[i + 1]
                : null;
            if (nextAnswer != null && nextAnswer.answer_subcheck) {
              consecutiveChecks = true;
            }
          }
          if (!consecutiveChecks) {
            this.snackbarState = this.question.question_answer_ordering === "ordered" ? 8 : 9;
            this.snackbarColor = this.snackbarColorFailure;
            this.snackbarFlag = true;
            this.valid = false;
            return;
          }
        }
      }

      // if some localtarget is selected, make sure some answers are also selected
      // note: this can be adjusted later to support multiple localtargets
      let isQuestionLocalTargetIncomplete = (this.question.localtarget[0]
        && this.question.localtarget[0].local_question_id
        && this.question.localtarget[0].local_question_id > 0
        && this.question.localtarget[0].any_answer_id.length == 0
        && this.question.localtarget[0].required_answer_id.length == 0
        && this.question.localtarget[0].unallowed_answer_id.length == 0);
      var isAnswerLocalTargetIncomplete = false;
      for (let i = 0; i < this.question.answers.length; i++) {
        let answer = this.question.answers[i];
        if (answer.localtarget[0]
          && answer.localtarget[0].local_question_id
          && answer.localtarget[0].local_question_id > 0
          && answer.localtarget[0].any_answer_id.length == 0
          && answer.localtarget[0].required_answer_id.length == 0
          && answer.localtarget[0].unallowed_answer_id.length == 0) {
            isAnswerLocalTargetIncomplete = true;
            break;
          }
      }

      if (!this.valid) {
        this.snackbarState = 1;
        this.snackbarColor = this.snackbarColorFailure;
      } else if (isQuestionLocalTargetIncomplete || isAnswerLocalTargetIncomplete) {
          this.valid = false;
          this.snackbarState = 7;
          this.snackbarColor = this.snackbarColorFailure;
      } else {
        this.snackbarState = 2;
        this.snackbarColor = this.snackbarColorSuccess;
      }
      this.snackbarFlag = true;
    },
    resetValidation() {
      console.log("question: ", JSON.stringify(this.question));
      this.$refs.form.resetValidation();
    },
    async save() {
      console.log("in save(), question:", JSON.stringify(this.question));
      this.validate();
      if (!this.valid) {
        return;
      }
      // remove answers with empty answer_text (which were added internally to support single* and multi*
      // questions but unnecessary/unused for *Text, *Date, voice question types) before saving to contentDb
      this.question.answers = this.question.answers.filter(answer => answer.answer_text != '');
      console.log("in save(), after answer[] cleanup, question:", JSON.stringify(this.question));

      // remove (cloned) answers, if any, for question_type={*Text, *Date, voice} as they cannot contain answers
      if (this.question.question_type.endsWith("Text")
          || this.question.question_type.endsWith("Date")
          || this.question.question_type == "voice") {
        this.question.answers = [];
      }

      var response;
      if (this.command === "edit") {
        let newQuestion = this.question;
        let originalQuestion = this.originalQuestion;
        // console.log("\nin save(), originalQuestionString:", JSON.stringify(originalQuestion));
        // console.log("in save(), newQuestionString:", JSON.stringify(newQuestion));
        // console.log("in save(), questionStringsHasUpdates:", JSON.stringify(originalQuestion) !== JSON.stringify(newQuestion));
        response = await API.updateQuestionApi(
          this.originalQuestion,
          this.question
        );
      } else {
        response = await API.addQuestionApi(this.question);
      }
      if (response.success == 1) {
        this.snackbarState = 3;
        this.snackbarColor = this.snackbarColorSuccess;
        this.searchQuestions(); // fetch updated questions and answers
      } else {
        this.snackbarState = 4;
        this.snackbarColor = this.snackbarColorFailure;
      }
      this.resetForm();
      this.snackbarFlag = true;
    },
    resetForm() {
      // note: when the form is reset by calling: this.$refs.form.reset();
      // seems to cause unselecting default answer ordering in radio button
      // so we reset all question variables to values manually below
      let answerCount = this.question.answers.length;
      for (let i = 0; i < answerCount; i++) {
        this.question.answers.pop();
      }
      this.addEmptyAnswer();
      this.question.question_id = null;
      this.question.question_text = "";
      this.question.question_type = "";
      this.question.question_answer_ordering = "ordered";
      let anyTargetCount = this.question.any_target_id.length;
      for (let i = 0; i < anyTargetCount; i++) {
        this.question.any_target_id.pop();
      }
      let requiredTargetCount = this.question.required_target_id.length;
      for (let i = 0; i < requiredTargetCount; i++) {
        this.question.required_target_id.pop();
      }
      let unallowedTargetCount = this.question.unallowed_target_id.length;
      for (let i = 0; i < unallowedTargetCount; i++) {
        this.question.unallowed_target_id.pop();
      }
      let localtargetCount = this.question.localtarget.length;
      for (let i = 0; i < localtargetCount; i++) {
        this.question.localtarget.pop();
      }
      this.question.localtarget = [{
        local_question_id: -1,
        any_answer_id: [],
        required_answer_id: [],
        unallowed_answer_id: []
      }];
      this.question.question_survey_id = undefined
      this.question.question_tag = []
      this.$refs.form.resetValidation();
    },
    reset() {
      if (confirm("Delete all entries in the form?")) {
        this.resetForm();
      }
    },
    async updateQuestionTypes() {
      // make sure defult question types are included and the list starts with them (in the same order)
      for (const qType of Constants.QUESTION_TYPE_DEFAULT_LIST) {
        if (!this.questionTypes.includes(qType)) {
          this.questionTypes.push(qType);
        }
      }
      // query the DB and append any missing question types to the end (very uncommon above list not to contain all)
      const response = await API.getDistinctValuesApi(
        Constants.QUESTION_TABLE,
        Constants.QUESTION_TYPE
      );
      if (response.success) {
        for (let i = 0; i < response.result.length; i++) {
          const item = response.result[i];
          const qType = item[Constants.QUESTION_TYPE]
          if (!this.questionTypes.includes(qType)) {
            this.questionTypes.push(qType);
          }
        }
      }
      console.log("questionTypes:", this.questionTypes);
    },
    async updateTargetIdNamePairs() {
      const response = await API.getTargetIdNamePairsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        // add special target_name_description which combines the two if description exists
        let target_name_description = item.target_name;
        if (item.target_description && item.target_description.length > 0 && !target_name_description.startsWith("_")) {
          target_name_description = item.target_name + " (" + item.target_description + ")";
        }
        let pair = {
          text: target_name_description,
          value: item.target_id
        };
        result.push(pair);
      }
      this.targetIdNamePairs = result;
      this.targetIdNamePairs.sort((a,b)=> (b.text > a.text ? 1 : -1))
      //console.log("targetIdNamePairs:", this.targetIdNamePairs);
    },
    async updateSurveyIdNamePairs() {
      const response = await API.getSurveyIdNamePairsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        let pair = {
          text: item.survey_name,
          value: item.survey_id
        };
        result.push(pair);
      }
      this.surveyIdNamePairs = result;
      //console.log("surveyIdNamePairs:", this.surveyIdNamePairs);
    },
    async updateTags() {
      //  fetch all tags and extract tagTexts
      var response = await API.getTagsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const tag = response.result[i];
        result.push(tag.tag_text);
        this.tagIdToTextMap[tag.tag_id] = tag.tag_text;
      }
      this.tags = result;
      console.log("this.tags:", this.tags);
      console.log("this.tagIdToTextMap, ", this.tagIdToTextMap);
    },
    addEmptyAnswer() {
      console.log("..0 addEmptyAnswer, this.question:", this.question);
      this.question.answers.push({
        answer_text: "",
        answer_score: null,
        any_target_id: [], // DELETE answer_target_id: undefined,
        required_target_id: [],
        unallowed_target_id: [],
        localtarget: [{ // supporting only one localtarget but extendable
          local_question_id: -1,
          any_answer_id: [],
          required_answer_id: [],
          unallowed_answer_id: []
        }],
        answer_index: -1,
        first_answer: false,
        last_answer: true,
        answer_subcheck: false
      });
      this.updateAnswersMetadata();
      // console.log("..1 addEmptyAnswer, this.question:", this.question);
    },
    updateAnswersMetadata() {
      for (var i = 0; i < this.question.answers.length; i++) {
        this.question.answers[i].first_answer = false;
        this.question.answers[i].last_answer = false;
        if (i === 0) {
          this.question.answers[i].first_answer = true;
        }
        this.question.answers[i].answer_index = i;
        if (i === this.question.answers.length - 1) {
          this.question.answers[i].last_answer = true;
        }
      }
    },
    safeToRemove(answer) {
      if (this.question.answers.length < 2) {
        return false;
      }
      if (answer.answer_text != ""
        || (answer.answer_score != null && answer.answer_score.toString().trim() != "")
        || (answer.any_target_id && answer.any_target_id.length > 0)
        || (answer.required_target_id && answer.required_target_id.length > 0)
        || (answer.unallowed_target_id && answer.unallowed_target_id.length > 0)
      ) {
        return false;
      }
      return true;

    },
    removeEmptyAnswer() {
      let lastAnswer = this.question.answers[this.question.answers.length - 1];
      if (this.safeToRemove(lastAnswer)) {
        this.question.answers.pop();
        this.updateAnswersMetadata();
      } else {
        this.$refs.form.validate();
      }
    },
    removeAnswer(answer) {
      console.log("..removeAnswer, answer:", answer);
      if (this.safeToRemove(answer) || confirm("Remove this answer?")) {
        this.question.answers.splice(answer.answer_index, 1);
        this.updateAnswersMetadata();
      }
    },
    updateAnswerOrdering(answerOrderingEvent) {
      console.log(
        "(1) answerOrderingEvent:",
        answerOrderingEvent,
        " this.question.question_answer_ordering:",
        this.question.question_answer_ordering
      );
      this.question.question_answer_ordering = answerOrderingEvent
        ? answerOrderingEvent
        : "ordered";
      console.log(
        "(2) answerOrderingEvent:",
        answerOrderingEvent,
        " this.question.question_answer_ordering:",
        this.question.question_answer_ordering
      );
    },
    removeTag(item) {
      this.question.question_tag.splice(this.question.question_tag.indexOf(item), 1)
      this.question.question_tag = [...this.question.question_tag]
    },
    isQuestionModified() {
      // return true if required parts of the question is modified/touched
      if (this.question.question_text && this.question.question_text.length > 0) {
        return true;
      }
      if (this.question.question_type && this.question.question_type.length > 0) {
        return true;
      }
      if (this.question.answers.length > 1) {
        return true;
      }
      if (this.question.answers.length == 1 && this.question.answers[0].answer_text.length > 0) {
        return true;
      }
      return false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isQuestionModified()) {
      // console.log("beforeRouteLeave, isQuestionModified: true");
      if (confirm('Do you want to proceed and discard the draft?')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>

<style scoped>
</style>
