<template>
  <v-list id="navigation-group" nav dense>
    <v-list-item-group color="primary">
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.link" link>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text" class="text-left"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-divider />
  </v-list>
</template>

<script>
export default {
  name: "NavigationGroup",
  props: ["items"]
};
</script>