<template>
  <v-app id="inspire">
    <v-form ref="form" lazy-validation dense>
      <v-text-field
        v-if="survey.survey_id != null && command === 'edit'"
        v-model="survey.survey_id"
        label="Survey ID"
        dense
        disabled
      ></v-text-field>
      <v-text-field
        v-model.trim="survey.survey_name"
        :counter="511"
        :rules="surveyNameRules"
        label="*Survey Name"
        lazy-validation
        dense
      ></v-text-field>
      <v-text-field
        v-model.trim="survey.survey_description"
        :counter="2043"
        :rules="surveyDescriptionRules"
        label="*Survey Description"
        lazy-validation
        dense
      ></v-text-field>
      <v-select
        :items="surveyTabs"
        v-model="survey.survey_tab"
        label="*Survey Tab"
        dense
      ></v-select>
      <Target
        v-model.trim="survey"
        :targetLabel="targetLabel"
        :targetIdNamePairs="targetIdNamePairs" />
      <br />
      <div style="color:#616161">*Search and Add Selected Questions:</div>
      <SearchQuestionsComponent
        :enableSelectingSurveys="false"
        @onUpdateSelectedQuestions="updateSelectedQuestions"
        @onAddSelectedQuestions="addSelectedQuestions"
      />
      <template v-if="allSelectedQuestions.length > 0">
        <v-radio-group
          label="Question Ordering:"
          v-model="survey.survey_question_ordering"
          row
          dense
          height="1px"
        >
          <v-radio value="ordered" label="Ordered" />
          <v-radio value="shuffle" label="Shuffle" />
        </v-radio-group>
        <!-- Questions to Add:{{survey.survey_separated_question_ids}} -->
        <v-data-table
          :headers="headers"
          :items="allSelectedQuestions"
          item-key="question_text"
          class="elevation-1"
          dense
        >
          <template v-slot:body="props">
            <draggable :list="props.items" tag="tbody" @change="dragHandler(props.items)">
              <tr v-for="(question, index) in props.items" :key="index">
                <!--td>{{ question.questionId }}</td-->
                <td>
                  <v-icon small>mdi-drag-vertical</v-icon>
                  {{ question.questionText }}
                </td>
                <td v-if="survey.survey_question_ordering === 'ordered'">{{ index + 1 }}</td>
                <td>
                  <v-simple-checkbox v-model="question.subcheck"></v-simple-checkbox>
                </td>
                <td>
                  <v-icon small class="mr-2" @click="removeQuestion(index)">mdi-delete</v-icon>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </template>
      <br />
      <v-combobox
        v-model="survey.survey_tag"
        :items="tags"
        chips
        dense
        clearable
        label="Tags"
        multiple
        height="25px"
      ><template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            color="success"
            text-color="white"
            small
            :input-value="selected"
            close
            @click="select"
            @click:close="removeTag(item)"
          >{{ item }}</v-chip>
        </template>
      </v-combobox>
      <div v-for="(timeWindow, i) in this.timeWindowFields" :key="i">
        <TimeWindow
          :updateKey="timeWindow.updateKey"
          :timeWindowLabel="timeWindow.timeWindowLabel"
          :timeWindowSeconds="timeWindow.timeWindowSeconds"
          @onUpdateTimeWindowSeconds="updateTimeWindowSeconds"
        />
      </div>
      <br />
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="validate">Validate</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="resetValidation">Reset Validation</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="save">Save</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="reset">Reset Form</v-btn>
    </v-form>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarMessages[snackbarState] }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Draggable from "vuedraggable";
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";
import Target from "../components/Target";
import TimeWindow from "../components/TimeWindow";
import SearchQuestionsComponent from "../components/SearchQuestionsComponent";

export default {
  components: {
    Draggable,
    Target,
    TimeWindow,
    SearchQuestionsComponent,
  },
  data: () => ({
    valid: false,
    surveyNameRules: [
      (v) => !!v || "Required",
      (v) =>
        (v && v.length <= 511) || "Survey name cannot have more than 511 chars",
    ],
    surveyDescriptionRules: [
      (v) => !!v || "Required",
      (v) =>
        (v && v.length <= 2043) || "Survey description cannot have more than 511 chars",
    ],
    targetRules: [
      (v) => !!v || "Required",
      (v) => (v && v.length > 0) || "Required",
    ],
    surveyTabs: [
      { text: "Me", value: Constants.ME_TAB },
      { text: "Circle", value: Constants.CIRCLE_TAB}
    ],
    survey: {
      survey_id: null,
      survey_name: "",
      survey_description: "",
      survey_tab: Constants.ME_TAB,
      any_target_id: [],
      required_target_id: [],
      unallowed_target_id: [],
      survey_question_id: [],
      survey_question_ordering: "ordered",
      survey_separated_question_ids: "",
      survey_warning_window_seconds: 172800,     // 2 days
      survey_expiration_window_seconds: 604800,  // 7 days
      survey_submit_cooldown_seconds: 864000000, // 10000 days
      survey_unsubmit_cooldown_seconds: 604800,  // 7 days
      survey_tag: []
    },
    originalSurvey: null, // used when the survey is being edited

    // as of 09/23, the exact same survey names cannot be reused across different surveys
    surveyNameToIdDict: {}, // key:survey_name, value:survey_id

    // target and survey metadata fetched from db
    targetIdNamePairs: [{ text: "", value: 0 }],

    selectedQuestions: [],
    allSelectedQuestions: [],

    // snackbar to show a message based on actions taken in data table
    snackbarState: 0,
    snackbarMessages: [
      /* 0 */ "Please add a new survey!",
      /* 1 */ "Please fix entries in the required fields!",
      /* 2 */ "All required fields are valid! Please click Save!",
      /* 3 */ "Succesfully saved the survey!",
      /* 4 */ "Failed to save the survey!",
      /* 5 */ "Please edit and save as a new survey!",
      /* 6 */ "Please edit the survey!",
      /* 7 */ "Only one question is selected for Subshuffle, at least two consecutive ones required!",
      /* 8 */ "Only one question is selected for Suborder, at least two consecutive ones required!",
      /* 9 */ "Please add at least one question to the survey!",
      /* 10 */ "Survey name cannot contain _,: special characters, please rename!",
      /* 11 */ "The survey is used in an active (auto generated) target or derived predicate!",
      /* 12 */ "Please select (Any/Required/Unallowed) Targets to match the survey to users!",
      /* 13 */ "Please select the Tab that this survey should be published in!",
      /* 14 */ "A survey with the exact same name already exists, please enter a new name!",
    ],
    snackbarFlag: false,
    snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
    snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
    snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
    snackbarTimeout: Constants.SNACKBAR_TIMEOUT,

    // tags fetched from db which can be optionally used to tag surveys (and questions)
    tags: [], // array of tag_text
    tagIdToTextMap: {}, // key:tag_id, value:tag_text

    // store targets that are in-use to avoid erroneous renaming surveys (only during "edit")
    // e.g do not allow above actions if the survey is used in an target or derived predicate
    targetsInUse: {}, // key:target_id, value:target
    derivedsInUse: {}, // key:derived_id, value:derived

    // this view can be called from Search Surveys with command = {edit, clone}
    command: "",
  }),
  computed: {
    headers() {
      let subcheckName =
        this.survey.survey_question_ordering === "ordered"
          ? "Subshuffle"
          : "Suborder";
      var h = [];
      // h.push({ text: "QuestionID", value: "question_id", sortable: false });
      h.push({ text: "Question", value: "question_text", sortable: false });
      if (this.survey.survey_question_ordering === "ordered") {
        h.push({ text: "Order", value: "question_order", sortable: false });
      }
      h.push({ text: subcheckName, value: "subcheck", sortable: false });
      h.push({ text: "Actions", value: "actions", sortable: false });
      return h;
    },

    // time window fields and their labels to show in the form
    timeWindowFields() {
      return [{
          updateKey: "survey_expiration_window_seconds",
          timeWindowLabel: "Expiration Window:",
          timeWindowSeconds: this.survey["survey_expiration_window_seconds"]
        },{
          updateKey: "survey_warning_window_seconds",
          timeWindowLabel: "Warning Window:",
          timeWindowSeconds: this.survey["survey_warning_window_seconds"]
        }, {
          updateKey: "survey_unsubmit_cooldown_seconds",
          timeWindowLabel: "Cooldown if Unsubmitted:",
          timeWindowSeconds: this.survey["survey_unsubmit_cooldown_seconds"]
        }, {
          updateKey: "survey_submit_cooldown_seconds",
          timeWindowLabel: "Cooldown After Submitted:",
          timeWindowSeconds: this.survey["survey_submit_cooldown_seconds"]
        }
      ]
    },
    targetLabel() {
      if (this.survey.survey_tab == Constants.ME_TAB) {
        return "*Targets:"
      }
      if (this.survey.survey_tab == Constants.CIRCLE_TAB) {
        return "*Subject Targets:"
      }
      throw new Error("Unknown survey_tab:", this.survey.survey_tab);
    }
  },
  created() {
    if (this.$route.params.survey_id) {
      // editing or cloning an existing survey
      console.log("AddSurvey, this.$route.params:", this.$route.params);
      this.command = this.$route.params.command;
      console.log("AddSurvey, command:", this.command);
      this.fetchAndUpdateInputFields(); // note: below async calls are made within this function
    } else {
      // TODO: make a batch call
      this.updateTargetIdNamePairs();
      this.updateTags();
      this.updateSurveyNameToIdDict();
    }
  },
  methods: {
    async fetchAndUpdateInputFields() {
      let response = await API.searchSurveysBySurveyIdApi(
        Constants.SURVEY_TABLE,
        "=",
        this.$route.params.survey_id
      );
      console.log("\n..fetchAndUpdate \nresponse:", response);

      if (this.command === "edit") {
        await this.updateTargetsInUse();
      }
      this.updateSurveyNameToIdDict(); // run async (this will only be used during validation)
      await this.updateTargetIdNamePairs();
      await this.updateTags(); // wait for this call as below needs to access it to lookup tagTexts

      // populate the fields from survey table
      response.surveys.forEach(survey => {
        // if we copy values (when editing/cloning an existing survey) like this.survey = { ...survey };
        // then the updates are not reactive, we need to keep the original survey and update the values
        this.survey.survey_id = survey.survey_id;
        this.survey.survey_name = survey.survey_name;
        this.survey.survey_description = survey.survey_description;
        this.survey.survey_tab = survey.survey_tab;
        this.survey.survey_question_ordering = survey.survey_question_ordering;
        this.survey.survey_separated_question_ids = survey.survey_separated_question_ids;
        this.survey.survey_warning_window_seconds = survey.survey_warning_window_seconds;
        this.survey.survey_expiration_window_seconds = survey.survey_expiration_window_seconds;
        this.survey.survey_submit_cooldown_seconds = survey.survey_submit_cooldown_seconds;
        this.survey.survey_unsubmit_cooldown_seconds = survey.survey_unsubmit_cooldown_seconds;
        this.survey.survey_tag = survey.survey_tag;
      });
      console.log("\n..0 fetchAndUpdate \nthis.survey:", this.survey);

      // populate survey (global) target fields  based on target_presence_in_profile field
      this.survey.any_target_id = [];
      this.survey.required_target_id = [];
      this.survey.unallowed_target_id = [];
      response.surveyTargets.forEach(target => {
        if (target.target_presence_in_profile == Constants.ANY) {
          this.survey.any_target_id.push(target.target_id);
        } else if (target.target_presence_in_profile == Constants.REQUIRED) {
          this.survey.required_target_id.push(target.target_id);
        } else if (target.target_presence_in_profile == Constants.UNALLOWED) {
          this.survey.unallowed_target_id.push(target.target_id);
        }
      });
      console.log("\n..1 fetchAndUpdate \nthis.survey:", this.survey);

      // populate survey question_ids
      this.survey.survey_question_id = [];
      this.survey.survey_separated_question_ids
        .replace(/-/g, ",")
        .split(",")
        .forEach((questionId) => {
          this.survey.survey_question_id.push(parseInt(questionId));
        });
      console.log("\n..2 fetchAndUpdate \nthis.survey:", this.survey);

      // load questions received into a dictionary for quick lookup
      let questions = {};
      response.questions.forEach((question) => {
        questions[question.question_id] = question;
      });

      // populate all Selected Questions to generate the draggable table
      this.allSelectedQuestions = [];
      let separatedQuestionIds = this.survey.survey_separated_question_ids;
      let questionIds = separatedQuestionIds.replace(/-/g, ",").split(",");
      questionIds.forEach((questionId) => {
        if (questionId in questions) {
          // the question is active, i.e was retrieved from db
          var row = {};
          row["questionId"] = questionId;
          row["questionText"] = questions[questionId].question_text;
          if (
            separatedQuestionIds.includes("-" + questionId) ||
            separatedQuestionIds.includes(questionId + "-")
          ) {
            row["subcheck"] = true;
          } else {
            row["subcheck"] = false;
          }
          console.log("\n....row:\n", row);
          this.allSelectedQuestions.push(row);
        }
      });

      // populate tags, if exist
      response.tags.forEach(tag => {
        if (this.survey.survey_tag == undefined) {
          this.survey.survey_tag = [];
        }
        let tagText = this.tagIdToTextMap[tag.tag_id]
        this.survey.survey_tag.push(tagText);
      });

      console.log(
        "\n..FINAL fetchAndUpdate \nthis.allSelectedQuestions:\n",
        this.allSelectedQuestions
      );

      this.originalSurvey = JSON.parse(JSON.stringify(this.survey));
      console.log(
        "\n.. fetchAndUpdate \nthis.originalSurvey:",
        this.originalSurvey
      );
    },
    async updateTargetsInUse() {
      // fetch targets that are used in other active answers/questions/surveys/predicates
      this.targetsInUse = {};
      var response = await API.getTargetsInUseApi();
      response.targets.forEach((target) => {
        this.targetsInUse[target.target_id] = target;
      });
      response.deriveds.forEach((derived) => {
        this.derivedsInUse[derived.derived_id] = derived;
      });
      console.log("this.targetsInUse:", this.targetsInUse, " this.derivedsInUse", this.derivedsInUse);
    },
    async updateTargetIdNamePairs() {
      const response = await API.getTargetIdNamePairsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        // add special target_name_description which combines the two if description exists
        let target_name_description = item.target_name;
        if (!target_name_description.startsWith("_")
            && item.target_description && item.target_description.length > 0) {
          target_name_description = item.target_name + " (" + item.target_description + ")";
        }
        let pair = {
          text: target_name_description,
          value: item.target_id
        };
        result.push(pair);
      }
      this.targetIdNamePairs = result;
      this.targetIdNamePairs.sort((a,b)=> (b.text > a.text ? 1 : -1))
      //console.log("targetIdNamePairs:", this.targetIdNamePairs);
    },
    async updateTags() {
      //  fetch all tags and extract tagTexts
      var response = await API.getTagsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const tag = response.result[i];
        result.push(tag.tag_text);
        this.tagIdToTextMap[tag.tag_id] = tag.tag_text;
      }
      this.tags = result;
      console.log("this.tags:", this.tags);
      console.log("this.tagIdToTextMap, ", this.tagIdToTextMap);
    },
    async updateSurveyNameToIdDict() {
      const response = await API.getSurveyIdNamePairsApi();
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        this.surveyNameToIdDict[item.survey_name] = item.survey_id;
      }
      console.log("surveyNameToIdDict:", this.surveyNameToIdDict);
    },
    validate() {
      let valid = this.$refs.form.validate();
      console.log("validate valid:", valid);
      if (!valid) {
        this.snackbarState = 1;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }
      console.log("this.allSelectedQuestions.length:", this.allSelectedQuestions.length);

      // check if surveyName already exists and do not allow another (new) survey with an existing name
      if (this.survey.survey_name in this.surveyNameToIdDict
          && this.survey.survey_id != this.surveyNameToIdDict[this.survey.survey_name]) {
        this.valid = false;
        this.snackbarState = 14;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return;
      }

      if (this.allSelectedQuestions.length == 0) {
        this.snackbarState = 9;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure survey name does not contain _,: special characters
      if (this.survey.survey_name.includes("_")
          || this.survey.survey_name.includes(",")
          || this.survey.survey_name.includes(":")) {
        this.snackbarState = 10;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure at least one (A/R/U) target is selected
      if (this.survey.any_target_id.length == 0
          && this.survey.required_target_id.length == 0
          && this.survey.unallowed_target_id.length == 0) {
        this.snackbarState = 12;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure a tab is selected
      if (this.survey.survey_tab != Constants.ME_TAB && this.survey.survey_tab != Constants.CIRCLE_TAB) {
        this.snackbarState = 13;
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // if survey name is edited, check if the original survey was used in any active auto generated targets or deriveds
      if (this.command === "edit"
        && this.originalSurvey.survey_name !== this.survey.survey_name
        && this.isSurveyUsedInActiveTargets(this.originalSurvey.survey_name)) {
        this.snackbarState = 11;
        this.snackbarText = "";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return;
      }

      // if there are any subchecks, make sure they are part of at least two consecutive checks
      for (let i = 0; i < this.allSelectedQuestions.length; i++) {
        let question = this.allSelectedQuestions[i];
        if (question.subcheck) {
          let consecutiveChecks = false;
          let prevQuestion = i > 0 ? this.allSelectedQuestions[i - 1] : null;
          if (prevQuestion != null && prevQuestion.subcheck) {
            consecutiveChecks = true;
          } else {
            let nextQuestion =
              i < this.allSelectedQuestions.length - 1
                ? this.allSelectedQuestions[i + 1]
                : null;
            if (nextQuestion != null && nextQuestion.subcheck) {
              consecutiveChecks = true;
            }
          }
          if (!consecutiveChecks) {
            this.snackbarState =
              this.survey.survey_question_ordering === "ordered" ? 7 : 8;
            this.snackbarColor = this.snackbarColorFailure;
            this.snackbarFlag = true;
            this.valid = false;
            return;
          }
        }
      }

      this.valid = true;
      this.snackbarState = 2;
      this.snackbarColor = this.snackbarColorSuccess;
      this.snackbarFlag = true;
      this.valid = true;
    },
    resetValidation() {
      console.log("survey: ", JSON.stringify(this.survey));
      this.$refs.form.resetValidation();
    },
    isSurveyUsedInActiveTargets(surveyName) {
      for (var [targetId, target] of Object.entries(this.targetsInUse)) {
        if (target.target_name.includes("_" + surveyName)) {
          return true;
        }
      }
      for (var [derivedId, derived] of Object.entries(this.derivedsInUse)) {
        if (derived.derived_name.includes("_" + surveyName)) {
          return true;
        }
      }
      return false;
    },
    async save() {
      this.validate();
      if (!this.valid) {
        return;
      }
      this.updateSeparatedQuestionIds();
      console.log("in save, survey:", JSON.stringify(this.survey));

      var response;
      if (this.command === "edit") {
        console.log("in edit, originalSurvey:", JSON.stringify(this.originalSurvey));
        response = await API.updateSurveyApi(this.originalSurvey, this.survey);
      } else {
        // brand new or cloned survey
        response = await API.addSurveyApi(this.survey);
      }
      if (response.success == 1) {
        this.snackbarState = 3;
        this.snackbarColor = this.snackbarColorSuccess;
        this.updateTags(); // update tags to include newly added ones (if any)
        this.updateTargetIdNamePairs(); // update targets to include autogen targets
      } else {
        this.snackbarState = 4;
        this.snackbarColor = this.snackbarColorFailure;
      }
      this.resetForm();
      this.snackbarFlag = true;
    },
    resetForm() {
      // TimeWindow does not reset (need to set up watcher) so using this for now
      this.$refs.form.reset();
      this.survey.survey_id = null;
      this.survey.survey_name = "";
      this.survey.survey_description = "";
      this.survey.survey_tab = Constants.ME_TAB;
      this.survey.any_target_id = [];
      this.survey.required_target_id = [];
      this.survey.unallowed_target_id = [];
      this.survey.survey_question_id = [];
      this.survey.survey_question_ordering = "ordered";
      this.survey.survey_separated_question_ids = "";
      this.survey.survey_warning_window_seconds = 172800;     // 2 days
      this.survey.survey_expiration_window_seconds = 604800;  // 7 days
      this.survey.survey_submit_cooldown_seconds = 864000000; // 10000 days
      this.survey.survey_unsubmit_cooldown_seconds = 604800;  // 7 days
      this.survey.survey_tag = [];
      this.$refs.form.resetValidation();
    },
    reset() {
      if (confirm("Delete all entries in the form?")) {
        this.resetForm();
      }
    },
    updateSelectedQuestions(selectedQuestions) {
      this.selectedQuestions = Array.from(selectedQuestions);
      // console.log("this.selectedQuestions:", this.selectedQuestions);
    },
    addSelectedQuestions() {
      // Populate draggable table with allSelectedQuestions
      console.log("this.selectedQuestions:", this.selectedQuestions);
      let allSelectedQuestionIds = this.allSelectedQuestions.map(
        (q) => q.questionId
      );
      console.log("allSelectedQuestionIds:", allSelectedQuestionIds);
      this.selectedQuestions.forEach((question) => {
        console.log(
          "questionId:",
          question.questionId,
          " includes:",
          this.allSelectedQuestions.includes(question.questionId)
        );
        if (!allSelectedQuestionIds.includes(question.questionId)) {
          this.allSelectedQuestions.push(question);
          this.survey.survey_question_id.push(question.questionId);
        }
      });
      this.updateSeparatedQuestionIds();
      this.selectedQuestions = [];
      console.log("allSelectedQuestions:", this.allSelectedQuestions);
    },
    removeQuestion(index) {
      var confirmationText = `Remove this queston from the survey?
        \nQuestion: '${this.allSelectedQuestions[index].questionText}'`;
      if (confirm(confirmationText)) {
        this.allSelectedQuestions.splice(index, 1);
        console.log("allSelectedQuestions:", this.allSelectedQuestions);
        this.updateSeparatedQuestionIds();
      }
    },
    dragHandler(items) {
      console.log("dragHandler, items:", items);
      this.allSelectedQuestions = Array.from(items);
      console.log("allSelectedQuestions:", this.allSelectedQuestions);
      this.updateSeparatedQuestionIds();
    },
    updateSeparatedQuestionIds() {
      this.survey.survey_question_id = [];
      this.survey.survey_separated_question_ids = "";
      for (let i = 0; i < this.allSelectedQuestions.length; i++) {
        let questionId = this.allSelectedQuestions[i].questionId;
        this.survey.survey_separated_question_ids += questionId;
        if (i < this.allSelectedQuestions.length - 1) {
          if (
            this.allSelectedQuestions[i].subcheck &&
            this.allSelectedQuestions[i + 1].subcheck
          ) {
            this.survey.survey_separated_question_ids += "-";
          } else {
            this.survey.survey_separated_question_ids += ",";
          }
        }
        this.survey.survey_question_id.push(parseInt(questionId));
      }
    },
    removeTag(item) {
      this.survey.survey_tag.splice(this.survey.survey_tag.indexOf(item), 1)
      this.survey.survey_tag = [...this.survey.survey_tag]
    },
    updateTimeWindowSeconds(update) {
      console.log("..updateTimeWindowSeconds, update:", update);
      this.survey[update["updateKey"]] = update["timeWindowSeconds"];
    },
    isSurveyModified() {
      // return true if some parts of the survey is modified/touched
      if (this.survey.survey_name.length > 0) {
        return true;
      }
      if (this.survey.survey_description.length > 0) {
        return true;
      }
      if (this.survey.any_target_id.length > 0
          || this.survey.required_target_id.length > 0
          || this.survey.unallowed_target_id.length > 0) {
        return true;
      }
      if (this.survey.survey_question_id.length > 0) {
        return true;
      }
      return false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSurveyModified()) {
      console.log("beforeRouteLeave, isSurveyModified: true");
      if (confirm('Do you want to proceed and discard the draft?')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
