<template>
  <v-container fluid>
    <v-row align="start" dense>
      <v-col class="d-flex" cols="3" xs="2" dense>
        <v-select
          v-model="selectedSearchField"
          :items="searchFields"
          label="Search Field"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="2" xs="2" dense>
        <v-select v-model="selectedOperator" :items="operators" label="Operator" outlined dense></v-select>
      </v-col>
      <v-col v-if="searchType === 'text'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model.trim="searchQuery"
          label="Search Text"
          outlined
          dense
          @keyup.enter="searchSurveys"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="searchType === 'constant' || searchType === 'searchableConstant'"
        class="d-flex"
        cols="5"
        xs="4"
        dense
      >
        <v-autocomplete
          v-model="searchQuery"
          :items="constantItems"
          :label="constantLabel"
          outlined
          dense
          @keyup.enter="searchSurveys"
        ></v-autocomplete>
      </v-col>
      <v-col align="start" justify="start" class="d-flex" dense>
        <v-btn
          v-if="searchType === 'text'"
          color="blue darken-2"
          @click="searchSurveys"
          dark
          dense
        >Search</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="surveysDataTable != null"
      :headers="headers"
      :items="surveysDataTable"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, -1]
      }"
      :items-per-page="100"
      dense
      item-key="surveyName"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDialogSurveyDetails(item)">mdi-magnify</v-icon>
        <v-icon small class="mr-2" @click="cloneSurvey(item)">mdi-content-copy</v-icon>
        <v-icon small class="mr-2" @click="editSurvey(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="archieveSurvey(item)">mdi-delete</v-icon>
        <v-icon
          v-if="item.surveyPublished !== 'Yes'"
          small
          class="mr-2"
          @click="publishSurvey(item)"
        >mdi-plus-box-outline</v-icon>
        <v-icon
          v-if="item.surveyPublished === 'Yes'"
          small
          class="mr-2"
          @click="unpublishSurvey(item)"
        >mdi-minus-box-outline</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <strong>Name:</strong>
          {{this.dialogSurvey.survey_name}}
          <br />
          <strong>Description:</strong>
          {{this.dialogSurvey.survey_description}}
          <br />
         <strong>Tab:</strong>
          {{this.dialogSurvey.survey_tab}}
          <br />
          <strong>Published:</strong>
          {{this.dialogSurvey.survey_published === 'true' ? 'Yes' : 'No'}}
          <br />
          <template v-if="this.dialogSurvey.survey_id in surveyAnyTargets">
            <strong v-if="this.dialogSurvey.survey_tab.toLowerCase() == meTab">Targets-Any:</strong>
            <strong v-if="this.dialogSurvey.survey_tab.toLowerCase() == circleTab">SubjectTargets-Any:</strong>
            <ul>
              <li v-for="(target, i) in surveyAnyTargets[this.dialogSurvey.survey_id]" :key="i">
                <template>{{ target.target_name_description }}</template>
              </li>
            </ul>
          </template>
          <template v-if="this.dialogSurvey.survey_id in surveyRequiredTargets">
            <strong v-if="this.dialogSurvey.survey_tab.toLowerCase() == meTab">Targets-Required:</strong>
            <strong v-if="this.dialogSurvey.survey_tab.toLowerCase() == circleTab">SubjectTargets-Required:</strong>
            <ul>
              <li v-for="(target, i) in surveyRequiredTargets[this.dialogSurvey.survey_id]" :key="i">
                <template>{{ target.target_name_description }}</template>
              </li>
            </ul>
          </template>
          <template v-if="this.dialogSurvey.survey_id in surveyUnallowedTargets">
            <strong v-if="this.dialogSurvey.survey_tab.toLowerCase() == meTab">Targets-Unallowed:</strong>
            <strong v-if="this.dialogSurvey.survey_tab.toLowerCase() == circleTab">SubjectTargets-Unallowed:</strong>
            <ul>
              <li v-for="(target, i) in surveyUnallowedTargets[this.dialogSurvey.survey_id]" :key="i">
                <template>{{ target.target_name_description }}</template>
              </li>
            </ul>
          </template>
          <strong>Questions:</strong>
          ({{ (this.dialogSurvey.survey_question_ordering === "shuffle" || this.dialogSurvey.survey_separated_question_ids.includes("-"))
          ? "Shuffle"
          : "Ordered"}})
          <ul>
            <li v-for="questionId in activeQuestionIdsInSurvey" :key="questionId">
              <template>{{ questions[questionId].question_text }}</template>
            </li>
          </ul>
          <template v-if="this.dialogSurvey.survey_id in surveyTags">
            <strong>Tags:</strong>
            {{ surveyTags[this.dialogSurvey.survey_id].toString() }}
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon small class="mr-3" @click="cloneSurvey">mdi-content-copy</v-icon>
          <v-icon small class="mr-3" @click="editSurvey">mdi-pencil</v-icon>
          <v-icon small class="mr-3" @click="archieveSurvey">mdi-delete</v-icon>
          <v-icon
            v-if="this.dialogSurvey.survey_published !== 'true'"
            small
            class="mr-2"
            @click="publishSurvey"
          >mdi-plus-box-outline</v-icon>
          <v-icon
            v-if="this.dialogSurvey.survey_published === 'true'"
            small
            class="mr-2"
            @click="unpublishSurvey"
          >mdi-minus-box-outline</v-icon>
          <v-icon small class="mr-3" @click="dialogClose">mdi-cancel</v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarText }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";
import * as Utils from "../utils/Utils.js";

export default {
  name: "searchSurveys",
  data() {
    return {
      searchFields: [
        "All Fields",
        "SurveyName",
        "SurveyDescription",
        "SurveyTab",
        "SurveyTarget*",
        "SurveyTargetAny",
        "SurveyTargetRequired",
        "SurveyTargetUnallowed",
        "SurveyTag",
        "QuestionOrdering"],
      selectedSearchField: "All Fields",

      // placeholder operators, updates the selected operator via v-model
      operators: ["Contains", "IsEqualTo", "StartsWith", "EndsWith"],
      selectedOperator: "Contains",

      // applicable operators depending on they type, e.g text, float and constants
      textOperators: ["Contains", "StartsWith", "EndsWith", "IsEqualTo"],
      constantOperators: ["IsEqualTo"],
      searchableConstantOperators: [
        "IsEqualTo",
        "Contains",
        "StartsWith",
        "EndsWith",
      ],

      // initiallize search type and query
      // and also constants used for fixed fields (e.g surveyName, target etc)
      searchType: "text",
      searchQuery: "",
      constantItems: [],
      constantLabel: "",
      constantItemsMap: {},

      // surveys and contained questions returned based on above search
      surveyIds: [], // array of survey ids returned from db
      surveys: {}, // key:survey_id, value:survey (fetched from db)
      questions: {}, // key:question_id, value:question (fetched from db)

      // survey targets of type {any, required, unallowed}
      surveyAnyTargets: {}, // key:survey_id, value:[survey_target] (target_presence=any)
      surveyRequiredTargets: {}, // key:survey_id, value:[survey_target] (target_presence=required)
      surveyUnallowedTargets: {}, // key:survey_id, value:[survey_target] (target_presence=unallowed)

      // metadata about existing targets and surveys
      targetMap: {}, // key:targetId, value:target (dict with target_name and target_description)
      questionSurveysMap: {}, // key:questionId, value:[survey]
      surveyIdNameMap: {}, // key:surveyId, value:surveyName
      surveyIdNamePairs: [],
      surveyTags: {}, // key:surveyId, value:[tagText]

      // tags used on surveys, provides tag_text lookup for survey_tag relations fetched from db
      tagIdToTextMap: {}, // key:tag_id, value:tag_text

      // store targets that are in-use to avoid erroneous renaming/archiving/deletion of surveys
      // e.g do not allow above actions if the survey is used in an target or derived predicate
      targetsInUse: {}, // key:target_id, value:target
      derivedsInUse: {}, // key:derived_id, value:derived

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "surveyName",
        },
        {
          text: "Tab",
          align: "start",
          sortable: true,
          value: "surveyTab",
        },
        {
          text: "Published",
          align: "start",
          sortable: true,
          value: "surveyPublished",
        },
        {
          text: "Targets:A/R/U",
          align: "start",
          sortable: true,
          value: "aruTargets",
        },
        {
          text: "Questions",
          align: "start",
          sortable: true,
          value: "surveyQuestions",
        },
        {
          text: "Ordering",
          align: "start",
          sortable: true,
          value: "questionOrdering",
        },
        { text: "Actions", align: "start", sortable: false, value: "actions" },
      ],
      surveysDataTable: null,

      // dialog that shows survey details, it allows deletion and can direct to edit page
      dialog: false,
      dialogSurvey: null,
      dialogSurveyId: null,
      dialogTitle: "Survey Details",

      // snackbar to show a message based on actions taken in data table
      snackbarFlag: false,
      snackbarText: "",
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,
    };
  },
  computed: {
    activeQuestionIdsInSurvey() {
      let questionIds = this.dialogSurvey.survey_separated_question_ids
        .replace(/-/g, ",")
        .split(",");
      let activeQuestionIds = [];
      questionIds.forEach((questionId) => {
        if (questionId in this.questions) {
          activeQuestionIds.push(questionId);
        }
      });
      return activeQuestionIds;
    },
    meTab() {
      return Constants.ME_TAB;
    },
    circleTab() {
      return Constants.CIRCLE_TAB;
    },
  },
  created() {
    // TODO: can we identify and avoid unnecessary fetches? or just make a batch call
    this.updateSurveyNameConstants();
    this.updateSurveyDescriptionConstants();
    this.updateSurveyTabConstants();
    this.updateSurveyTargetConstants();
    this.updateSurveyTagConstants();
    this.updateQuestionOrderingConstants();
    this.updateTargetMap();
    this.updateSurveyIdNamePairs();
    this.updateTargetsInUse();
  },
  methods: {
    getResults(response, fieldName) {
      return response.success && response.result && response.result.length > 0
        ? response.result.map((item) => item[fieldName])
        : [];
    },
    async updateSurveyNameConstants() {
      // fetch and update Survey Name values
      const response = await API.getDistinctValuesApi(
        Constants.SURVEY_TABLE,
        Constants.SURVEY_NAME
      );
      const results = this.getResults(response, Constants.SURVEY_NAME);
      this.constantItemsMap["SurveyName"] = results;
      console.log("SurveyName, ", this.constantItemsMap);
    },
    async updateSurveyDescriptionConstants() {
      // fetch and update Survey Name values
      const response = await API.getDistinctValuesApi(
        Constants.SURVEY_TABLE,
        Constants.SURVEY_DESCRIPTION
      );
      const results = this.getResults(response, Constants.SURVEY_DESCRIPTION);
      this.constantItemsMap["SurveyDescription"] = results;
      console.log("SurveyDescription, ", this.constantItemsMap);
    },
    async updateSurveyTabConstants() {
      this.constantItemsMap["SurveyTab"] = Constants.SURVEY_TABS.map(t => Utils.capitalizeWord(t));
      console.log("SurveyTab, ", this.constantItemsMap);
    },
    async updateSurveyTargetConstants() {
      this.constantItemsMap["SurveyTarget*"] = []
      this.constantItemsMap["SurveyTargetAny"] = []
      this.constantItemsMap["SurveyTargetRequired"] = []
      this.constantItemsMap["SurveyTargetUnallowed"] = []
      const response = await API.getTargetsWithPresenceInProfileApi(Constants.SURVEY_TABLE);
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        if (!(this.constantItemsMap["SurveyTarget*"].includes(item.target_name))) {
          this.constantItemsMap["SurveyTarget*"].push(item.target_name)
        }
        if (item.target_presence_in_profile == Constants.ANY) {
          this.constantItemsMap["SurveyTargetAny"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.REQUIRED) {
          this.constantItemsMap["SurveyTargetRequired"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.UNALLOWED) {
          this.constantItemsMap["SurveyTargetUnallowed"].push(item.target_name)
        }
      }
      console.log("SurveyTarget*, ", this.constantItemsMap);
    },
    async updateSurveyTagConstants() {
      // fetch and update survey tags
      const response = await API.getTagsInUseApi(Constants.SURVEY_TAG_TABLE);
      this.constantItemsMap["SurveyTag"] = this.getResults(response, Constants.TAG_TEXT);
      console.log("SurveyTag, ", this.constantItemsMap);
      for (let i = 0; i < response.result.length; i++) {
        const tag = response.result[i];
        this.tagIdToTextMap[tag.tag_id] = tag.tag_text;
      }
      console.log("this.tagIdToTextMap, ", this.tagIdToTextMap);
    },
    async updateQuestionOrderingConstants() {
      // update Queston Ordering values
      this.constantItemsMap["QuestionOrdering"] =
        Constants.SURVEY_QUESTION_ORDERING;
      console.log("QuestionOrdering, ", this.constantItemsMap);
    },
    async updateTargetMap() {
      const response = await API.getTargetIdNamePairsApi();
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        this.targetMap[item.target_id] = {}
        this.targetMap[item.target_id].target_name = item.target_name;
        this.targetMap[item.target_id].target_description = item.target_description;
        this.targetMap[item.target_id].target_name_description = item.target_description.length == 0
          ? item.target_name
          : item.target_name + " (" + item.target_description + ")"
      }
      console.log("targetMap:", this.targetMap);
    },
    async updateSurveyIdNamePairs() {
      const response = await API.getSurveyIdNamePairsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        let pair = {
          text: item.survey_name,
          value: item.survey_id,
        };
        result.push(pair);
        this.surveyIdNameMap[item.survey_id] = item.survey_name;
      }
      this.surveyIdNamePairs = result;
      console.log("surveyIdNamePairs:", this.surveyIdNamePairs);
      console.log("surveyIdNameMap:", this.surveyIdNameMap);
    },
    async updateTargetsInUse() {
      // fetch targets that are used in other active answers/questions/surveys/predicates
      this.targetsInUse = {};
      var response = await API.getTargetsInUseApi();
      response.targets.forEach((target) => {
        this.targetsInUse[target.target_id] = target;
      });
      response.deriveds.forEach((derived) => {
        this.derivedsInUse[derived.derived_id] = derived;
      });
      console.log("this.targetsInUse:", this.targetsInUse, " this.derivedsInUse", this.derivedsInUse);
    },
    getSqlColumn() {
      if (this.selectedSearchField == "SurveyName") {
        return "survey_name";
      }
      if (this.selectedSearchField == "SurveyDescription") {
        return "survey_description";
      }
      return "unknown_column"; // error
    },
    getSqlOperator() {
      const op = this.selectedOperator;
      if (op == "EqualTo" || op == "IsEqualTo") {
        return "=";
      }
      if (op == "Contains" || op == "StartsWith" || op == "EndsWith") {
        return "LIKE";
      }
      return "unknown_operator"; // error
    },
    getSqlSearchQuery() {
      this.searchQuery = this.searchQuery.trim();
      if (this.selectedOperator == "Contains") {
        return `%${this.searchQuery}%`;
      }
      if (this.selectedOperator == "StartsWith") {
        return `${this.searchQuery}%`;
      }
      if (this.selectedOperator == "EndsWith") {
        return `%${this.searchQuery}`;
      }
      return this.searchQuery;
    },
    async searchSurveys() {
      console.log("searchSurveys:");
      const field = this.selectedSearchField;
      var response;
      if (field === "All Fields") {
        console.log("calling searchSurveysByAllTextColumnsApi");
        response = await API.searchSurveysByAllTextColumnsApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "SurveyName" || field === "SurveyDescription") {
        console.log("calling searchSurveysByColumn");
        response = await API.searchSurveysByColumnApi(
          Constants.SURVEY_TABLE,
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "SurveyTab") {
        response = await API.searchSurveysBySurveyTabApi(
          this.getSqlSearchQuery().toLowerCase() // since we capitalize Me/Circle
        );
      } else if (field === "SurveyTarget*") {
        response = await API.searchSurveysByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          ""
        );
      } else if (field === "SurveyTargetAny") {
        response = await API.searchSurveysByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "any"
        );
      } else if (field === "SurveyTargetRequired") {
        response = await API.searchSurveysByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "required"
        );
      } else if (field === "SurveyTargetUnallowed") {
        response = await API.searchSurveysByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "unallowed"
        );
      } else if (field === "SurveyTag") {
        response = await API.searchSurveysByTagApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "QuestionOrdering") {
        response = await API.searchSurveysByQuestionOrderingApi(
          this.getSqlSearchQuery()
        );
      }
      console.log("\n response:", JSON.stringify(response));

      // clear surveys dictionary; insert retrieved surveys with key survey_id
      this.surveys = {};
      this.surveyIds = [];
      response.surveys.forEach((survey) => {
        if (survey.survey_id > 0) { // to allow selecting only certain/recent items (if needed)
          this.surveyIds.push(survey.survey_id);
          this.surveys[survey.survey_id] = survey;
        }
      });
      console.log("\nthis.surveys:", this.surveys);

      // clear questions dictionary; insert retrieved questions with key question_id
      this.questions = {};
      this.questionIds = [];
      response.questions.forEach((question) => {
        this.questionIds.push(question.question_id);
        this.questions[question.question_id] = question;
      });
      console.log("\nthis.questions:", this.questions);

      // clear survey*Targets dictionaries; insert received survey_target items with key survey_id
      this.surveyAnyTargets = {};
      this.surveyRequiredTargets = {};
      this.surveyUnallowedTargets = {};
      response.surveyTargets.forEach(target => {
        target["target_name"] = this.targetMap[target.target_id].target_name;
        target["target_description"] = this.targetMap[target.target_id].target_description;
        target["target_name_description"] = this.targetMap[target.target_id].target_name_description
        if (target.target_presence_in_profile == Constants.ANY) {
          if (!(target.survey_id in this.surveyAnyTargets)) {
            this.surveyAnyTargets[target.survey_id] = [];
          }
          this.surveyAnyTargets[target.survey_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.REQUIRED) {
          if (!(target.survey_id in this.surveyRequiredTargets)) {
            this.surveyRequiredTargets[target.survey_id] = [];
          }
          this.surveyRequiredTargets[target.survey_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.UNALLOWED) {
          if (!(target.survey_id in this.surveyUnallowedTargets)) {
            this.surveyUnallowedTargets[target.survey_id] = [];
          }
          this.surveyUnallowedTargets[target.survey_id].push(target);
        }
      });
      // console.log("\nthis.surveyAnyTargets:", this.surveyAnyTargets);
      // console.log("this.surveyRequiredTargets:", this.surveyRequiredTargets);
      // console.log("this.surveyUnallowedTargets:", this.surveyUnallowedTargets);

      // clear surveyTags and insert if they exist
      this.surveyTags = {}; // key:surveyId, value:[tag_text]
      response.tags.forEach(tag => {
        if (!(tag.survey_id in this.surveyTags)) {
          this.surveyTags[tag.survey_id] = [];
        }
        let tagText = this.tagIdToTextMap[tag.tag_id]
        this.surveyTags[tag.survey_id].push(tagText);
      });
      console.log("\nthis.surveyTags:", this.surveyTags);

      // construct questions Data Table to be shown as rows in table
      this.surveysDataTable = [];
      this.surveyIds.forEach((surveyId) => {
        var row = {};
        let survey = this.surveys[surveyId];
        row["surveyId"] = survey.survey_id;
        row["surveyName"] = survey.survey_name;
        row["surveyTab"] = Utils.capitalizeWord(survey.survey_tab);
        row["aruTargets"] = (this.surveyAnyTargets[surveyId] || []).length.toString()
          + "/" + (this.surveyRequiredTargets[surveyId] || []).length.toString()
          + "/" + (this.surveyUnallowedTargets[surveyId] || []).length.toString();
        row["surveyQuestions"] = 0;
        survey.survey_separated_question_ids
          .replace(/-/g, ",")
          .split(",")
          .forEach((qid) => {
            if (qid in this.questions) {
              // this is an active question retrieved from db
              row["surveyQuestions"] += 1;
            }
          });
        row["questionOrdering"] =
          survey.survey_question_ordering === "shuffle" ||
          survey.survey_separated_question_ids.includes("-")
            ? "Shuffle"
            : "Ordered";
        row["surveyPublished"] =
          survey.survey_published === "true" ? "Yes" : "No";
        this.surveysDataTable.push(row);
      });
    },
    getDialogSurveyDetails(survey) {
      console.log("..getDialogSurveyDetails \nsurvey:", survey);
      this.dialogSurveyId = survey.surveyId;
      this.dialogSurvey = { ...this.surveys[this.dialogSurveyId] };
      this.dialogSurvey.survey_tab = Utils.capitalizeWord(this.dialogSurvey.survey_tab)
      this.dialog = true;
    },
    dialogClose() {
      this.dialog = false;
    },
    async cloneSurvey(survey) {
      if (survey == null || !("surveyId" in survey)) {
        this.surveysDataTable.forEach((item) => {
          if (item.surveyId === this.dialogSurvey.survey_id) {
            survey = item;
          }
        });
      }
      console.log("..clone \nsurvey:", survey);
      var confirmationText = "Clone this survey and create a new one?";
      if (confirm(`${confirmationText}\n\nSurvey: '${survey.surveyName}'`)) {
        this.$router.push({
          name: "AddSurvey",
          params: { survey_id: `${survey.surveyId}`, command: "clone" },
        });
      }
    },
    async editSurvey(survey) {
      if (survey == null || !("surveyId" in survey)) {
        this.surveysDataTable.forEach((item) => {
          if (item.surveyId === this.dialogSurvey.survey_id) {
            survey = item;
          }
        });
      }
      console.log("..edit \nsurvey:", survey);
      var confirmationText = "Edit this survey?";
      if (confirm(`${confirmationText}\n\nSurvey: '${survey.surveyName}'`)) {
        this.$router.push({
          name: "AddSurvey",
          params: { survey_id: `${survey.surveyId}`, command: "edit" },
        });
      }
    },
    async archieveSurvey(survey) {
      if (survey == null || !("surveyId" in survey)) {
        this.surveysDataTable.forEach((item) => {
          if (item.surveyId === this.dialogSurvey.survey_id) {
            survey = item;
          }
        });
      }
      console.log("..archieveSurvey \nsurvey:", survey);
      var confirmationText = "Archieve this survey?";
      if (confirm(`${confirmationText}\n\nSurvey: '${survey.surveyName}'`)) {
        if (survey.surveyPublished === "Yes") {
          this.snackbarText = "Please unpublish the survey first before archieving!";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          return;
        }

        // check if this survey is used in any active auto generated targets or deriveds
        if (this.isSurveyUsedInActiveTargets(survey.surveyName)) {
          this.snackbarText = "The survey is used in an active (auto generated) target or derived predicate!";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          return;
        }

        const response = await API.archieveSurveyApi(
          survey.surveyId.toString(),
          survey.surveyName
        );
        console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully archieved the survey!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText = "Failed to archieve the survey!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
        this.dialogClose();
      }
    },
    isSurveyUsedInActiveTargets(surveyName) {
      for (var [targetId, target] of Object.entries(this.targetsInUse)) {
        if (target.target_name.includes("_" + surveyName)) {
          return true;
        }
      }
      for (var [derivedId, derived] of Object.entries(this.derivedsInUse)) {
        if (derived.derived_name.includes("_" + surveyName)) {
          return true;
        }
      }
      return false;
    },
    async publishSurvey(survey) {
      if (survey == null || !("surveyId" in survey)) {
        this.surveysDataTable.forEach((item) => {
          if (item.surveyId === this.dialogSurvey.survey_id) {
            survey = item;
          }
        });
      }
      console.log("..publishSurvey \nsurvey:", survey);
      var confirmationText = "Publish this survey?";
      if (confirm(`${confirmationText}\n\nSurvey: '${survey.surveyName}'`)) {
        // TODO: here we can check if there are any skip-questions that point to external surveys
        // note: this is anti-pattern but may not be a critical failure
        const response = await API.updateColumnValueApi(
          Constants.SURVEY_TABLE,
          Constants.SURVEY_PUBLISHED,
          "true",
          Constants.SURVEY_ID,
          survey.surveyId.toString()
        );
        console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully published the survey!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText = "Failed to publish the survey!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
        this.dialogClose();
      }
    },
    async unpublishSurvey(survey) {
      if (survey == null || !("surveyId" in survey)) {
        this.surveysDataTable.forEach((item) => {
          if (item.surveyId === this.dialogSurvey.survey_id) {
            survey = item;
          }
        });
      }
      console.log("..unpublishSurvey \nsurvey:", survey);
      var confirmationText = "Unpublish this survey?";
      if (confirm(`${confirmationText}\n\nSurvey: '${survey.surveyName}'`)) {
        const response = await API.updateColumnValueApi(
          Constants.SURVEY_TABLE,
          Constants.SURVEY_PUBLISHED,
          "false",
          Constants.SURVEY_ID,
          survey.surveyId.toString()
        );
        console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully unpublished the survey!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText = "Failed to unpublish the survey!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
        this.dialogClose();
      }
    },
    resetDataTable() {
      this.surveysDataTable = null;
      this.selectedSearchField = "All Fields";
      this.selectedOperator = "Contains";
      this.searchQuery = " ";
      this.searchType = "text";
    },
  },
  watch: {
    selectedSearchField: function (newField, oldField) {
      this.searchQuery = "";
      this.surveysDataTable = null;
      if (newField == "All Fields") {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      } else if (newField == "SurveyName"
        || newField == "SurveyDescription"
        || newField.includes("SurveyTarget")
        || newField == "SurveyTag") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "searchableConstant";
        this.operators = this.searchableConstantOperators;
        this.selectedOperator = this.searchableConstantOperators[0];
      } else if (newField == "SurveyTab" || newField == "QuestionOrdering") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "constant";
        this.operators = this.constantOperators;
        this.selectedOperator = this.constantOperators[0];
      } else {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      }
    },
    selectedOperator: function (newOperator, oldOperator) {
      this.searchQuery = "";
      this.surveysDataTable = null;
      console.log("selectedOperator update, this.searchType:", this.searchType);
      if (newOperator == "IsEqualTo") {
        this.constantLabel = this.selectedSearchField;
        this.constantItems =
          this.constantItemsMap[this.selectedSearchField] || [];
        if (
          this.selectedSearchField == "SurveyName" ||
          this.selectedSearchField == "SurveyDescription" ||
          this.selectedSearchField.includes("SurveyTarget") ||
          this.selectedSearchField == "SurveyTag"
        ) {
          this.searchType = "searchableConstant";
          this.operators = this.searchableConstantOperators;
        } else if (
          this.selectedSearchField == "SurveyTab" ||
          this.selectedSearchField == "QuestionOrdering"
        ) {
          this.searchType = "constant";
          this.operators = this.constantOperators;
        }
      } else if (
        newOperator == "Contains" ||
        newOperator == "StartsWith" ||
        newOperator == "EndsWith"
      ) {
        this.searchQuery = " ";
        this.searchType = "text";
      }
    },
    searchQuery: function (newQuery, oldQuery) {
      if (this.surveysDataTable != null) {
        this.surveysDataTable = null;
      }
      if (
        (this.searchType == "constant" ||
          this.searchType == "searchableConstant") &&
        newQuery.trim().length > 0
      ) {
        this.searchSurveys();
      }
    },
  },
};
</script>
