<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer
        v-if="loggedIn"
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
      >
        <navigation-group v-bind:items="questionItems" />
        <navigation-group v-bind:items="targetItems" />
        <navigation-group v-bind:items="surveyItems" />
        <navigation-group v-bind:items="postItems" />
        <navigation-group v-bind:items="careItems" />
        <navigation-group v-bind:items="contentManagementItems" />
      </v-navigation-drawer>
      <v-app-bar
        v-if="loggedIn"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="blue darken-3"
        dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 175px" class="ml-0 pl-0 mr-7 pr-10">
          <span class="hidden-sm-and-down">Tibi Health</span>
        </v-toolbar-title>
        <v-text-field
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search"
          class="hidden-sm-and-down"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="handleLogout">mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main>
        <v-container fluid fill-height>
          <v-row class="fill-height">
            <v-col cols="12" class="fill-height">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
import Vuetify from "./plugins/vuetify";
import {
  mdiCommentQuestionOutline,
  mdiCommentSearchOutline,
  mdiTextBoxMultipleOutline,
  mdiTextBoxSearchOutline,
  mdiLogout,
  mdiTrashCanOutline,
  mdiMagnify,
  mdiContentCopy,
  mdiTargetVariant,
  mdiCardSearchOutline,
  mdiNoteOutline,
  mdiNoteSearchOutline,
  mdiDoctor,
  mdiCalendarClock,
  mdiDatabase,
  mdiFunction,
} from "@mdi/js";
import NavigationGroup from "./components/NavigationGroup";
import { router } from "./router";

import { AmplifyEventBus } from "aws-amplify-vue";
import { getUser, isUserAdmin } from "./utils/Auth.js";

export default {
  name: "app",
  components: {
    NavigationGroup,
  },
  data: () => ({
    drawer: null,
    questionItems: [
      {
        text: "Add Question",
        icon: mdiCommentQuestionOutline,
        link: "/addQuestion",
      },
      {
        text: "Search Questions",
        icon: mdiCommentSearchOutline,
        link: "/searchQuestions",
      },
    ],
    targetItems: [
      {
        text: "Add Target",
        icon: mdiTargetVariant,
        link: "/addTarget",
      },
      {
        text: "Search Targets",
        icon: mdiCardSearchOutline,
        link: "/searchTargets",
      },
    ],
    surveyItems: [
      {
        text: "Add Survey",
        icon: mdiTextBoxMultipleOutline,
        link: "/addSurvey",
      },
      {
        text: "Search Surveys",
        icon: mdiTextBoxSearchOutline,
        link: "/searchSurveys",
      },
    ],
    postItems: [
      {
        text: "Add Post",
        icon: mdiNoteOutline,
        link: "/addPost",
      },
      {
        text: "Search Posts",
        icon: mdiNoteSearchOutline,
        link: "/searchPosts",
      },
    ],
    careItems: [
      {
        text: "Review Providers",
        icon: mdiDoctor,
        link: "/reviewProviders",
      },
      {
        text: "Review Proposals",
        icon: mdiCalendarClock,
        link: "/reviewProposals",
      },
    ],
    contentManagementItems: [
      {
        text: "Manage ContentDB",
        icon: mdiDatabase,
        link: "/manageContentDb",
      },
      {
        text: "Run user-content-targeting",
        icon: mdiFunction,
        link: "/runUserContentTargeting",
      },

    ],
    loggedIn: false,
  }),
  created() {
    this.confirmAdmin();
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        getUser().then(user => {
          if (!user) {
            this.loggedIn = false;
          } else if (isUserAdmin(user)) {
            this.loggedIn = true;
          } else {
            this.handleLogout();
          }
        });
      } else {
        router.push({ name: 'SignIn' }, () => {})
      }
    });
  },
  methods: {
    async confirmAdmin() {
      const user = await getUser();
      if (user) {
        const isAdmin = await isUserAdmin(user);
        if (isAdmin) {
          this.loggedIn = true;
        } else {
          this.handleLogout();
        }
      } else {
        this.loggedIn = false;
        router.push({ name: "SignIn" }, () => {});
      }
    },
    async handleLogout() {
      this.loggedIn = false;
      router.push({ name: "SignIn" }, () => {});
      try {
        await Auth.signOut();
      } catch (e) {
        console.error("Amplify signout failed, e: ", e);
      }
    },
  },
};
</script>
