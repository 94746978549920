import { render, staticRenderFns } from "./SearchTargets.vue?vue&type=template&id=4cd2ae05&"
import script from "./SearchTargets.vue?vue&type=script&lang=js&"
export * from "./SearchTargets.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports