/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "6c31cc7120644f918f5e87029e20b4d4",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "6c31cc7120644f918f5e87029e20b4d4",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://b5tf9u7tgi.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "contactapi",
            "endpoint": "https://8srhw5x9p7.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "contentdbapi",
            "endpoint": "https://g3qehfh5jg.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "providerapplyapi",
            "endpoint": "https://98l68pkkt6.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "tcareapi",
            "endpoint": "https://3h664e0q83.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "tcircleapi",
            "endpoint": "https://ia2cl07ru2.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "tgenericapi",
            "endpoint": "https://3uxjxl1i34.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jifnw3eao5frhkteife3ejfz4u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-45v45oiosbetjkz7ihdocwk4ve",
    "aws_cognito_identity_pool_id": "us-east-1:23efbd9b-6fe4-41c4-a169-0ff77332c138",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FZqTipVVG",
    "aws_user_pools_web_client_id": "3t9rjcgk7b1tc3ccu1tlpdjadr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "public-docs-tibihealth-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "convert": {
            "transcription": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
