<template>
  <v-container fluid class="mx-0 px-0 my-0 py-0" dense>
    <!--p>TargetComponent, value: {{value}}</p-->
    <!--p>TargetComponent, targetIdNamePairs: {{targetIdNamePairs}}</p-->
    <v-row align="center" dense>
      <v-col cols="auto" dense justify="start" class="my-0 py-0">
        <div style="color:#616161">{{this.targetLabel}}</div>
      </v-col>
      <v-col align-self="auto" dense justify="end" class="my-0 py-0">
        <v-autocomplete
          v-model="value.any_target_id"
          :items="anyTargetIdNamePairs"
          label="Any"
          multiple
          dense
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col align-self="auto" dense class="my-0 py-0">
        <v-autocomplete
          v-model="value.required_target_id"
          :items="requiredTargetIdNamePairs"
          label="Required"
          multiple
          dense
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col align-self="auto" dense class="my-0 py-0">
        <v-autocomplete
          v-model="value.unallowed_target_id"
          :items="unallowedTargetIdNamePairs"
          label="Unallowed"
          multiple
          dense
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  data: () => ({
    anyTargetIdNamePairs: [{ text: "", value: 0 }],
    requiredTargetIdNamePairs: [{ text: "", value: 0 }],
    unallowedTargetIdNamePairs: [{ text: "", value: 0 }],
  }),
  created() {
    this.updateTargetIdNamePairs();
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    targetIdNamePairs: Array,
    targetLabel: String
  },
  methods: {
    updateTargetIdNamePairs() {
      //console.log("TARGET-COMPONENT 1, this.targetIdNamePairs: ", this.targetIdNamePairs);
      this.anyTargetIdNamePairs = []
      for (let i = 0; i < this.targetIdNamePairs.length; i++) {
        if (this.value.required_target_id.includes(this.targetIdNamePairs[i].value)) {
          continue
        }
        if (this.value.unallowed_target_id.includes(this.targetIdNamePairs[i].value)) {
          continue
        }
        this.anyTargetIdNamePairs.push(this.targetIdNamePairs[i])
      }
      //console.log("TARGET-COMPONENT 2.1, anyTargetIdNamePairs: ", this.anyTargetIdNamePairs)

      this.requiredTargetIdNamePairs = []
      for (let i = 0; i < this.targetIdNamePairs.length; i++) {
        if (this.value.any_target_id.includes(this.targetIdNamePairs[i].value)) {
          continue
        }
        if (this.value.unallowed_target_id.includes(this.targetIdNamePairs[i].value)) {
          continue
        }
        this.requiredTargetIdNamePairs.push(this.targetIdNamePairs[i])
      }
      //console.log("TARGET-COMPONENT 2.2, requiredTargetIdNamePairs: ", this.requiredTargetIdNamePairs)

      this.unallowedTargetIdNamePairs = []
      for (let i = 0; i < this.targetIdNamePairs.length; i++) {
        if (this.value.required_target_id.includes(this.targetIdNamePairs[i].value)) {
          continue
        }
        if (this.value.any_target_id.includes(this.targetIdNamePairs[i].value)) {
          continue
        }
        this.unallowedTargetIdNamePairs.push(this.targetIdNamePairs[i])
      }
      //console.log("TARGET-COMPONENT 2.3, unallowedTargetIdNamePairs: ", this.unallowedTargetIdNamePairs)
    }
  },
  computed: {
  },
  watch: {
    'value.any_target_id': function (newField, oldField) {
      this.updateTargetIdNamePairs();
    },
    'value.required_target_id': function (newField, oldField) {
      this.updateTargetIdNamePairs();
    },
    'value.unallowed_target_id': function (newField, oldField) {
      this.updateTargetIdNamePairs();
    },
    'targetIdNamePairs': function (newField, oldField) {
      this.updateTargetIdNamePairs();
    }
  },
};
</script>
