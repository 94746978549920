<template>
    <SearchQuestionsComponent
      :enableSelectingSurveys="enableSelectingSurveys"
      @onUpdateSelectedQuestions="updateSelectedQuestions"
    />
</template>

<script>
import SearchQuestionsComponent from "../components/SearchQuestionsComponent";

export default {
  components: {
    SearchQuestionsComponent
  },
  data() {
    return {
      // selected rows (questions) in the data table
      selectedQuestions: [],

      // flag to show checkbox in data table and allow adding selected questions to surveys
      enableSelectingSurveys: true
    };
  },
  methods: {
    updateSelectedQuestions(selectedQuestions) {
      this.selectedQuestions = Array.from(selectedQuestions);
    }
  }
};
</script>