<template>
  <v-container fluid>
    <v-row align="start" dense>
      <v-col class="d-flex" cols="3" xs="2" dense>
        <v-select
          v-model="selectedSearchField"
          :items="searchFields"
          label="Search Field"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="2" xs="2" dense>
        <v-select v-model="selectedOperator" :items="operators" label="Operator" outlined dense></v-select>
      </v-col>
      <v-col v-if="searchType === 'text'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model.trim="searchQuery"
          label="Search Text"
          outlined
          dense
          @keyup.enter="searchPosts"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="searchType === 'constant' || searchType === 'searchableConstant'"
        class="d-flex"
        cols="5"
        xs="4"
        dense
      >
        <v-autocomplete
          v-model="searchQuery"
          :items="constantItems"
          :label="constantLabel"
          outlined
          dense
          @keyup.enter="searchPosts"
        ></v-autocomplete>
      </v-col>
      <v-col align="start" justify="start" class="d-flex" dense>
        <v-btn
          v-if="searchType === 'text'"
          color="blue darken-2"
          @click="searchPosts"
          dark
          dense
        >Search</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="postsDataTable != null"
      :headers="headers"
      :items="postsDataTable"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, -1]
      }"
      :items-per-page="100"
      dense
      item-key="postId"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getPostPreviewDetails(item)">mdi-magnify</v-icon>
        <v-icon small class="mr-2" @click="clonePost(item)">mdi-content-copy</v-icon>
        <v-icon small class="mr-2" @click="editPost(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="archievePost(item)">mdi-delete</v-icon>
        <v-icon
          v-if="item.postPublished !== 'Yes'"
          small
          class="mr-2"
          @click="publishPost(item)"
        >mdi-plus-box-outline</v-icon>
        <v-icon
          v-if="item.postPublished === 'Yes'"
          small
          class="mr-2"
          @click="unpublishPost(item)"
        >mdi-minus-box-outline</v-icon>
      </template>
    </v-data-table>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarText }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
    <br />
    <PostPreview
      v-if="showPreview"
      show-preview
      v-model.trim="post"
      @closePreview="showPreview=false"
      lazy-validation
    />
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Utils from "../utils/Utils.js";
import * as Constants from "../utils/Constants.js";
import PostPreview from "../components/PostPreview";

export default {
  components: {
    PostPreview
  },
  name: "searchPosts",
  data() {
    return {
      searchFields: [
        "All Fields",
        "PostTitle",
        "PostBody",
        "PostTarget*",
        "PostTargetAny",
        "PostTargetRequired",
        "PostTargetUnallowed",
        "PostKeyword",
        "PostTag",
        "LinkText",
        "LinkType"],
      selectedSearchField: "All Fields",

      // placeholder operators, updates the selected operator via v-model
      operators: ["Contains", "IsEqualTo", "StartsWith", "EndsWith"],
      selectedOperator: "Contains",

      // applicable operators depending on they type, e.g text, float and constants
      textOperators: ["Contains", "StartsWith", "EndsWith", "IsEqualTo"],
      constantOperators: ["IsEqualTo"],
      searchableConstantOperators: [
        "IsEqualTo",
        "Contains",
        "StartsWith",
        "EndsWith",
      ],

      // initiallize search type and query
      // and also constants used for fixed fields (e.g postTitle, target etc)
      searchType: "text",
      searchQuery: "",
      constantItems: [],
      constantLabel: "",
      constantItemsMap: {},

      // posts and contained links returned based on above search
      postIds: [], // array of post ids returned from db
      posts: {}, // key:post_id, value:post (fetched from db)
      links: {}, // key:link_id, value:link (fetched from db)
      postKeywords: {}, // key:post_id, value:[keywordText]

      // post targets of type {any, required, unallowed}
      postAnyTargets: {}, // key:post_id, value:[post_target] (target_presence=any)
      postRequiredTargets: {}, // key:post_id, value:[post_target] (target_presence=required)
      postUnallowedTargets: {}, // key:post_id, value:[post_target] (target_presence=unallowed)

      // metadata about existing targets and posts
      targetMap: {}, // key:targetId, value:target (dict with target_name and target_description)
      postTags: {}, // key:postId, value:[tagText]

      // tags used on posts, provides tag_text lookup for post_tag relations fetched from db
      tagIdToTextMap: {}, // key:tag_id, value:tag_text

      // keywords used on posts, provides keyword_text lookup for post_keyword relations fetched from db
      keywordIdToTextMap: {}, // key:keyword_id, value:keyword_text

      headers: [
        {
          text: "Post Title",
          align: "start",
          sortable: true,
          value: "postTitle",
        },
        {
          text: "Published",
          align: "start",
          sortable: true,
          value: "postPublished",
        },
        {
          text: "Targets:A/R/U",
          align: "start",
          sortable: true,
          value: "aruTargets",
        },
        {
          text: "Keywords",
          align: "start",
          sortable: true,
          value: "postKeywords",
        },
        {
          text: "Images",
          align: "start",
          sortable: true,
          value: "postImages",
        },
        {
          text: "Videos",
          align: "start",
          sortable: true,
          value: "postVideos",
        },
        {
          text: "Docs",
          align: "start",
          sortable: true,
          value: "postDocs",
        },
        {
          text: "Urls",
          align: "start",
          sortable: true,
          value: "postUrls",
        },
        { text: "Actions", align: "start", sortable: false, value: "actions" },
      ],
      // note: postsDataTable uses postId as the item-key (vs postTitle) since admins may clone
      // posts during adjusting some fields (this causes duplicate 'key' error with postTitle)
      postsDataTable: null,

      // dialog that shows post details, it allows deletion and can direct to edit page
      dialog: false,
      dialogPost: null,
      dialogPostId: null,
      dialogTitle: "Post Details",

      // snackbar to show a message based on actions taken in data table
      snackbarFlag: false,
      snackbarText: "",
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,

      // to enable/disable post preview
      post: {},
      showPreview: false,
    };
  },
  created() {
    // TODO: can we identify and avoid unnecessary fetches? or just make a batch call
    this.updatePostTitleConstants();
    this.updatePostTargetConstants();
    this.updatePostKeywordConstants();
    this.updatePostTagConstants();
    this.updateLinkTextConstants();
    this.updateLinkTypeConstants();
    this.updateTargetMap();
    this.showPreview = false;
  },
  methods: {
    getResults(response, fieldName) {
      return response.success && response.result && response.result.length > 0
        ? response.result.map((item) => item[fieldName])
        : [];
    },
    async updatePostTitleConstants() {
      // fetch and update Post Name values
      const response = await API.getDistinctValuesApi(
        Constants.POST_TABLE,
        Constants.POST_TITLE
      );
      const results = this.getResults(response, Constants.POST_TITLE);
      this.constantItemsMap["PostTitle"] = results;
      //console.log("PostTitle, ", this.constantItemsMap);
    },
    async updatePostTargetConstants() {
      this.constantItemsMap["PostTarget*"] = []
      this.constantItemsMap["PostTargetAny"] = []
      this.constantItemsMap["PostTargetRequired"] = []
      this.constantItemsMap["PostTargetUnallowed"] = []
      const response = await API.getTargetsWithPresenceInProfileApi(Constants.POST_TABLE);
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        if (!(this.constantItemsMap["PostTarget*"].includes(item.target_name))) {
          this.constantItemsMap["PostTarget*"].push(item.target_name)
        }
        if (item.target_presence_in_profile == Constants.ANY) {
          this.constantItemsMap["PostTargetAny"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.REQUIRED) {
          this.constantItemsMap["PostTargetRequired"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.UNALLOWED) {
          this.constantItemsMap["PostTargetUnallowed"].push(item.target_name)
        }
      }
      console.log("PostTarget*, ", this.constantItemsMap);
    },
    async updatePostKeywordConstants() {
      // fetch and update post keywords
      const response = await API.getKeywordsInUseApi(Constants.POST_KEYWORD_TABLE);
      this.constantItemsMap["PostKeyword"] = this.getResults(response, Constants.KEYWORD_TEXT);
      //console.log("PostKeyword, ", this.constantItemsMap);
      for (let i = 0; i < response.result.length; i++) {
        const keyword = response.result[i];
        this.keywordIdToTextMap[keyword.keyword_id] = keyword.keyword_text;
      }
      //console.log("this.keywordIdToTextMap, ", this.keywordIdToTextMap);
    },
    async updatePostTagConstants() {
      // fetch and update post tags
      const response = await API.getTagsInUseApi(Constants.POST_TAG_TABLE);
      this.constantItemsMap["PostTag"] = this.getResults(response, Constants.TAG_TEXT);
      console.log("PostTag, ", this.constantItemsMap);
      for (let i = 0; i < response.result.length; i++) {
        const tag = response.result[i];
        this.tagIdToTextMap[tag.tag_id] = tag.tag_text;
      }
      //console.log("this.tagIdToTextMap, ", this.tagIdToTextMap);
    },
    async updateLinkTextConstants() {
      // fetch and update Post Name values
      const response = await API.getDistinctValuesApi(
        Constants.LINK_TABLE,
        Constants.LINK_TEXT
      );
      const results = this.getResults(response, Constants.LINK_TEXT);
      this.constantItemsMap["LinkText"] = results;
      console.log("LinkText, ", this.constantItemsMap);
    },
    async updateLinkTypeConstants() {
      this.constantItemsMap["LinkType"] = Constants.LINK_TYPES_FIXED;
      console.log("LinkType, ", this.constantItemsMap);
    },
    async updateTargetMap() {
      const response = await API.getTargetIdNamePairsApi();
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        this.targetMap[item.target_id] = {}
        this.targetMap[item.target_id].target_name = item.target_name;
        this.targetMap[item.target_id].target_description = item.target_description;
        this.targetMap[item.target_id].target_name_description = item.target_description.length == 0
          ? item.target_name
          : item.target_name + " (" + item.target_description + ")"
      }
      console.log("targetMap:", this.targetMap);
    },
    getSqlColumn() {
      if (this.selectedSearchField == "PostTitle") {
        return "post_title";
      }
      if (this.selectedSearchField == "PostBody") {
        return "post_body";
      }
      if (this.selectedSearchField == "LinkText") {
        return "link_text";
      }
      if (this.selectedSearchField == "LinkType") {
        return "link_type";
      }
      return "unknown_column"; // error
    },
    getSqlOperator() {
      const op = this.selectedOperator;
      if (op == "EqualTo" || op == "IsEqualTo") {
        return "=";
      }
      if (op == "Contains" || op == "StartsWith" || op == "EndsWith") {
        return "LIKE";
      }
      return "unknown_operator"; // error
    },
    getSqlSearchQuery() {
      this.searchQuery = this.searchQuery.trim();
      if (this.selectedOperator == "Contains") {
        return `%${this.searchQuery}%`;
      }
      if (this.selectedOperator == "StartsWith") {
        return `${this.searchQuery}%`;
      }
      if (this.selectedOperator == "EndsWith") {
        return `%${this.searchQuery}`;
      }
      return this.searchQuery;
    },
    async searchPosts() {
      const field = this.selectedSearchField;
      console.log("searchPosts(), field:", field);
      var response;
      if (field === "All Fields") {
        response = await API.searchPostsByAllTextColumnsApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "PostTitle"
        || field === "PostBody") {
        response = await API.searchPostsByColumnApi(
          Constants.POST_TABLE,
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "PostTarget*") {
        response = await API.searchPostsByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          ""
        );
      } else if (field === "PostTargetAny") {
        response = await API.searchPostsByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "any"
        );
      } else if (field === "PostTargetRequired") {
        response = await API.searchPostsByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "required"
        );
      } else if (field === "PostTargetUnallowed") {
        response = await API.searchPostsByTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "unallowed"
        );
      } else if (field === "PostKeyword") {
        response = await API.searchPostsByKeywordApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "PostTag") {
        response = await API.searchPostsByTagApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "LinkText") {
        response = await API.searchPostsByLinkTextApi(
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "LinkType") {
        response = await API.searchPostsByLinkTypeApi(
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      }
      console.log("\nresponse:", JSON.stringify(response));

      // clear posts dictionary; insert retrieved posts with key post_id
      this.posts = {};
      this.postIds = [];
      response.posts.forEach((post) => {
        if (post.post_id > 0) { // to allow selecting only certain/recent items (if needed)
          this.postIds.push(post.post_id);
          this.posts[post.post_id] = post;
        }
      });
      console.log("\nthis.posts:", this.posts);

      // clear questions dictionary; insert retrieved questions with key question_id
      this.links = {};
      response.links.forEach(link => {
        this.links[link.link_id] = link;
      });
      console.log("\nthis.links:", this.links);

     // clear post*Targets dictionaries; insert received post_target items with key post_id
      this.postAnyTargets = {};
      this.postRequiredTargets = {};
      this.postUnallowedTargets = {};
      response.postTargets.forEach(target => {
        target["target_name"] = this.targetMap[target.target_id].target_name;
        target["target_description"] = this.targetMap[target.target_id].target_description;
        target["target_name_description"] = this.targetMap[target.target_id].target_name_description
        if (target.target_presence_in_profile == Constants.ANY) {
          if (!(target.post_id in this.postAnyTargets)) {
            this.postAnyTargets[target.post_id] = [];
          }
          this.postAnyTargets[target.post_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.REQUIRED) {
          if (!(target.post_id in this.postRequiredTargets)) {
            this.postRequiredTargets[target.post_id] = [];
          }
          this.postRequiredTargets[target.post_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.UNALLOWED) {
          if (!(target.post_id in this.postUnallowedTargets)) {
            this.postUnallowedTargets[target.post_id] = [];
          }
          this.postUnallowedTargets[target.post_id].push(target);
        }
      });
      // console.log("\nthis.postAnyTargets:", this.postAnyTargets);
      // console.log("this.postRequiredTargets:", this.postRequiredTargets);
      // console.log("this.postUnallowedTargets:", this.postUnallowedTargets);

      // clear postTags and insert if they exist
      this.postTags = {}; // key:postId, value:[tag_text]
      response.tags.forEach(tag => {
        if (!(tag.post_id in this.postTags)) {
          this.postTags[tag.post_id] = [];
        }
        let tagText = this.tagIdToTextMap[tag.tag_id]
        this.postTags[tag.post_id].push(tagText);
      });
      console.log("\nthis.postTags:", this.postTags);

      // clear postKeywords and insert new ones (required)
      this.postKeywords = {}; // key:postId, value:[keywordText]
      response.keywords.forEach(keyword => {
        if (!(keyword.post_id in this.postKeywords)) {
          this.postKeywords[keyword.post_id] = [];
        }
        let keywordText = this.keywordIdToTextMap[keyword.keyword_id]
        this.postKeywords[keyword.post_id].push(keywordText);
      });
      console.log("\nthis.postKeywords:", this.postKeywords);

      // construct questions Data Table to be shown as rows in table
      this.postsDataTable = [];
      this.postIds.forEach((postId) => {
        var row = {};
        let post = this.posts[postId];
        row["postId"] = post.post_id;
        row["postTitle"] = post.post_title;
        row["aruTargets"] = (this.postAnyTargets[postId] || []).length.toString()
          + "/" + (this.postRequiredTargets[postId] || []).length.toString()
          + "/" + (this.postUnallowedTargets[postId] || []).length.toString();
        row["postKeywords"] = this.postKeywords[post.post_id].length;
        row["postImages"] = 0;
        row["postVideos"] = 0;
        row["postDocs"] = 0;
        row["postUrls"] = 0;
        post.post_separated_link_ids
          .split(",")
          .forEach(linkId => {
            if (linkId in this.links) {
              let link = this.links[linkId];
              if (link.link_type === Constants.LINK_TYPE_IMAGE) {
                row["postImages"] += 1;
              } else if (link.link_type === Constants.LINK_TYPE_VIDEO) {
                row["postVideos"] += 1;
              } else if (link.link_type === Constants.LINK_TYPE_DOCUMENT) {
                row["postDocs"] += 1;
              } else if (link.link_type === Constants.LINK_TYPE_URL) {
                row["postUrls"] += 1;
              }
            }
          });
        row["postPublished"] =
          post.post_published === "true" ? "Yes" : "No";
        this.postsDataTable.push(row);
      });
    },
    loadPost(postId) {
      this.post = {};
      this.post = { ...this.posts[postId] };
      this.post.post_publication_timestamp = Utils.getUtcSecondsEpoch();
      if (this.post.post_main_image_link_id) {
        this.post.post_main_image_link = {};
        this.post.post_main_image_link = { ...this.links[this.post.post_main_image_link_id] };
      } else {
        this.post.post_main_image_link = {
          link_type: Constants.LINK_TYPE_IMAGE,
          link_text: "",
          link_key: null,
          link_file: null
        }
      }
      this.post.links = [];
      if (this.post.post_separated_link_ids.length > 0) {
        let linkIds = this.post.post_separated_link_ids.split(",");
        for (let i = 0; i < linkIds.length; i++){
          this.post.links.push({ ...this.links[parseInt(linkIds[i])] });
        }
      }
      this.post.any_target_id = [];
      for (let target of this.postAnyTargets[postId] || []) {
        this.post.any_target_id.push(target.target_id);
      }
      this.post.required_target_id = [];
      for (let target of this.postRequiredTargets[postId] || []) {
        this.post.required_target_id.push(target.target_id);
      }
      this.post.unallowed_target_id = [];
      for (let target of this.postUnallowedTargets[postId] || []) {
        this.post.unallowed_target_id.push(target.target_id);
      }
      this.post.post_tag = [];
      if (postId in this.postTags) {
        this.post.post_tag = this.postTags[postId];
      }
      this.post.post_keyword = [];
      if (postId in this.postKeywords) {
        this.post.post_keyword = this.postKeywords[postId];
      }
      console.log("loadPost, this.post:", this.post);
    },
    getPostPreviewDetails(item) {
      console.log("getPostPreviewDetails, item:", item);
      this.loadPost(item.postId);
      this.showPreview = true;
    },
    async clonePost(post) {
      if (post == null || !("postId" in post)) {
        this.postsDataTable.forEach((item) => {
          if (item.postId === this.post.post_id) {
            post = item;
          }
        });
      }
      console.log("..clone \npost:", post);
      var confirmationText = "Clone this post and create a new one?";
      if (confirm(`${confirmationText}\n\nPost: '${post.postTitle}'`)) {
        this.$router.push({
          name: "AddPost",
          params: { post_id: `${post.postId}`, command: "clone" },
        });
      }
    },
    async editPost(post) {
      if (post == null || !("postId" in post)) {
        this.postsDataTable.forEach((item) => {
          if (item.postId === this.dialogPost.post_id) {
            post = item;
          }
        });
      }
      console.log("..edit \npost:", post);
      var confirmationText = "Edit this post?";
      if (confirm(`${confirmationText}\n\nPost: '${post.postTitle}'`)) {
        this.$router.push({
          name: "AddPost",
          params: { post_id: `${post.postId}`, command: "edit" },
        });
      }
    },
    async archievePost(post) {
      if (post == null || !("postId" in post)) {
        this.postsDataTable.forEach((item) => {
          if (item.postId === this.dialogPost.post_id) {
            post = item;
          }
        });
      }
      console.log("..archievePost \npost:", post);
      var confirmationText = "Archieve this post?";
      if (confirm(`${confirmationText}\n\nPost: '${post.postTitle}'`)) {
        if (post.postPublished === "Yes") {
          this.snackbarText = "Please unpublish the post first before archieving!";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          return;
        }
        const response = await API.archievePostApi(
          post.postId.toString(),
          post.postTitle
        );
        console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully archieved the post!";
          this.snackbarColor = this.snackbarColorSuccess;
          this.showPreview = false;
        } else {
          this.snackbarText = "Failed to archieve the post!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
      }
    },
    async publishPost(post) {
      if (post == null || !("postId" in post)) {
        this.postsDataTable.forEach((item) => {
          if (item.postId === this.dialogPost.post_id) {
            post = item;
          }
        });
      }
      console.log("..publishPost \npost:", post);
      var confirmationText = "Publish this post?";
      if (confirm(`${confirmationText}\n\nPost: '${post.postTitle}'`)) {
        await API.ensureAutoGeneratedTargetsCreatedApi(post.postId, post.postTitle);
        const response = await API.updateColumnValueApi(
          Constants.POST_TABLE,
          Constants.POST_PUBLISHED,
          "true",
          Constants.POST_ID,
          post.postId.toString()
        );
        console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully published the post!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText = "Failed to publish the post!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
      }
    },
    async unpublishPost(post) {
      if (post == null || !("postId" in post)) {
        this.postsDataTable.forEach((item) => {
          if (item.postId === this.dialogPost.post_id) {
            post = item;
          }
        });
      }
      console.log("..unpublishPost \npost:", post);
      var confirmationText = "Unpublish this post?";
      if (confirm(`${confirmationText}\n\nPost: '${post.postTitle}'`)) {
        const response = await API.updateColumnValueApi(
          Constants.POST_TABLE,
          Constants.POST_PUBLISHED,
          "false",
          Constants.POST_ID,
          post.postId.toString()
        );
        console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully unpublished the post!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText = "Failed to unpublish the post!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
      }
    },
    resetDataTable() {
      this.postsDataTable = null;
      this.selectedSearchField = "All Fields";
      this.selectedOperator = "Contains";
      this.searchQuery = " ";
      this.searchType = "text";
    },
  },
  watch: {
    selectedSearchField: function (newField, oldField) {
      this.searchQuery = "";
      this.postsDataTable = null;
      if (newField == "All Fields" || newField == "PostBody") {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      } else if (newField == "PostTitle"
        || newField == "PostKeyword"
        || newField == "PostTag"
        || newField.includes("PostTarget")
        || newField == "LinkText") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "searchableConstant";
        this.operators = this.searchableConstantOperators;
        this.selectedOperator = this.searchableConstantOperators[0];
      } else if (newField == "LinkType") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "constant";
        this.operators = this.constantOperators;
        this.selectedOperator = this.constantOperators[0];
      } else {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      }
    },
    selectedOperator: function (newOperator, oldOperator) {
      this.searchQuery = "";
      this.postsDataTable = null;
      console.log("selectedOperator update, this.searchType:", this.searchType);
      if (newOperator == "IsEqualTo") {
        this.constantLabel = this.selectedSearchField;
        this.constantItems =
          this.constantItemsMap[this.selectedSearchField] || [];
        if (
          this.selectedSearchField == "PostTitle" ||
          this.selectedSearchField.includes("PostTarget") ||
          this.selectedSearchField == "PostTag") {
          this.searchType = "searchableConstant";
          this.operators = this.searchableConstantOperators;
        } else if (this.selectedSearchField == "QuestionOrdering") {
          this.searchType = "constant";
          this.operators = this.constantOperators;
        }
      } else if (
        newOperator == "Contains" ||
        newOperator == "StartsWith" ||
        newOperator == "EndsWith"
      ) {
        this.searchQuery = " ";
        this.searchType = "text";
      }
    },
    searchQuery: function (newQuery, oldQuery) {
      if (this.postsDataTable != null) {
        this.postsDataTable = null;
      }
      if ((this.searchType == "constant" || this.searchType == "searchableConstant")
        && newQuery.trim().length > 0) {
        this.searchPosts();
      }
    },
  },
};
</script>
