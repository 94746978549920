import Vue from 'vue'
import VueRouter from 'vue-router'

import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

import { getUser, isUserAdmin, signOut } from '../utils/Auth.js'

import Home from '../views/Home.vue'
import SignIn from '../views/SignIn.vue'
import AddQuestion from '../views/AddQuestion.vue'
import SearchQuestions from '../views/SearchQuestions.vue'
import AddTarget from '../views/AddTarget.vue'
import SearchTargets from '../views/SearchTargets.vue'
import AddSurvey from '../views/AddSurvey.vue'
import SearchSurveys from '../views/SearchSurveys.vue'
import AddPost from '../views/AddPost.vue'
import SearchPosts from '../views/SearchPosts.vue'
import ReviewProviders from '../views/ReviewProviders.vue'
import ReviewProposals from '../views/ReviewProposals.vue'
import ManageDatabase from '../views/ManageDatabase.vue'
import RunUserContentTargeting from '../views/RunUserContentTargeting.vue'
import DataTableExample from '../views/DataTableExample.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/signIn', name: 'SignIn', component: SignIn },
  { path: '/addQuestion', name: 'AddQuestion', component: AddQuestion },
  { path: '/searchQuestions', name: 'SearchQuestions', component: SearchQuestions },
  { path: '/addTarget', name: 'AddTarget', component: AddTarget },
  { path: '/searchTargets', name: 'SearchTargets', component: SearchTargets },
  { path: '/addSurvey', name: 'AddSurvey', component: AddSurvey },
  { path: '/searchSurveys', name: 'SearchSurveys', component: SearchSurveys },
  { path: '/addPost', name: 'AddPost', component: AddPost },
  { path: '/searchPosts', name: 'SearchPosts', component: SearchPosts },
  { path: '/reviewProviders', name: 'ReviewProviders', component: ReviewProviders },
  { path: '/reviewProposals', name: 'ReviewProposals', component: ReviewProposals },
  { path: '/manageContentDb', name: 'ManageDatabase', component: ManageDatabase },
  { path: '/runUserContentTargeting', name: 'RunUserContentTargeting', component: RunUserContentTargeting },
  { path: '/dataTableExample', name: 'DataTableExample', component: DataTableExample }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

getUser().then(user => {
  if (!user) {
    router.push({ name: "SignIn" }, () => {});
  } else if (isUserAdmin(user)) {
    console.log("Router confirmed user is an admin");
    router.push({ name: 'Home' }, () => {});
  } else {
    signOut();
    alert("Please sign in to tibihealth.com");
    window.location.href = 'https://www.tibihealth.com';
  }
});

AmplifyEventBus.$on('authState', info => {
  if (info === 'signedIn') {
    getUser().then(user => {
      if (!user) {
        router.push({ name: "SignIn" }, () => {});
      } else if (isUserAdmin(user)) {
        console.log("Router confirmed user is an admin");
        router.push({ name: 'Home' }, () => {});
      } else {
        signOut();
        alert("Please sign in to tibihealth.com");
        window.location.href = 'https://www.tibihealth.com';
      }
    });
  } else {
    router.push({ name: 'SignIn' }, () => {})
  }
});

router.beforeEach((to, from, next) => {
  if (to.name === 'SignIn') {
    next()
  } else {
    getUser().then(user => {
      if (!user) {
        router.push({ name: "SignIn" }, () => {});
      } else if (isUserAdmin(user)) {
        // console.log("Router confirmed user is an admin, go to: " + to.name);
        next();
      } else {
        alert("Please sign in to tibihealth.com");
        signOut();
        window.location.href = 'https://www.tibihealth.com';
      }
    });
  }
})

export { router }
