<template>
  <v-container fluid class="ml-6 my-0 py-0" dense>
    <!--p>Predicate: {{value}}</p-->
    <v-row align="start" justify="start" class="my-0 py-0" dense>
      <v-col class="start" cols="1">
        <v-select
          v-model="value.predicate_type"
          :items="predicateTypes"
          :rules="predicateTypeRules"
          label="*Type"
          required
          dense
        ></v-select>
      </v-col>
      <v-col class="start" cols="6">
        <v-autocomplete
          v-model="value.predicate_key"
          :items="predicateKeyIdPairs"
          :rules="predicateKeyRules"
          :label="predicateKeyLabel"
          lazy-validation
          dense
        ></v-autocomplete>
      </v-col>
      <v-col class="start" cols="1">
        <v-select
          v-model="value.predicate_comparison_function"
          :items="comparisonFunctionAndNamePairs"
          :rules="predicateComparisonFunctionRules"
          label="*Comparison"
          lazy-validation
          dense
        ></v-select>
      </v-col>
      <v-col class="start" cols="2">
        <v-select v-if="predicateValueIdPairs.length > 0"
          v-model="value.predicate_value"
          :items="predicateValueIdPairs"
          :rules="predicateValueRules"
          :label="predicateValueLabel"
          :multiple="allowMultiplePredicateValues"
          lazy-validation
          dense
        ></v-select>
        <v-text-field v-if="predicateValueIdPairs.length == 0"
          v-model="value.predicate_value"
          :rules="numericalValueRules"
          :label="predicateValueLabel"
          lazy-validation
          dense
        ></v-text-field>
      </v-col>
       <v-col v-if="!value.first_predicate || !value.last_predicate" class="start" cols="1">
        <v-select v-if="!value.last_predicate"
          v-model="value.predicate_operation"
          :items="predicateOperationPairs"
          :rules="predicateOperationRules"
          label="Operation"
          required
          lazy-validation
          dense
        ></v-select>
      </v-col>
      <v-icon v-if="!value.first_predicate || !value.last_predicate"
        class="ml-2 mt-3"
        color="blue darken-2"
        dark
        dense
        @click="$emit('onRemovePredicate', value)"
        medium>mdi-delete
      </v-icon>
    </v-row>
    <v-btn
      v-if="value.last_predicate"
      color="blue darken-2"
      class="mx-1 px-1"
      height="30px"
      width="30px"
      fab
      dark
      @click="$emit('onAddAndPredicate')">
      +
    </v-btn>
    <v-btn
      v-if="value.last_predicate"
      color="blue darken-2"
      class="mx-1 px-1"
      height="30px"
      width="30px"
      fab
      dark
      @click="$emit('onAddOrPredicate')">
      /
    </v-btn>
    <div v-if="!value.last_predicate && value.predicate_operation === '/'" color="blue darken-2">
      <br>
      <strong>OR</strong>
      <br>
      <br>
    </div>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  data: () => ({
    predicateKeyRules: [
      (v) => !!v || "Required",
      (v) => (v && v.toString() != "-1") || "Required",
    ],
    predicateComparisonFunctionRules: [
      (v) => !!v || "Required",
      (v) => (v && v.length <= 31) || "Text cannot have more than 31 chars",
    ],
    predicateValueRules: [
      (v) => !!v || "Required",
      (v) => (v && v.toString().length <= 127) || "Text cannot have more than 127 chars",
    ],
    numericalValueRules: [
      (v) => !!v || "Required",
      (v) =>
        v == null ||
        v === "" ||
        /^[\d.]+$/.test(v) ||
        "Value must be numerical (float)",
    ],
    predicateTypeRules: [v => !!v || "Required"],
    predicateOperationRules: [v => !!v || "Required"],

    // Predicate can be of type Constants.PREDICATE_TYPES_FIXED = {Question, Target, Healthkit, Derived}
    //  1) Predicate: in the form of <predicateType, predicateKey, comparisonFunction, predicateValue>
    //  2) Target: or can simple point to another Target.
    predicateTypes: [],

    // for type="Question", text: "[SurveyName] questionText", value: questionId
    // for type="Target", text: "targetName (targetDescription)", value: targetId
    // for type="Healthkit", text: "healthPredicateName", value: healthPredicateId (e.g walkSteps)
    // for type="Derived", text: "derivedName (derivedDescription)", value: derivedId (e.g gestationWeeks)
    // points to questionTextIdPairs, targetNameIdPairs, healthNameIdPairs, derivedNameIdPairs
    predicateKeyIdPairs: [{ text: "", value: 0 }],

    // set the label for the profileKey based on the predicateType (setting the default for Question)
    predicateKeyLabel: "*Key: [Survey] Question",

    // below variable is used to populate comparison function field based on predicateType selected

    // text: userFacingComparisonName, value: comparisonFunction, e.g (text: EqualTo, value: StringEqualTo)
    comparisonFunctionAndNamePairs: [{ text: "", value: "" }],

    // below variables are used to populate predicateValue field, based on predicateType/Key selected

    // text: "answerText", value: answerId
    answerTextIdPairs: [{ text: "", value: 0 }],

    // text: "Value", value: {true}
    // note: only true is allowed since false could be misleading due to missing items. NotEqualTo True could
    // still be used which means target evaluates to true but this target population should be excluded
    targetValuePairs: [{ text: "True", value: "true" }],

    // text: "Value", value: <threshold>
    healthValueIdPairs: [{ text: "", value: 0 }],

    // text: "Value", value: <threshold>
    derivedBooleanValuePairs: [{ text: "True", value: "1.0" }, { text: "False", value: "0.0" }],

    // for type="Question", text: "answerText", value: answerId
    // for type="Target", text:{True}, value: {true} // only true is allowed (false could be misleading due to missing items)
    // for type="Healthkit", text: "Value", value: <threshold>
    // for type="Derived", text: "Value", value: <threshold> for numerical OR 0.0/1.0 for boolean
    // points to answerTextIdPairs, targetValuePairs, healthValueIdPairs, derivedValueIdPairs
    predicateValueIdPairs: [], // stores an array of dicts, [{ text: "", value: 0 }]

    // set the label for the profileValue based on the predicateType (setting the default for Question type)
    predicateValueLabel: "*Value: Answer",

    // allow multiple value selection or not (for Questions true, for Targets false)
    allowMultiplePredicateValues: true,

    // the operation in target expression which can be AND or OR
    // these operations are represented by + and / respectively in target_separated_predicate_ids
    predicateOperationPairs: [{ text: "AND", value: "+" }, { text: "OR", value: "/" }],
  }),
  created() {
    //console.log("Predicate created, this.value:", this.value);
    this.updatePredicateLabels();
    if (this.value.predicate_key > 0) {
      // editing or cloning an existing predicate (for an existing target)
      this.updatePredicateValues();
    }
    this.updatePredicateTypeConstants();
  },
  props: {
    value: {
      type: Object,
      required: true,
    },

    // pass in below variables to populate predicateKey field, based on predicateType selected
    // [{ text: "", value: 0 }] where text: "[SurveyName] questionText", value: questionId
    questionTextIdPairs: Array,

    // [{ text: "", value: 0 }] where text: targetName, value: targetId
    targetNameIdPairs: Array,

    // [{ text: "", value: 0 }] where text: healthPredicateName, value: healthPredicateId
    healthNameIdPairs: Array,

    // [{ text: "", value: 0 }] where text: derivedPredicateName, value: derivedPredicateId
    derivedNameIdPairs: Array,

    // pass in all active questions/answers
    // dictionary, key:question_id, value:question (fetched from db)
    questions: Object,

    // dictionary, key:answer_id, value:answer
    answers: Object,

    // dictionary, key:derived_id, value:derived
    deriveds: Object,
  },
  methods: {
    updatePredicateTypeConstants() {
      this.predicateTypes = Constants.PREDICATE_TYPES_FIXED;
    },
    updatePredicateLabels() {
      if (this.value.predicate_type === "Question") {
        // populate predicateKeys for Questions and set comparisonFunction
        this.predicateKeyIdPairs = this.questionTextIdPairs;
        this.predicateKeyLabel = "*Key: [Survey] Question";
        this.predicateValueLabel = "*Value: Answer";
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_QUESTION_COMPARISON_PAIRS;
        this.allowMultiplePredicateValues = true;
      } else if (this.value.predicate_type === "Target") {
        // populate predicateKeys for Targets and set comparisonFunction
        this.predicateKeyIdPairs = this.targetNameIdPairs;
        this.predicateKeyLabel = "*Key: Target";
        this.predicateValueLabel = "*Value";
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_TARGET_COMPARISON_PAIRS;
        this.allowMultiplePredicateValues = false;
      } else if (this.value.predicate_type === "Healthkit") {
        // TODO: update this later, this is a placeholder
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_NUMERICAL_COMPARISON_PAIRS;
      } else if (this.value.predicate_type === "Derived") {
        // assume the values will be numerical for now for the comparison function
        // when specific derived entity is selected, it will be updated based on the derived_value_type
        this.predicateKeyIdPairs = this.derivedNameIdPairs;
        this.predicateKeyLabel = "*Key: Derived";
        this.predicateValueLabel = "*Value: (Numerical)";
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_NUMERICAL_COMPARISON_PAIRS;
      } else {
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_NUMERICAL_COMPARISON_PAIRS;
      }
    },
    updatePredicateValues() {
      //console.log(".. 1 updatePredicateValues, this.value:",  this.value);
      if (this.value.predicate_type === "Question" && this.value.predicate_key > 0) {
        // specific question is selected, populate PredicateValue(answers) fields
        let questionId = this.value.predicate_key;
        let question = this.questions[questionId] || {};
        let separatedAnswerIds = question.question_separated_answer_ids || "";
        let answerIds = separatedAnswerIds.replace(/-/g, ",").split(",");
        var result = [];
        answerIds.forEach(answerId => {
          let answer = this.answers[answerId];
          let pair = {
            text: answer.answer_text,
            value: answer.answer_id
          };
          result.push(pair);
        });
        this.answerTextIdPairs = result;
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_QUESTION_COMPARISON_PAIRS;
        if (this.value.predicate_comparison_function === undefined) {
          // empty predicate
          this.value.predicate_comparison_function = this.comparisonFunctionAndNamePairs[0].value;
        }
        this.predicateValueIdPairs = this.answerTextIdPairs;
        if (this.value.predicate_value.length > 0) {
          // this is edit/clone of an existing predicate, convert the id(s) to int
          let predicateValues = [];
          this.value.predicate_value.split(",").forEach(answerIdString => {
            // there are multiple answers selected
            predicateValues.push(parseInt(answerIdString));
          });
          this.value.predicate_value = predicateValues;
        }
        //console.log(".. 2 updatePredicateValues, value:",  this.value);
      } else if (this.value.predicate_type === "Target" && this.value.predicate_key > 0) {
        // specific target is selected, populate PredicateValue field which is {True, False}
        this.predicateValueIdPairs = this.targetValuePairs;
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_TARGET_COMPARISON_PAIRS;
        if (this.value.predicate_comparison_function === undefined) {
          // empty predicate
          this.value.predicate_comparison_function = this.comparisonFunctionAndNamePairs[0].value;
        }
        if (this.value.predicate_value.length === 0) {
          // empty predicate
          this.value.predicate_value = this.predicateValueIdPairs[0].value;
        }
      } else if (this.value.predicate_type === "Healthkit") {
        // TODO: update this later, this is a placeholder
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_NUMERICAL_COMPARISON_PAIRS;
      } else if (this.value.predicate_type === "Derived" && this.value.predicate_key > 0) {
        let derivedId = this.value.predicate_key;
        let derived = this.deriveds[derivedId];
        let derivedValueType = derived.derived_value_type.toLowerCase();
        if (derivedValueType.startsWith("bool")) {
          this.predicateValueIdPairs = this.derivedBooleanValuePairs;
          this.predicateValueLabel = "*Value: (Boolean)";
          this.comparisonFunctionAndNamePairs = Constants.PREDICATE_BOOLEAN_COMPARISON_PAIRS;
          if (this.value.predicate_value.length === 0) {
            // empty predicate
            this.value.predicate_value = this.predicateValueIdPairs[0].value;
          }
          this.allowMultiplePredicateValues = false;
        } else {
          // number/numeric/float
          this.predicateValueIdPairs = [];
          this.predicateValueLabel = "*Value: (Numerical)";
          this.comparisonFunctionAndNamePairs = Constants.PREDICATE_NUMERICAL_COMPARISON_PAIRS;
        }
        if (this.value.predicate_comparison_function === undefined) {
          // empty predicate
          this.value.predicate_comparison_function = this.comparisonFunctionAndNamePairs[0].value;
        }
      } else {
        this.comparisonFunctionAndNamePairs = Constants.PREDICATE_STRING_COMPARISON_PAIRS;
      }
    },
  },
  watch: {
    'value.predicate_type': function (newField, oldField) {
      this.value.predicate_key = -1;
      this.value.predicate_comparison_function = undefined;
      this.value.predicate_value = "";
      this.predicateValueIdPairs = [];
      this.updatePredicateLabels();
    },
    'value.predicate_key': function (newField, oldField) {
      //console.log(".. watch predicate_key, value.predicate_key:",  this.value.predicate_key);
      this.value.predicate_value = "";
      this.predicateValueIdPairs = [];
      this.updatePredicateValues();
    },
    'value.predicate_value': function (newField, oldField) {
      if (Array.isArray(newField) && newField.length === 0) {
        // modify empty array to empty text so vuetify validation can catch this is empty
        this.value.predicate_value = "";
      }
    }
  }
};
</script>

<style>
.material-icons { vertical-align: bottom }
</style>
