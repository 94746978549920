<template>
  <div id="app">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <v-img :src="require('../assets/tibi_logo.png')" class="my-3" contain height="100" />
          <br />
          <h1>
            <strong>Welcome to Tibi Survey Portal!</strong>
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import { router } from "router/index";

export default {
  name: "app",
  methods: {
    sendToLambda: async function() {
      console.log("send to lambda called");
      let apiName = "surveydbapi";
      let path = "/run";
      let myInit = {
        queryStringParameters: {
          sqlQuery: "select * from questions"
        }
      };
      return await API.get(apiName, path, myInit);
    },
    gotoAddQuestion: function() {}
  }
};
</script>
