import * as Constants from "../utils/Constants.js";

/**
 * Checks if input is a numeric value, if so returns true
 */
function isNumeric(input){
  return !isNaN(input)
}

/**
 * Goes over the answers of the input questionId
 * and return "string", "number" or "boolean" to indicate the their data type.
 * For "number" and "boolean", all profileValue must conform.
 */
export function getAnswerDataType(questionId, questions, answers) {
  if (!(questionId in questions)) {
    return "string";
  }
  let question = questions[questionId];
  let separatedAnswerIds = question.question_separated_answer_ids;
  let answerIds = separatedAnswerIds.replace(/-/g, ",").split(",");
  var allNumbers = true;
  answerIds.forEach(answerId => {
    let answer = answers[answerId];
    let answerText = answer[Constants.ANSWER_TEXT];
    if (!isNumeric(answerText)) {
      allNumbers = false;
    }
  });
  if (allNumbers) {
    return "number";
  }
  return "string";
}

/**
 * Unoptimized implementation to extract text that appear between given prefix and suffix pairs.
 * https://stackoverflow.com/questions/14867835/get-substring-between-two-characters-using-javascript
 */
export var getFromBetween = {
  results:[],
  string:"",
  getFromBetween:function (sub1,sub2) {
    if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
    var SP = this.string.indexOf(sub1)+sub1.length;
    var string1 = this.string.substr(0,SP);
    var string2 = this.string.substr(SP);
    var TP = string1.length + string2.indexOf(sub2);
    return this.string.substring(SP,TP);
  },
  removeFromBetween:function (sub1,sub2) {
    if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
    var removal = sub1+this.getFromBetween(sub1,sub2)+sub2;
    this.string = this.string.replace(removal,"");
  },
  getAllResults:function (sub1,sub2) {
    // first check to see if we do have both substrings
    if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;

    // find one result
    var result = this.getFromBetween(sub1,sub2);
    // push it to the results array
    this.results.push(result);
    // remove the most recently found one from the string
    this.removeFromBetween(sub1,sub2);

    // if there's more substrings
    if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
        this.getAllResults(sub1,sub2);
    }
    else return;
  },
  get:function (string,sub1,sub2) {
    this.results = [];
    this.string = string;
    this.getAllResults(sub1,sub2);
    return this.results;
  }
};

/**
 * Returns true if input string is a valid url with https protocol.
 */
 export function isValidUrl(string) {
  // external links (urls) must be http(s), otherwise, app will crash with:
  // "The specified URL has an unsupported scheme. Only HTTP and HTTPS URLs are supported"
  // for extra privacy we require protocol to be https
  var pattern = new RegExp('^(https:\\/\\/)?'+ // protocol (must be https)
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(string);
}

/**
 * Returns the utc epoch seconds.
 */
export function getUtcSecondsEpoch() {
  const now = new Date();
  const utcMilllisecondsEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
  const utcSecondsEpoch = Math.round(utcMilllisecondsEpoch / 1000);
  return utcSecondsEpoch;
}

/**
 * Returns the inputDate (utc epoch )in pacific timezone with a human readable format
 * e.g Monday, December 28, 2020
 */
export function getFormattedDate(utcSeconds) {
  var date = new Date(utcSeconds * 1000)
  return date.toLocaleDateString("en-US",
    { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}

/**
 * Returns the input str in #HashTag format.
 */
export function toHashTag(str) {
  str = str.replace(/[\W_]+/g, ' ') // replace nonalphanum chars with single space
      .replace(/_/g, ' ')           // replace _ with single space (since it is considered alphanum)
      .replace(/\s\s+/g, ' ')       // replace multi spaces with single space
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join('');
  return str.charAt(0) == '#' ? str : "#" + str
}

/**
 * Capitalizes the first letter of the input string.
 */
export function capitalizeWord(s) {
  if (typeof s !== 'string') return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Iterates over objects and keeps track of unique values seen in object.fieldName and returns them sorted.
 */
export function getSortedUniqueValues(objects, fieldName) {
  if (objects && objects.length > 0) {
      let set = new Set();
      for (let object of objects) {
          set.add(object[fieldName])
      }
      return Array.from(set).sort();
  }
  return [];
}

/**
 * Iterates over object's fields and returns true if any of the values seen match against
 * input query as (case-insensitive) text based on the operator specifed.
 */
export function objectHasTextMatch(object, operator, query) {
  for (const [key, value] of Object.entries(object)) {
    let valueString = String(value).toLowerCase().trim();
    if (operator == "Contains" && valueString.includes(query)) {
      return true;
    }
    if (operator == "IsEqualTo" && valueString == query) {
      return true;
    }
    if (operator == "StartsWith" && valueString.startsWith(query)) {
      return true;
    }
    if (operator == "EndsWith" && valueString.endsWith(query)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if input (number) value matches query based on operator specified.
 */
 export function valueNumberMatch(value, operator, query) {
    if (operator == "EqualTo" || operator == "IsEqualTo") {
      return value == query;
    }
    if (operator == "LessThan") {
      return value < query;
    }
    if (operator == "LessThanOrEqualTo") {
      return value <= query;
    }
    if (operator == "GreaterThan") {
      return value > query;
    }
    if (operator == "GreaterThanOrEqualTo") {
      return value >= query;
    }
    return false;

}
