<template>
<v-container fluid class="mx-0 mb-3 py-3" dense>
  <!--p>value: {{value}}</p-->
  <v-card class="mx-0 my-auto" elevation="4" :max-width="maxWidth">
    <v-card-title class="headline black--text">{{value.post_title}}</v-card-title>
    <v-card-subtitle>
        <div v-for="(keyword, i) in value.post_keyword" :key="i">
          <div style="float:left;margin:2px" v-if="i % 5 == 0" class="blue--text">{{keyword}}</div>
          <div style="float:left;margin:2px" v-if="i % 5 == 1" class="red--text">{{keyword}}</div>
          <div style="float:left;margin:2px" v-if="i % 5 == 2" class="green--text">{{keyword}}</div>
          <div style="float:left;margin:2px" v-if="i % 5 == 3" class="brown--text">{{keyword}}</div>
          <div style="float:left;margin:2px" v-if="i % 5 == 4" class="orange--text">{{keyword}}</div>
        </div>
    </v-card-subtitle>
    <v-card-text class="font-weight-regular black--text">
      <div v-html="mainImage"/>
      <div v-html="postBody"/>
    </v-card-text>
  </v-card>
  <div v-if="isTargetMapLoaded" class="mx-4 my-1">
    <small>
      <strong>PostID: </strong>{{value.post_id}}<br>
      <template v-if="value.any_target_id.length > 0">
        <strong>Targets-Any:</strong>
        <ul>
          <li v-for="(target_id, i) in value.any_target_id" :key="i">
            <template>{{ targetMap[target_id].target_name_description }}</template>
          </li>
        </ul>
      </template>
      <template v-if="value.required_target_id.length > 0">
        <strong>Targets-Required:</strong>
        <ul>
          <li v-for="(target_id, i) in value.required_target_id" :key="i">
            <template>{{ targetMap[target_id].target_name_description }}</template>
          </li>
        </ul>
      </template>
      <template v-if="value.unallowed_target_id.length > 0">
        <strong>Targets-Unallowed:</strong>
        <ul>
          <li v-for="(target_id, i) in value.unallowed_target_id" :key="i">
            <template>{{ targetMap[target_id].target_name_description }}</template>
          </li>
        </ul>
      </template>
    </small>
  </div>
  <div v-if="value.post_tag.length > 0" class="mx-4">
    <small>
      <strong>Tags:</strong>
      <ul>
        <li v-for="(tag, i) in value.post_tag" :key="i">
          <template>{{ tag }}</template>
        </li>
      </ul>
    </small>
  </div>
  <v-btn color="blue darken-2" dark dense class="my-3" @click="$emit('closePreview')">Close</v-btn>
</v-container>
</template>

<script>
import { Storage } from "aws-amplify";
import * as API from "../utils/Api.js";
import * as Utils from "../utils/Utils.js";
import * as Constants from "../utils/Constants.js";

export default {
  data: () => ({
    maxWidth: 400,
    mainImage: "",
    postBody: "",
    targetMap: {}, // key:targetId, value:target (dict with target_name and target_description)
    isTargetMapLoaded: false,
  }),
  created() {
    this.updatePostBody();
    this.updateMainImage();
    this.updateTargetMap();
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    showPreview: Boolean
  },
  methods: {
    async getLinkHtmlTag(link) {
      if (link.link_type.toLowerCase() === "image") {
        let s3Link = await Storage.get(link.link_key, {
          level: 'public',
          expires: Constants.S3_LINK_EXPIRATION_SECS
        });
        return `<img src="${s3Link}" style="width:${this.linkMaxWidth}px">
          <figcaption class="font-weight-light" style="font-family:'Roboto'">${link.link_text}</figcaption>
          <br>`;
      }
      if (link.link_type.toLowerCase() === "video") {
        let s3Link = await Storage.get(link.link_key, {
          level: 'public',
          expires: Constants.S3_LINK_EXPIRATION_SECS
        });
        let type = "application/x-mpegURL"; // for .m3u8
        if (link.link_key.endsWith(".mp4")) {
          type = "video/mp4";
        } else if (link.link_key.endsWith(".mov")) {
          type = "video/quicktime";
        }
        return `<video width="${this.linkMaxWidth}px" controls>
            <source src="${s3Link}" type="${type}">
          </video>
          <figcaption class="font-weight-light" style="font-family:'Roboto'">${link.link_text}</figcaption>
          <br>`;
      }
      if (link.link_type.toLowerCase() === "document") {
        let s3Link = await Storage.get(link.link_key, {
          level: 'public',
          expires: Constants.S3_LINK_EXPIRATION_SECS
        });
        return `<a href="${s3Link}">${link.link_text}</a>`;
      }
      if (link.link_type.toLowerCase() === "url") {
        let url = link.link_url;
        if (url != null && !url.startsWith("http")) {
          url = "https://" + url;
        }
        return `<a href="${url}">${link.link_text}</a>`;
      }
    },
    async updatePostBody() {
      // console.log("PostPreview, updatePostBody post:", this.value);
      let postBody = this.value.post_body;
      for (let i = 0; i < this.value.links.length; i++) {
        let link = this.value.links[i];
        let linkRef = "link_ref" in link
          ? Constants.LINK_PREFIX + link.link_ref + Constants.LINK_SUFFIX
          : Constants.LINK_PREFIX + link.link_id + Constants.LINK_SUFFIX;
        let htmlTag = await this.getLinkHtmlTag(link);
        console.log("link:", link, " htmlTag:", htmlTag);
        postBody = postBody.replaceAll(linkRef, htmlTag);
      }
      console.log("org this.value.post_body:", this.value.post_body, "\nnew postBody:", postBody);
      this.postBody = postBody;
    },
    async updateMainImage() {
      console.log("PostPreview, updateMainImage post:", this.value);
      const s3Link = await Storage.get(this.value.post_main_image_link.link_key, {
        level: 'public',
        expires: Constants.S3_LINK_EXPIRATION_SECS
      });
      console.log("key:", this.value.post_main_image_link.link_key, " s3Link:", s3Link, " caption:", this.value.post_main_image_link.link_text);
      this.mainImage = `<img src="${s3Link}" style="width:${this.linkMaxWidth}px">
        <figcaption class="font-weight-light" style="font-family:'Roboto'">${this.value.post_main_image_link.link_text}</figcaption>
        <br>`;
    },
    async updateTargetMap() {
      const response = await API.getTargetIdNamePairsApi();
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        this.targetMap[item.target_id] = {}
        this.targetMap[item.target_id].target_name = item.target_name;
        this.targetMap[item.target_id].target_description = item.target_description;
        this.targetMap[item.target_id].target_name_description = item.target_description.length == 0
          ? item.target_name
          : item.target_name + " (" + item.target_description + ")"
      }
      // console.log("PostPreview, targetMap:", this.targetMap);
      this.isTargetMapLoaded = true;
    },
  },
  computed: {
    linkMaxWidth() {
      return this.maxWidth-32;
    },
    hasMainImage() {
      return (this.value.post_main_image_link != null
        && this.value.post_main_image_link.link_key != null
        && this.value.post_main_image_link.link_key.length > 0);
    },
    publicationDate() {
      console.log("this.value.post_publication_timestamp:", this.value.post_publication_timestamp);
      console.log("Utils.getFormattedDate:", Utils.getFormattedDate(this.value.post_publication_timestamp));
      return Utils.getFormattedDate(this.value.post_publication_timestamp);
    }
  },
  watch: {
    'showPreview': function (newValue, oldValue) {
      if (newValue === true) {
        // console.log("1 Refreshing, post main image and post body");
        this.updatePostBody();
        this.updateMainImage();
      }
    },
    'value.post_id': function (newQuery, oldQuery) {
      // console.log("2 Refreshing, post main image and post body");
      this.updatePostBody();
      this.updateMainImage();
      },
  }
};
</script>

<style>
.material-icons { vertical-align: bottom }
</style>
