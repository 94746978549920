<template>
  <v-container fluid class="ml-2 mt-0 pt-0 mb-10 pb-0" dense>
    <!--p>CHILD question_answer_ordering: {{question_answer_ordering}}</p-->
    <div v-if="value.first_answer" class="my-0 py-0">
        <v-row align="center" class="my-0 py-0" dense>
        <v-col cols="auto" dense justify="start" class="my-0 py-0 mr-2">
          <v-btn
            color="success"
            height="25px"
            width="25px"
            fab
            dark
            @click="$emit('onAddEmptyAnswer')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            color="error"
            class="mx-1 px-1"
            height="25px"
            width="25px"
            fab
            dark
            @click="$emit('onRemoveEmptyAnswer')"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
        <v-col class="start" justify="start" cols="3" dense>
          <v-radio-group
            label="Answers:"
            :value="question_answer_ordering"
            @change="$emit('onUpdateAnswerOrdering', $event)"
            row
            dense
            height="1px"
          >
            <v-radio value="ordered" label="Ordered" />
            <v-radio value="shuffle" label="Shuffle" />
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <v-row align="center" class="my-0 py-0" dense>
      <v-col class="end" cols="9">
        <v-text-field
          :value="value.answer_text"
          :rules="answerTextRules"
          :counter="511"
          :label="answerTextLabel"
          required
          dense
          height="20px"
          @change="updateAnswer('answer_text', $event)"
        >{{ value.answer_text }}</v-text-field>
      </v-col>
      <v-col class="end" cols="1">
        <v-text-field
          :value="value.answer_score"
          :rules="answerScoreRules"
          label="Score"
          dense
          height="20px"
          @change="updateAnswer('answer_score', $event)"
        >{{ value.answer_score }}</v-text-field>
      </v-col>
      <v-col cols="1" class="my-0 py-0">
        <v-checkbox
          v-if="question_answer_ordering === 'ordered'"
          v-model="value.answer_subcheck"
          label="subshuffle"
          dense
          height="5px"
          @change="updateAnswer('answer_subcheck', $event)"
        ></v-checkbox>
        <v-checkbox
          v-if="question_answer_ordering === 'shuffle'"
          v-model="value.answer_subcheck"
          label="suborder"
          dense
          height="5px"
          @change="updateAnswer('answer_subcheck', $event)"
        ></v-checkbox>
      </v-col>
      <v-icon v-if="!value.first_answer || !value.last_answer"
        class="ml-2 mt-3"
        color="blue darken-2"
        dark
        dense
        @click="$emit('onRemoveAnswer', value)"
        medium>mdi-delete
      </v-icon>
    </v-row>
    <v-row align="center" class="mt-0 pt-0 mb-6 pb-0" dense>
      <v-col align-self="auto" justify="start" class="mt-0 pt-0 mb-6 pb-0" dense>
        <Target
          v-model.trim="value"
          :targetLabel="targetLabel"
          :targetIdNamePairs="targetIdNamePairs"
        />
      </v-col>
      <v-col align-self="auto" justify="start" class="mt-0 pt-0 mb-6 pb-0" dense>
        <LocalTarget
          v-model.trim="value.localtarget[0]"
          :questions="questions"
          :questionIds="questionIds"
          :answers="answers"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Target from "../components/Target";
import LocalTarget from "../components/LocalTarget";

export default {
  components: {
    Target,
    LocalTarget
  },
  data: () => ({
    name: "addAnswer",
    answerTextRules: [
      v => !!v || "Required",
      v => (v && v.length <= 511) || "Text cannot have more than 511 chars"
    ],
    answerScoreRules: [
      v =>
        v == null || v === "" || /^[\d.]+$/.test(v) || "Score must be a float"
    ],
  }),
  props: {
    value: {
      type: Object,
      required: true
    },
    question_answer_ordering: String,
    targetIdNamePairs: Array,
    questions: Object,
    questionIds: Array,
    answers: Object,
    answerIndex: Number
  },
  computed: {
    targetIdNamePairItems() {
      return this.targetIdNamePairs;
    },
    answerTextLabel() {
      return `[${this.answerIndex + 1}] Answer:`;
    },
    targetLabel() {
      return `[${this.answerIndex + 1}] Answer Targets:`;
    },
  },
  watch: {
  },
  methods: {
    updateAnswer(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    }
  }
};
</script>
