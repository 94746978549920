import { Auth } from 'aws-amplify'
import * as Constants from "../utils/Constants.js";

export async function getUser() {
  try {
    const user = await Auth.currentAuthenticatedUser()
    if (user && user.signInUserSession) {
      return user
    } else {
      return null
    }
  } catch (err) {
    console.log(err)
    return null
  }
}

export function isUserAdmin(user) {
  if (!user) {
    return false;
  }
  const cognitoGroups = user.signInUserSession?.idToken?.payload?.["cognito:groups"];
  if (cognitoGroups && cognitoGroups.includes(Constants.ADMIN)) {
    return true;
  } else {
    return false;
  }
}

export async function signin({ email, password }) {
  const curUser = await Auth.signIn(email, password)
  return curUser
}

export async function completeNewPassword({ cognitoUser, newPassword, phone_number }) {
  try {
    await Auth.completeNewPassword(cognitoUser, newPassword, {phone_number});
    console.log("auth.js completeNewPassword done, curUser is NOT set yet");
  } catch (error) {
    console.log("auth.js completeNewPassword, error:", error);
    return error;
  }
}

export async function confirmSignin({ user, code, mfaType }) {
  try {
    console.log("auth.js confirmSignin, user:", user, " code:", code, " mfaType:", mfaType);
    await Auth.confirmSignIn(user, code, mfaType);
    const curUser = await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log("auth.js confirmSignin, error:", error);
    return error;
  }
}

export async function changePassword({ oldPassword, newPassword }) {
  try {
    const curUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(curUser, oldPassword, newPassword);
    return null; // no error
  } catch (error) {
    console.log("auth.js changePassword, error:", error);
    return error;
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (e) {
    console.error("Amplify signout failed, e: ", e);
  }
}