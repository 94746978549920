<template>
  <v-container fluid>
    <template v-if="!showProgressCircular">
      <v-row align="start">
        <b class="mr-1 pr-0">dbName:</b>{{dbName}}
      </v-row>
      <v-row align="start">
        <b class="mr-1 pr-0">dbStatus:</b>{{dbStatus}}
      </v-row>
      <v-row align="start">
        <v-btn
          v-if="dbStatus == DB_AVAILABLE"
          color="blue darken-2"
          @click="stopDbInstance"
          class="mt-2 pt-0"
          dark
          dense
        >Stop</v-btn>
        <v-btn
          v-if="dbStatus == DB_STOPPED"
          color="blue darken-2"
          @click="startDbInstance"
          class="mt-2 pt-0"
          dark
          dense
        >Start</v-btn>
        <v-btn
          v-if="dbStatus != DB_STOPPED && dbStatus != DB_AVAILABLE"
          color="blue darken-2"
          @click="refreshDbInstance"
          class="mt-2 pt-0"
          dark
          dense
        >Refresh</v-btn>
      </v-row>
    </template>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarText }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div class="text-center" v-if="showProgressCircular">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  name: "manageDatabase",
  data() {
    return {
      dbName: "",
      dbStatus: "",
      dBInstanceIdentifier: {},

      // spinner to show while waiting for API calls
      showProgressCircular: true,

      // https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/accessing-monitoring.html#Overview.DBInstance.Status
      DB_STOPPED: "stopped",
      DB_AVAILABLE: "available",

      // snackbar to show error message
      snackbarText: "",
      snackbarFlag: false,
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,
    };
  },
  async created() {
    console.time("manageDatabase");
    await this.getDbInstance();
    console.timeEnd("manageDatabase");
  },
  computed: {
  },
  methods: {
    async getDbInstance() {
      this.showProgressCircular = true;
      let response = await API.getDbInstance();
      //console.log(`getDbInstance, response:${JSON.stringify(response)}`);
      this.showProgressCircular = false;
      if (response.success != 1) {
        this.snackbarText = "Failed to connect to the database";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return;
      }
      this.dBInstance = response.result || {};
      this.dbName = this.dBInstance.DBInstanceIdentifier || "";
      this.dbStatus = this.dBInstance.DBInstanceStatus || "";
    },
    async stopDbInstance() {
      if (confirm(`Stop ${this.dbName} now?\n\nNote: It takes about 5 minutes to stop`)) {
        this.showProgressCircular = true;
        let response = await API.stopDbInstance();
        //console.log(`stopDbInstance, response:${JSON.stringify(response)}`);
        await this.getDbInstance();
      }
    },
    async startDbInstance() {
      if (confirm(`Start ${this.dbName} now?\n\nNote: It takes about 5 minutes to start and costs $0.50/day`)) {
        this.showProgressCircular = true;
        let response = await API.startDbInstance();
        //console.log(`startDbInstance, response:${JSON.stringify(response)}`);
        await this.getDbInstance();
      }
    },
    async refreshDbInstance() {
      await this.getDbInstance();
    },
  },
}
</script>