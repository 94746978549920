<template>
  <!--p>timeWindowSeconds: {{timeWindowSeconds}}</p>
  <p>secondsInTimeUnitSelected: {{secondsInTimeUnitSelected}}</p>
  <p>countOfTimeUnits: {{countOfTimeUnits}}</p-->
  <v-row align="center" justify="start" class="mx-0 px-0 my-0 py-0" dense>
    <v-col class="start" cols="2" dense>
      <div style="color:#616161">{{timeWindowLabel}}</div>
    </v-col>
    <v-col class="start" cols="2" dense>
      <v-select
        :items="timeUnitInSeconds"
        v-model="secondsInTimeUnitSelected"
        label="Time Unit"
        dense
        height="20px"
        @change="updateTimeWindowSeconds()"
      ></v-select>
    </v-col>
     <v-col class="start" cols="2" dense>
       <v-text-field
         v-model="countOfTimeUnits"
         :rules="countOfTimeUnitsRules"
         label="Value"
         required
         dense
         height="20px"
        @change="updateTimeWindowSeconds()"
       ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    name: "timeWindow",
    countOfTimeUnitsRules: [
      (v) => !!v || "Required",
      (v) =>
        v == null ||
        v === "" ||
        /^[\d]+$/.test(v) || "Value must be an (integer) number"
    ],
    timeUnitInSeconds: [
      { text: "Days", value: 86400 },
      { text: "Hours", value: 3600 },
      { text: "Minutes", value: 60 },
      { text: "Seconds", value: 1 }
    ],
    secondsInTimeUnitSelected: 86400, // default unit is Days
    countOfTimeUnits: 0,
  }),
  props: {
    updateKey: String,
    timeWindowLabel: String,
    timeWindowSeconds: Number
  },
  created() {
    this.countOfTimeUnits = Math.floor(this.timeWindowSeconds / this.secondsInTimeUnitSelected);
    // console.log("created, this.timeWindowSeconds:", this.timeWindowSeconds);
    // console.log("created, this.secondsInTimeUnitSelected:", this.secondsInTimeUnitSelected);
    // console.log("created, this.countOfTimeUnits:", this.countOfTimeUnits);
  },
  methods: {
    updateTimeWindowSeconds() {
      var update = {};
      update["updateKey"] = this.updateKey;
      update["timeWindowSeconds"] = this.secondsInTimeUnitSelected * this.countOfTimeUnits;
      this.$emit('onUpdateTimeWindowSeconds', update);
    }
  }
};
</script>
