<template>
  <v-app id="inspire">
    <!--p>PARENT post: {{post}}</p-->
    <v-form ref="form" lazy-validation dense>
      <v-text-field
        v-if="originalPostIdEdit != null && command === 'edit'"
        v-model="originalPostIdEdit"
        label="Post ID"
        dense
        disabled
      ></v-text-field>
      <v-text-field
        v-model.trim="post.post_title"
        :counter="127"
        :rules="postTitleRules"
        label="*Post Title"
        lazy-validation
        dense
      ></v-text-field>
      <v-combobox
        v-model="post.post_keyword"
        :items="keywords"
        chips
        dense
        clearable
        :rules="keywordRules"
        lazy-validation
        label="*Keywords"
        multiple
        height="25px">
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              color="success"
              text-color="white"
              small
              :input-value="selected"
              close
              @click="select"
              @click:close="removeKeyword(item)"
            >{{ item }}</v-chip>
        </template>
      </v-combobox>
      <Link
        v-model="post.post_main_image_link"
        is-main-link
        lazy-validation
        @onEmptyMainLink="emptyMainLink"
        dense
      />
      <v-textarea
        class="mt-2 pt-0"
        v-model.trim="post.post_body"
        rows="5"
        :counter="10239"
        :rules="postBodyRules"
        label="*Post Body (Html)"
        lazy-validation
        filled
        dense
      ></v-textarea>
      <div v-for="(link, i) in this.post.links" :key="post.links.length+i">
        <Link
          v-model.trim="post.links[i]"
          @onAddLink="addLink"
          @onRemoveLink="removeLink"
          lazy-validation
        />
      </div>
      <Target
        v-model.trim="post"
        targetLabel="*Targets:"
        :targetIdNamePairs="targetIdNamePairs" />
      <v-combobox
        v-model="post.post_tag"
        :items="tags"
        chips
        dense
        clearable
        label="Tags"
        multiple
        height="25px">
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              color="success"
              text-color="white"
              small
              :input-value="selected"
              close
              @click="select"
              @click:close="removeTag(item)"
            >{{ item }}</v-chip>
        </template>
      </v-combobox>
      <br />
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="validateAndPreview">Validate &#38; Preview</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="resetValidation">Reset Validation</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="save">Save</v-btn>
      <v-btn color="blue darken-2" dark dense class="mr-3" @click="reset">Reset Form</v-btn>
    </v-form>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarMessage }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
    <br />
    <PostPreview
      v-if="showPreview"
      show-preview
      v-model.trim="post"
      @closePreview="showPreview=false"
      lazy-validation
    />
  </v-app>
</template>

<script>
import { Storage } from "aws-amplify";
import Link from "../components/Link";
import PostPreview from "../components/PostPreview";
import Target from "../components/Target";
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";
import * as Utils from "../utils/Utils.js";

export default {
  components: {
    Link,
    PostPreview,
    Target
  },
  data: () => ({
    name: "addPost",

    // rules for form validation
    valid: false,
    postTitleRules: [
      v => !!v || "Required",
      // v => (v && v.length >= 3) || "Title cannot be less than 3 characters", TODO: uncomment later
      v => (v && v.length <= 127) || "Title cannot have more than 127 characters"
    ],
    keywordRules: [
      (v) => !!v || "Required",
      (v) => (v && v.length > 0) || "Required",
    ],
    postBodyRules: [
      v => !!v || "Required",
      // v => (v && v.length >= 3) || "Post body cannot be less than 3 characters", TODO: uncomment later
      v => (v && v.length <= 10239) || "Post body cannot have more than 10239 characters"
    ],
    targetRules: [
      (v) => !!v || "Required",
      (v) => (v && v.length > 0) || "Required",
    ],
    post: {
      post_id: null,
      post_publication_timestamp: Utils.getUtcSecondsEpoch(),
      post_title: "",
      post_keyword: [], // must be in #HashTag form (watcher automatically converts if it does not start with #)
      post_main_image_link: {
        upload_disabled: false,
        link_type: Constants.LINK_TYPE_IMAGE,
        link_text: "",
        link_key: null,
        link_file: null,
      },
      post_body: "",
      post_separated_edit_post_ids: "",
      links: [],
      any_target_id: [],
      required_target_id: [],
      unallowed_target_id: [],
      post_tag: [],
      is_preview: false, // addPost will skip *AutoGeneratedPostTargets processing in validateAndPreview calls
    },
    originalPost: null, // used when the post is being edited

    // target and survey metadata fetched from db
    targetIdNamePairs: [{ text: "", value: 0 }],

    // tags fetched from db which can be optionally used to tag surveys (and questions)
    tags: [], // array of tag_text
    tagIdToTextMap: {}, // key:tag_id, value:tag_text

    // keywords fetched from db which is used in post targeting (i.e users can follow keywords)
    keywords: [], // array of keyword_text
    keywordIdToTextMap: {}, // key:keyword_id, value:keyword_text

    // snackbar to show a messages
    snackbarMessage: "",
    snackbarFlag: false,
    snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
    snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
    snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
    snackbarTimeout: Constants.SNACKBAR_TIMEOUT,

    // this view can be called from Search Post with command = {edit, clone}
    // note: to support validateAndPreview, we need to save a draft post to the db but we do not want to modify the
    // original post until it is saved. so when admin clicks on validateAndPreview, we create a new 'draft' post with
    // the contents of the original post. when admin clicks on save, (1) the updated/draft post is saved with
    // id=originalPostIdEdit so the fields of the original post is updated under the same post_id, so engagement log
    // or eventLogs are still consistent event after post is edited; (2) draft post is deleted from db. if admin
    // abandons the edit, we delete the draft and the original post will be kept untouched.
    command: "",
    draftPostId: null,
    draftPostTitle: null,
    originalPostIdEdit: null,
    originalPostTitleEdit: null,

    // store targets that are in-use to check if edited post title was being referenced (only during "edit")
    // e.g do not allow edit of the post title if it is being used in an target or derived predicate
    targetsInUse: {}, // key:target_id, value:target
    derivedsInUse: {}, // key:derived_id, value:derived

    // store post titles with their ids as fetched from database (to enforce/alert title uniqueness in validation)
    postTitlesInUse: {}, // key:postTitle, value:postId

    // to enable/disable post preview
    showPreview: false,
  }),
  async created() {
    if (this.$route.params.post_id) {
      // editing or cloning an existing post
      console.log("AddPost, this.$route.params:", this.$route.params);
      this.command = this.$route.params.command;
      await this.fetchAndUpdateInputFields(); // note: below async calls are made within this function
    } else {
      //this.initializePredicates();
      this.addEmptyLink();
      this.updateTargetIdNamePairs();
      this.updateTags();
      this.updateKeywords();
    }
    this.showPreview = false;

    // fetch post titles and their ids in database (to ensure post title entered does not already exist)
    await this.updatePostTitlesInUse();
  },
  methods: {
    async fetchAndUpdateInputFields() {
      let postId = this.$route.params.post_id;
      let response = await API.searchPostsByPostIdApi(Constants.POST_TABLE, "=", postId);
      console.log("\n..fetchAndUpdate, postId:", postId, " \nresponse:", response);

      // wait for the calls as below needs to access it to lookup tagTexts
      await this.updateTargetIdNamePairs();
      await this.updateTags();
      await this.updateKeywords();

      // populate the fields from post table
      console.log("\n..0 fetchAndUpdate \nthis.post:", this.post);
      response.posts.forEach((post) => {
        // when editing/cloning an existing post, updating link is not reactive
        // to fix this we need to keep the original post.links[] for now, so avoid
        // creating a new post object (i.e post = {..})
        this.post.post_id = post.post_id;
        this.post.post_title = post.post_title;
        this.post.post_body = post.post_body;
        this.post.post_main_image_link_id = post.post_main_image_link_id;
        this.post.post_separated_edit_post_ids = post.post_separated_edit_post_ids;
      });
      // console.log("\n..0.1 fetchAndUpdate \nthis.post:", this.post);

      // set post_id depending on command and update internal ids
      if (this.command === "clone") {
        // set post_id to null so a new post will be created based on the clone
        this.post.post_id = null;
      } else if (this.command === "edit") {
        this.originalPostIdEdit = this.post.post_id;
        this.originalPostTitleEdit = this.post.post_title;
        this.post.post_id = null; // so a draft post will be created if validateAndPreview is clicked
      }
      // console.log("\n..0.2 fetchAndUpdate \nthis.post:", this.post);

      // populate the fields from link table
      response.links.forEach((link) => {
        // if this is a clone or edit, we keep the original link_ids, however,
        // the lambda will insert new copies as will be reflected in link/post tables
        if (this.post.post_main_image_link_id != null
          && this.post.post_main_image_link_id === link.link_id) {
          // this link is for main image
          this.post.post_main_image_link.upload_disabled = true;
          this.post.post_main_image_link.link_type = link.link_type;
          this.post.post_main_image_link.link_text = link.link_text;
          this.post.post_main_image_link.link_key = link.link_key;
          this.post.post_main_image_link.link_url = link.link_url;
          // console.log("\n..0.4 fetchAndUpdate \nthis.post:", this.post);
        } else {
          // this link is used in post body
          link.upload_disabled = true; // the re-uploads are disabled (they should be removed and added)
          link.link_ref = link.link_id;
          this.post.links.push(link)
          // console.log("fetchAndUpdate 0.6 pushed this.post:", this.post);
        }
      });
      // console.log("fetchAndUpdate 1.0, this.post:", this.post);

      // add one more empty (modifiable) link
      this.addEmptyLink();

      // populate post (global) target fields  based on target_presence_in_profile field
      this.post.any_target_id = [];
      this.post.required_target_id = [];
      this.post.unallowed_target_id = [];
      response.postTargets.forEach(target => {
        if (target.target_presence_in_profile == Constants.ANY) {
          this.post.any_target_id.push(target.target_id);
        } else if (target.target_presence_in_profile == Constants.REQUIRED) {
          this.post.required_target_id.push(target.target_id);
        } else if (target.target_presence_in_profile == Constants.UNALLOWED) {
          this.post.unallowed_target_id.push(target.target_id);
        }
      });
      console.log("\n..1 fetchAndUpdate \nthis.post:", this.post);

      // populate tags, if exist
      this.post.post_tag = [];
      response.tags.forEach(tag => {
        let tagText = this.tagIdToTextMap[tag.tag_id]
        this.post.post_tag.push(tagText);
      });

      // populate keywords, if exist
      this.post.post_keyword = [];
      response.keywords.forEach(keyword => {
        let keywordText = this.keywordIdToTextMap[keyword.keyword_id]
        this.post.post_keyword.push(keywordText);
      });

      // set the publication timestamp based on now
      // note: main image (if exists) is updated above
      this.post.post_publication_timestamp = Utils.getUtcSecondsEpoch();
      console.log("\n..3 fetchAndUpdate \nthis.post:", this.post);

      // fetch targets that are used in other active (to ensure edited post title is not being used)
      if (this.command === "edit") {
        await this.updateTargetsInUse();
      }
    },
    async updatePostTitlesInUse() {
      const response = await API.getPostIdTitlePairsApi();
      for (let i = 0; i < response.result.length; i++) {
        const post = response.result[i];
        this.postTitlesInUse[post.post_title] = post.post_id;
      }
      //console.log("postTitlesInUse, ", this.postTitlesInUse);
    },
    async updateTargetsInUse() {
      // fetch targets that are used in other active answers/questions/surveys/posts/predicates
      this.targetsInUse = {};
      var response = await API.getTargetsInUseApi();
      response.targets.forEach((target) => {
        this.targetsInUse[target.target_id] = target;
      });
      response.deriveds.forEach((derived) => {
        this.derivedsInUse[derived.derived_id] = derived;
      });
      //console.log("targetsInUse: ", this.targetsInUse, " this.derivedsInUse: ", this.derivedsInUse);
    },
    addEmptyLink() {
      this.post.links.push({
        link_type: Constants.LINK_TYPE_IMAGE,
        link_text: "",
        link_file: null,
        link_key: null,
        link_url: null,
        link_ref: -1,
        link_index: -1,
        first_link: false,
        last_link: true,
        upload_disabled: false
      });
      this.updateLinkPositions();
    },
    updateLinkPositions() {
      let maxLinkRef = 0;
      for (let i = 0; i < this.post.links.length; i++) {
        if (this.post.links[i].link_ref > maxLinkRef) {
          maxLinkRef = this.post.links[i].link_ref;
        }
      }
      maxLinkRef += 1;
      for (let i = 0; i < this.post.links.length; i++) {
        this.post.links[i].first_link = false;
        this.post.links[i].last_link = false;
        if (i === 0) {
          this.post.links[i].first_link = true;
        }
        this.post.links[i].link_index = i;
        if (this.post.links[i].link_ref < 0) {
          this.post.links[i].link_ref = maxLinkRef;
          maxLinkRef += 1;
        }
        if (i === this.post.links.length - 1) {
          this.post.links[i].last_link = true;
        }
      }
    },
    addLink() {
      this.addEmptyLink();
    },
    removeLink(link) {
      if ((!link.link_file && link.link_text.length == 0)
        || confirm("Remove this link?")) {
        this.post.links.splice(link.link_index, 1);
        this.updateLinkPositions();
      }
    },
    emptyMainLink(link) {
      if (confirm("Remove this main image?")) {
        this.post.post_main_image_link.upload_disabled = false;
        this.post.post_main_image_link.link_type = Constants.LINK_TYPE_IMAGE;
        this.post.post_main_image_link.link_text = "";
        this.post.post_main_image_link.link_key = null;
        this.post.post_main_image_link.link_url = null;
        this.post.post_main_image_link.link_file =  null;
      }
    },
    async updateTargetIdNamePairs() {
      const response = await API.getTargetIdNamePairsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        // add special target_name_description which combines the two if description exists
        let target_name_description = item.target_name;
        if (!item.target_name.startsWith("_")
            && item.target_description && item.target_description.length > 0) {
          target_name_description = item.target_name + " (" + item.target_description + ")";
        }
        let pair = {
          text: target_name_description,
          value: item.target_id
        };
        result.push(pair);
      }
      this.targetIdNamePairs = result;
      this.targetIdNamePairs.sort((a,b)=> (b.text > a.text ? 1 : -1))
    },
    async updateTags() {
      //  fetch all tags and extract tagTexts
      var response = await API.getTagsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const tag = response.result[i];
        result.push(tag.tag_text);
        this.tagIdToTextMap[tag.tag_id] = tag.tag_text;
      }
      this.tags = result;
      console.log("this.tags:", this.tags);
      console.log("this.tagIdToTextMap, ", this.tagIdToTextMap);
    },
    removeTag(item) {
      this.post.post_tag.splice(this.post.post_tag.indexOf(item), 1)
      this.post.post_tag = [...this.post.post_tag]
    },
    async updateKeywords() {
      // fetch all keywords and extract keywordTexts
      var response = await API.getKeywordsApi();
      var result = [];
      for (let i = 0; i < response.result.length; i++) {
        const keyword = response.result[i];
        result.push(keyword.keyword_text);
        this.keywordIdToTextMap[keyword.keyword_id] = keyword.keyword_text;
      }
      this.keywords = result;
      console.log("this.keywords:", this.keywords);
      console.log("this.keywordIdToTextMap, ", this.keywordIdToTextMap);
    },
    removeKeyword(item) {
      //console.log("removeKeyword, item: ", item)
      this.post.post_keyword.splice(this.post.post_keyword.indexOf(item), 1)
      this.post.post_keyword = [...this.post.post_keyword]
      //console.log("removeKeyword, this.post.post_keyword: ", this.post.post_keyword)
    },
    validate() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        this.snackbarMessage = "Please fix entries in the required fields!";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure post title does not already exist (e.g if this is a clone)
      if (this.postTitlesInUse[this.post.post_title] != null // title already exist in db
          && this.postTitlesInUse[this.post.post_title] != this.originalPostIdEdit) { // and belongs to a different id
        this.snackbarMessage = "Post title already exists, please use a different title",
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // if post title is edited, check if the original title was used in any active auto generated targets or deriveds
      if (this.command === "edit"
          && this.originalPostTitleEdit !== this.post.post_title
          && this.isPostUsedInActiveTargets(this.originalPostTitleEdit)) {
        this.snackbarMessage = "The original post is being used in an active (auto generated) target or derived predicate",
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure at least one (A/R/U) target is selected
      if (this.post.any_target_id.length == 0
          && this.post.required_target_id.length == 0
          && this.post.unallowed_target_id.length == 0) {
        this.snackbarMessage = "Please select (Any/Required/Unallowed) Targets to match the post to users!";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure main image and related info is provided (in addition to vuetify rules)
      let mainImage = this.post.post_main_image_link;
      let isTextEntered = mainImage.link_text.length > 0 ; // caption text
      let isLinkEntered = mainImage.upload_disabled || mainImage.link_file != null;
      console.log("mainImage:", mainImage  , " isTextEntered:", isTextEntered, " isLinkEntered:", isLinkEntered);
      if (!isTextEntered || !isLinkEntered) {
        this.snackbarMessage = "Please provide main image which is required";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        this.valid = false;
        return;
      }

      // make sure all links[] entered are complete and has no missing data (caption/anchor/file/url)
      let linkRefSet = new Set();
      for (let i = 0; i < this.post.links.length; i++) {
        let link = this.post.links[i];
        let isTextEntered = link.link_text.length > 0 ; // caption or anchor text
        let isLinkEntered = link.upload_disabled
          || link.link_file != null
          || (link.link_url != null && link.link_url.length > 0); // upload or url
        if (isTextEntered != isLinkEntered) {
          this.snackbarMessage = "Please enter missing data in Reference " + link.link_ref + ".";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          this.valid = false;
          return;
        }
        if (link.link_url && link.link_url.length > 0 && !Utils.isValidUrl(link.link_url)) {
          this.snackbarMessage = "Please enter a valid URL for Reference " + link.link_ref + ".";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          this.valid = false;
          return;
        }
        if (link.link_text.length > 127) {
          var linkTextLabel = ""
          if (link.link_type === Constants.LINK_TYPE_IMAGE) {
            linkTextLabel = "Image Caption";
          } else if (link.link_type === Constants.LINK_TYPE_VIDEO) {
            linkTextLabel = "Video Caption";
          } else if (link.link_type === Constants.LINK_TYPE_DOCUMENT) {
            linkTextLabel = "Anchor Text";
          } else if (link.link_type === Constants.LINK_TYPE_URL) {
            linkTextLabel = "Anchor Text";
          }
          this.snackbarMessage = "'" + linkTextLabel + "' cannot have more than 127 character.";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          this.valid = false;
          return
        }
        if (isTextEntered && isLinkEntered) {
          linkRefSet.add(this.post.links[i].link_ref); // this is a valid and complete link
        }
      }
      console.log("linkRefSet:", linkRefSet);

      // NOTE: "make sure each ref is used once in post body (since ios processes each reference only once)"
      // this restriction does not seem to be not necessary since ios replaces all occurrences of
      // the reference, so even if it is used multiple times, all will be replaced. This restriction
      // is not enforced below (so admins can reference to the same doc/img/video multiple times in body)

      // make sure all references (e.g __link__) used in the post body are provided in links[]
      let bodyRefs = Utils.getFromBetween.get(this.post.post_body, Constants.LINK_PREFIX, Constants.LINK_SUFFIX);
      let bodyRefSet = new Set();
      for (let i = 0; i < bodyRefs.length; i++) {
        let ref = parseInt(bodyRefs[i]);
        bodyRefSet.add(ref);
        if (!linkRefSet.has(ref)) {
          this.snackbarMessage = "Reference (" + ref + ") used in Post Body is not uploaded or specified.";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          this.valid = false;
          return;
        }
      }
      console.log("bodyRefSet:", bodyRefSet);

      // NOTE: "make sure the order of items in links[] are same as in their usage in post body"
      // this restriction does not seem to be not necessary. PostViewController replaces __ref__
      // with the actual url, so the order does not matter and is not enforced below.

      // make sure all links[] entered are actually used the post body (in any order)
      let linkRefArray = Array.from(linkRefSet);
      for (let i = 0; i < linkRefArray.length; i++) {
        let ref = linkRefArray[i];
        if (!bodyRefSet.has(ref)) {
          this.snackbarMessage = "Reference (" + ref + ") is not used in Post Body.";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          this.valid = false;
          return;
        }
      }
      this.valid = true;
    },
    isPostUsedInActiveTargets(postTitle) {
      for (var [targetId, target] of Object.entries(this.targetsInUse)) {
        if (target.target_name.includes("_" + postTitle)) {
          return true;
        }
      }
      for (var [derivedId, derived] of Object.entries(this.derivedsInUse)) {
        if (derived.derived_name.includes("_" + postTitle)) {
          return true;
        }
      }
      return false;
    },
    async uploadFiles() {
      let utcSecondsEpoch = Utils.getUtcSecondsEpoch().toString();

      // note: since we expect post body to be a few KB max we will compress
      // and store it in postDDB (vs. saving the body content to s3). This will
      // also allow us avoid another api call to fetch it from s3. However, later
      // post body can also be stored on s3 if needed.

      // upload main image and update its link_key in post
      if (this.post.post_main_image_link.link_file) {
        // this is a new main imgae link with a file upload
        // note: if this is null, it could be an existing link with upload_disabled
        let linkFile = this.post.post_main_image_link.link_file;
        let linkKey = Constants.LINK_KEY_PREFIX + utcSecondsEpoch+ "_" + linkFile.name;
        this.post.post_main_image_link.link_key = linkKey;
        console.log("uploadFiles, this.post.post_main_image_link:", this.post.post_main_image_link);
        await Storage.put(linkKey, linkFile)
          .then (result => {
              console.log("UPLOAD, result:", result);
          }).catch(err => {
            console.log("UPLOAD, error:", err);
            self.snackbarMessage = "Failed to upload the file, fileName:" + linkFile.name;
            self.snackbarColor = self.snackbarColorFailure
            self.snackbarFlag = true;
            return;
          });
      }

      // upload files in the post body and update link_key fields in post.links
      console.log("uploadFiles, utcSecondsEpoch:", utcSecondsEpoch);
      for (let i = 0; i < this.post.links.length; i++) {
        console.log("this.post.links[" + i + "]:", this.post.links[i]);
        if (this.post.links[i].link_file) {
          // this is a new link with a file upload
          // note: if this is null, it could be an existing link with upload_disabled
          let linkFile = this.post.links[i].link_file;
          let linkKey = Constants.LINK_KEY_PREFIX + utcSecondsEpoch+ "_" + linkFile.name;
          this.post.links[i].link_key = linkKey;
          console.log("uploadFiles, this.post.links[" + i + "]:", this.post.links[i]);
          await Storage.put(linkKey, linkFile)
            .then (result => {
                console.log("UPLOAD, result:", result);
            }).catch(err => {
              console.log("UPLOAD, error:", err);
              self.snackbarMessage = "Failed to upload the file, fileName:" + linkFile.name;
              self.snackbarColor = self.snackbarColorFailure
              self.snackbarFlag = true;
              return;
            });
        }
      }
      console.log("uploadFiles, this.post:", this.post);
    },
    async deleteUnusedFiles() {
      // lookup all "_post_"* linkKeys (filenames) written to the database
      let linkKeysInUse = new Set();
      let response = await API.getPostLinkKeysApi();
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        const linkKey = item["link_key"];
        linkKeysInUse.add(linkKey);
      }
      console.log("deleteUnusedFiles, linkKeysInUse:", linkKeysInUse);

      // query files written to s3 with filename "_post_"* and delete ones that are not in use
      Storage.list(Constants.LINK_KEY_PREFIX)
        .then(result =>  {
          console.log("_post_ files stored on s3: ", result);
          for (let i = 0; i < result.length; i++) {
            const linkKey = result[i]["key"];
            if (!linkKeysInUse.has(linkKey)) {
              console.log("DELETING linkKey:", linkKey);
              Storage.remove(linkKey);
            }
          }
        })
        .catch(err => console.log(err));
    },
    async validateAndPreview() {
      // note: preview saves the record to the db (if valid) but asks to delete/keep it before leaving the page
      this.validate();
      if (!this.valid) {
        return;
      }

      // upload main image and other files used in the post body
      await this.uploadFiles();

      // add the post to the db for preview
      if (this.draftPostId != null) {
        // a draft post has already been created, reuse it to avoid creating a new draft for this post
        this.post.post_id = this.draftPostId;
      }

      // set preview flag so addPost will skip *AutoGeneratedPostTargets processing
      this.post.is_preview = true;

      // call add post which will create/update draft post to be previewed
      var response = await API.addPostApi(this.post);
      if (response.success == 1 && response.posts.length == 1) {
        this.draftPostId = response.posts[0].post_id;
        this.draftPostTitle = response.posts[0].post_title;
        this.snackbarMessage = "Succesfully created the Post preview!";
        this.snackbarColor = this.snackbarColorSuccess;
        this.snackbarFlag = true;
      } else {
        this.snackbarMessage = "Failed to write to the database during Post preview!";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
        return;
      }

      // delete unused files (with "_post_" prefix) that were uploaded to the storage
      await this.deleteUnusedFiles();

      // bring up PostPreview component with a close button
      // note: watcher for 'post' below is causing a race condition so make sure this is at the end
      this.showPreview = true;
      console.log("\nvalidateAndPreview, this.post:", this.post);

    },
    resetValidation() {
      //console.log("target: ", JSON.stringify(this.target));
      this.$refs.form.resetValidation();
    },
    resetPost() {
      let linkCount = this.post.links.length;
      for (var i = 0; i < linkCount; i++) {
        this.post.links.pop();
      }
      this.post = {
        post_id: null,
        post_publication_timestamp: Utils.getUtcSecondsEpoch(),
        post_title: "",
        post_keyword: [],
        post_main_image_link: {
          link_type: Constants.LINK_TYPE_IMAGE,
          link_text: "",
          link_key: null,
          link_file: null
        },
        post_body: "",
        post_separated_edit_post_ids: "",
        links: [],
        any_target_id: [],
        required_target_id: [],
        unallowed_target_id: [],
        post_tag: []
      };
    },
    resetForm() {
      this.command = "";
      this.originalPostIdEdit = null;
      this.originalPostTitleEdit = null;
      this.resetPost();
      this.$refs.form.resetValidation();
      this.addEmptyLink();
    },
    async reset() {
      if (confirm("Delete all entries in the form?")) {
        this.resetForm();

        // check if a draft post was created as part of validateAndPreview, if so, delete it
        if (this.draftPostId != null) {
          //console.log(`reset, deleting draftPostId:${this.draftPostId} draftPostTitle:${this.draftPostTitle}`);
          await API.deletePostApi(this.draftPostId, this.draftPostTitle);
        }
      }
    },
    async save() {
      this.validate();
      if (!this.valid) {
        return;
      }

      // upload main image and other files used in the post body
      await this.uploadFiles();

      // check if this is edit of an existing post, if so, set post_id so it gets updated (vs creating a new post)
      if (this.originalPostIdEdit != null) {
        this.post.post_id = this.originalPostIdEdit;
      }

      // set preview flag to false so addPost will process *AutoGeneratedPostTargets
      this.post.is_preview = false;
      console.log("in save(), post:", JSON.stringify(this.post));

      // check if a draft post was created (as part of validateAndPreview for an existing post), if so, delete it
      // note: this must be done before addPost below since it will delete all matching title-based autoGenPost targets
      //console.log(`save, check draftPostId:${this.draftPostId} originalPostIdEdit:${this.originalPostIdEdit}`);
      if (this.draftPostId != null
          && this.originalPostIdEdit != null
          && this.draftPostId != this.originalPostIdEdit) {
        console.log(`save, deleting draftPostId:${this.draftPostId} originalPostIdEdit:${this.originalPostIdEdit}`);
        await API.deletePostApi(this.draftPostId, this.draftPostTitle);
      }

      // call add post which will either update existing post (if post_id is not null) or add (create) a new post
      var response = await API.addPostApi(this.post);
      if (response.success == 1 && response.posts.length == 1) {
        this.snackbarMessage = "Succesfully saved the Post!";
        this.snackbarColor = this.snackbarColorSuccess;
        this.snackbarFlag = true;
        this.deleteUnusedFiles(); // with "_post_" prefix that were uploaded to the storage

        // note: this block should never run as of 05/23 (as we no longer delete & insert posts when editing an
        // existing post), we now properly update the fields on the same post_id without createing a new post_id
        // (i.e post_separated_edit_post_ids column in db is depreceated)
        if (this.command === "edit"
            && this.originalPostIdEdit != null
            && this.originalPostIdEdit != this.post.post_id) {
          // update post_separated_edit_post_ids field so we know this is a new version of originalPostIdEdit
          await API.updateEditPostIdApi(this.post.post_id, this.originalPostIdEdit);

          // saved the edited version of this existing post, so delete the original version
          console.log(`Deleting original post,
            originalPostIdEdit:${this.originalPostIdEdit}
            originalPostTitleEdit:${this.originalPostTitleEdit}`);
          await API.deletePostApi(this.originalPostIdEdit, this.originalPostTitleEdit);
        }
        this.resetForm();
        this.updateKeywords(); // update to include newly added keywords (if any)
      } else {
        this.snackbarMessage = "Failed to save the Post!";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
      }
    },
    isPostModified() {
      // return true if some parts of the post is modified/touched
      if (this.post.post_title.length > 0) {
        return true;
      }
      if (this.post.post_keyword.length > 0) {
        return true;
      }
      if (this.post.post_body.length > 0) {
        return true;
      }
      if (this.post.post_main_image_link.link_text.length > 0) {
        return true;
      }
      if (this.post.post_main_image_link.link_file != null) {
        return true;
      }
      if (this.post.links.length > 1) {
        return true;
      }
      if (this.post.links.length == 1 && this.post.links[0].link_file != null) {
        return true;
      }
      if (this.post.any_target_id.length > 0) {
        return true;
      }
      if (this.post.required_target_id.length > 0) {
        return true;
      }
      if (this.post.unallowed_target_id.length > 0) {
        return true;
      }
      if (this.post.post_tag.length > 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    post: {
      // any (deep) change on the form should close the preview
      deep: true,
      handler() {
        console.log("WATCHER for POST, setting showPreview false");
        this.showPreview = false;
      }
    },
    'post.post_keyword': function (newValue, oldValue) {
      // format post keywords automatically to hash tag format (e.g 'abc xyz' -> #AbcXyz)
      if (newValue.length == oldValue.length) {
        // no update but recursive call due to below pop of duplicate/empty item
        return
      }
      if (newValue.length > 0) {
        let newItem = Utils.toHashTag(newValue[newValue.length - 1])
        //console.log("newItem:", newItem)
        if (oldValue.includes(newItem) || newItem == "#") {
          // duplicates and empty strings are not allowed, ignore/delete this newItem
          //console.log("pre-pop this.post.post_keyword:", this.post.post_keyword)
          this.post.post_keyword.pop()
          return
        }
      }
      for (let i = 0; i < this.post.post_keyword.length; i++) {
        if (this.post.post_keyword[i].charAt(0) != '#') {
          this.post.post_keyword[i] = Utils.toHashTag(this.post.post_keyword[i])
        }
      }
      //console.log("WATCHER final formatted, post_keyword: ", this.post.post_keyword)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isPostModified()) {
      console.log("beforeRouteLeave, isPostModified: true");
      if (confirm('Do you want to proceed and discard the draft?')) {
        if (this.draftPostId != null) {
          // admin had clicked on validateAndPreview and created a draft/copy in DB
          // note: if this was an edit of an existing Post, the original is not modified
          console.log(`beforeRouteLeave, delete draftPostId:${this.draftPostId} draftPostTitle:${this.draftPostTitle}`);
          API.deletePostApi(this.draftPostId, this.draftPostTitle);
        }
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
