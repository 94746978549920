
import Vue from 'vue'
import App from './App.vue'
import { router } from './router'

import { Amplify } from 'aws-amplify';
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import AwsExports from './aws-exports'

import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

export function mountVue() {
  Amplify.configure(AwsExports)

  Vue.use(AmplifyPlugin, AmplifyModules)
  Vue.config.productionTip = false

  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

mountVue();