<template>
  <v-container fluid>
    <v-row align="start" dense>
      <v-col class="d-flex" cols="3" xs="2" dense>
        <v-select
          v-model="selectedSearchField"
          :items="searchFields"
          label="Search Field"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="2" xs="2" dense>
        <v-select v-model="selectedOperator" :items="operators" label="Operator" outlined dense></v-select>
      </v-col>
      <v-col v-if="searchType === 'text'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model.trim="searchQuery"
          label="Search Text"
          outlined
          dense
          @keyup.enter="searchQuestions"
        ></v-text-field>
      </v-col>
      <v-col v-if="searchType === 'float'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model="searchQuery"
          :rules="answerScoreRules"
          lazy-validation
          label="Search Value"
          append-icon="mdi-magnify"
          outlined
          dense
          @keyup.enter="searchQuestions"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="searchType === 'constant' || searchType === 'searchableConstant'"
        class="d-flex"
        cols="5"
        xs="4"
        dense
      >
        <v-autocomplete
          v-model="searchQuery"
          :items="constantItems"
          :label="constantLabel"
          outlined
          dense
          @keyup.enter="searchQuestions"
        ></v-autocomplete>
      </v-col>
      <v-col align="start" justify="start" class="d-flex" dense>
        <v-btn
          v-if="searchType === 'text' || searchType === 'float'"
          color="blue darken-2"
          @click="searchQuestions"
          dark
          dense
        >Search</v-btn>
      </v-col>
    </v-row>
    <!--p>dialogQuestion: {{dialogQuestion}}</p-->
    <!--v-btn dark color="orange darken-1" @click="snackbarFlag = true">Open Snackbar</v-btn-->
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <strong>Question:</strong>
          {{this.dialogQuestion.question_text}}
          <br />
          <strong>Answers:</strong>
          ({{this.dialogQuestion.question_answer_ordering}})
          <br />
          <ul v-if="this.dialogQuestion.question_separated_answer_ids.length > 0">
            <li
              v-for="answerId in this.dialogQuestion.question_separated_answer_ids.replace(/-/g, ',').split(',')"
              :key="answerId"
            >
              <template>
                <strong>Answer:</strong>
                {{ answers[answerId].answer_text }}
              </template>

              <template v-if="answerId in answerAnyTargets
                  || answerId in answerRequiredTargets
                  || answerId in answerUnallowedTargets">

                <br> <!-- answer has some (global) targets -->
                <template v-if="answerId in answerAnyTargets">
                  <strong>Targets-Any:</strong>
                  <ul>
                    <li v-for="(target, i) in answerAnyTargets[answerId]" :key="i">
                      <template>{{ target.target_name_description }}</template>
                    </li>
                  </ul>
                </template>
                <template v-if="answerId in answerRequiredTargets">
                  <strong>Targets-Required:</strong>
                  <ul>
                    <li v-for="(target, i) in answerRequiredTargets[answerId]" :key="i">
                      <template>{{ target.target_name_description }}</template>
                    </li>
                  </ul>
                </template>
                <template v-if="answerId in answerUnallowedTargets">
                  <strong>Targets-Unallowed:</strong>
                  <ul>
                    <li v-for="(target, i) in answerUnallowedTargets[answerId]" :key="i">
                      <template>{{ target.target_name_description }}</template>
                    </li>
                  </ul>
                </template>
              </template>

              <template v-if="answerId in answerAnyLocalTargets
                || answerId in answerRequiredLocalTargets
                || answerId in answerUnallowedLocalTargets">

                <br> <!-- answer has some local targets -->
                <template v-if="answerId in answerAnyLocalTargets">
                  <strong>LocalTargets-Any:</strong>
                  <ul>
                    <li v-for="(target, i) in answerAnyLocalTargets[answerId]" :key="i">
                      <template><strong>Question:</strong> {{ target.local_question_text }} <strong>Answer:</strong> {{ target.local_answer_text }} </template>
                    </li>
                  </ul>
                </template>
                <template v-if="answerId in answerRequiredLocalTargets">
                  <strong>LocalTargets-Required:</strong>
                  <ul>
                    <li v-for="(target, i) in answerRequiredLocalTargets[answerId]" :key="i">
                      <template><strong>Question:</strong> {{ target.local_question_text }} <strong>Answer:</strong> {{ target.local_answer_text }} </template>
                    </li>
                  </ul>
                </template>
                <template v-if="answerId in answerUnallowedLocalTargets">
                  <strong>LocalTargets-Unallowed:</strong>
                  <ul>
                    <li v-for="(target, i) in answerUnallowedLocalTargets[answerId]" :key="i">
                      <template><strong>Question:</strong> {{ target.local_question_text }} <strong>Answer:</strong> {{ target.local_answer_text }} </template>
                    </li>
                  </ul>
                </template>
              </template>

              <template v-if="answers[answerId].answer_score != null && answers[answerId].answer_score != 0">
                <strong>Score:</strong>
                {{ answers[answerId].answer_score }}
              </template>
              <template v-if="dialogQuestion.ordered && answers[answerId].answer_subcheck">
                <strong>Subshuffle:</strong> True
              </template>
              <template v-if="dialogQuestion.shuffle && answers[answerId].answer_subcheck">
                <strong>Suborder:</strong> True
              </template>
            </li>
          </ul>
          <strong>Type:</strong>
          {{this.dialogQuestion.question_type}}
          <br />
          <template v-if="this.dialogQuestion.question_id in questionAnyTargets
            || this.dialogQuestion.question_id in questionRequiredTargets
            || this.dialogQuestion.question_id in questionUnallowedTargets">

            <!-- question has some (global) targets -->
            <template v-if="this.dialogQuestion.question_id in questionAnyTargets">
              <strong>Targets-Any:</strong>
              <ul>
                <li v-for="(target, i) in questionAnyTargets[this.dialogQuestion.question_id]" :key="i">
                  <template>{{ target.target_name_description }}</template>
                </li>
              </ul>
            </template>
            <template v-if="this.dialogQuestion.question_id in questionRequiredTargets">
              <strong>Targets-Required:</strong>
              <ul>
                <li v-for="(target, i) in questionRequiredTargets[this.dialogQuestion.question_id]" :key="i">
                  <template>{{ target.target_name_description }}</template>
                </li>
              </ul>
            </template>
            <template v-if="this.dialogQuestion.question_id in questionUnallowedTargets">
              <strong>Targets-Unallowed:</strong>
              <ul>
                <li v-for="(target, i) in questionUnallowedTargets[this.dialogQuestion.question_id]" :key="i">
                  <template>{{ target.target_name_description }}</template>
                </li>
              </ul>
            </template>
          </template>

          <template v-if="this.dialogQuestion.question_id in questionAnyLocalTargets
            || this.dialogQuestion.question_id in questionRequiredLocalTargets
            || this.dialogQuestion.question_id in questionUnallowedLocalTargets">

            <!-- question has some local targets -->
            <template v-if="this.dialogQuestion.question_id in questionAnyLocalTargets">
              <strong>LocalTargets-Any:</strong>
              <ul>
                <li v-for="(target, i) in questionAnyLocalTargets[this.dialogQuestion.question_id]" :key="i">
                  <template><strong>Question:</strong> {{ target.local_question_text }} <strong>Answer:</strong> {{ target.local_answer_text }} </template>
                </li>
              </ul>
            </template>
            <template v-if="this.dialogQuestion.question_id in questionRequiredLocalTargets">
              <strong>LocalTargets-Required:</strong>
              <ul>
                <li v-for="(target, i) in questionRequiredLocalTargets[this.dialogQuestion.question_id]" :key="i">
                  <template><strong>Question:</strong> {{ target.local_question_text }} <strong>Answer:</strong> {{ target.local_answer_text }} </template>
                </li>
              </ul>
            </template>
            <template v-if="this.dialogQuestion.question_id in questionUnallowedLocalTargets">
              <strong>LocalTargets-Unallowed:</strong>
              <ul>
                <li v-for="(target, i) in questionUnallowedLocalTargets[this.dialogQuestion.question_id]" :key="i">
                  <template><strong>Question:</strong> {{ target.local_question_text }} <strong>Answer:</strong> {{ target.local_answer_text }} </template>
                </li>
              </ul>
            </template>
          </template>
          <template v-if="this.dialogQuestion.question_id in surveys">
            <strong>Surveys:</strong>
            {{ surveys[this.dialogQuestion.question_id].map(i => i.survey_name).toString() }}
          </template>
          <template v-if="this.dialogQuestion.question_id in questionTags">
            <br />
            <strong>Tags:</strong>
            {{ questionTags[this.dialogQuestion.question_id].toString() }}
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon small class="mr-3" @click="cloneQuestion">mdi-content-copy</v-icon>
          <v-icon small class="mr-3" @click="editQuestion">mdi-pencil</v-icon>
          <v-icon small class="mr-3" @click="archieveQuestion">mdi-delete</v-icon>
          <v-icon small class="mr-3" @click="dialogClose">mdi-cancel</v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="questionsDataTable != null"
      v-model="selectedQuestions"
      :headers="headers"
      :items="questionsDataTable"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, -1]
      }"
      :items-per-page="100"
      :single-select="singleSelect"
      dense
      show-select
      item-key="questionId"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDialogQuestionDetails(item)">mdi-magnify</v-icon>
        <v-icon small class="mr-2" @click="cloneQuestion(item)">mdi-content-copy</v-icon>
        <v-icon small class="mr-2" @click="editQuestion(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="archieveQuestion(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-form ref="form" lazy-validation dense>
      <v-row v-if="selectedQuestions.length > 0" class="start">
        <v-col v-if="enableSelectingSurveys === true" class="start" cols="8" dense>
          <v-select
            v-model="selectedSurveys"
            :items="surveyIdNamePairs"
            :rules="selectedSurveyRules"
            label="Add Questions to Surveys"
            multiple
            dense
            clearable
            outlined
            height="25px"
            lazy-validation
          ></v-select>
        </v-col>
        <v-col align="start" justify="start" dense>
          <v-btn
            v-if="enableSelectingSurveys === true"
            color="blue darken-2"
            @click="save"
            dark
            dense
            width="95px"
          >Save</v-btn>
          <v-btn
            v-if="enableSelectingSurveys === false"
            color="blue darken-2"
            @click="emitAddSelectedQuestions"
            dark
            dense
            width="95px"
          >Add</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarText }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  name: "searchQuestionsComponent",
  props: {
    enableSelectingSurveys: Boolean,
  },
  data() {
    return {
      searchFields: [
        "All Fields",
        "QuestionText",
        "QuestionType",
        "QuestionTarget*",
        "QuestionTargetAny",
        "QuestionTargetRequired",
        "QuestionTargetUnallowed",
        "QuestionLocalTarget*",
        "QuestionLocalTargetAny",
        "QuestionLocalTargetRequired",
        "QuestionLocalTargetUnallowed",
        "QuestionTag",
        "AnswerOrdering",
        "AnswerText",
        "AnswerScore",
        "AnswerTarget*",
        "AnswerTargetAny",
        "AnswerTargetRequired",
        "AnswerTargetUnallowed",
        "AnswerLocalTarget*",
        "AnswerLocalTargetAny",
        "AnswerLocalTargetRequired",
        "AnswerLocalTargetUnallowed",
        "SurveyName",
      ],
      selectedSearchField: "All Fields",

      // placeholder operators, updates the selected operator via v-model
      operators: ["Contains", "IsEqualTo"],
      selectedOperator: "Contains",

      // applicable operators depending on they type, e.g text, float and constants
      textOperators: ["Contains", "StartsWith", "EndsWith", "IsEqualTo"],
      floatOperators: [
        "EqualTo",
        "LessThan",
        "LessThanOrEqualTo",
        "GreaterThan",
        "GreaterThanOrEqualTo",
      ],
      constantOperators: ["IsEqualTo"],
      searchableConstantOperators: [
        "IsEqualTo",
        "Contains",
        "StartsWith",
        "EndsWith",
      ],

      // validation rule for float
      answerScoreRules: [
        (v) =>
          v == null ||
          v === "" ||
          /^[\d.]+$/.test(v) ||
          "Score must be a float",
      ],

      // initiallize search type and query
      // and also constants used for fixed fields (e.g questionType, *Targets, etc)
      searchType: "text",
      searchQuery: "",
      constantItems: [],
      constantLabel: "",
      constantItemsMap: {},

      // questions returned based on above search
      questionIds: [], // array of question ids returned from db
      questions: {}, // key:question_id, value:question (fetched from db)
      answers: {}, // key:answer_id, value:answer

      // question global targets
      questionAnyTargets: {}, // key:question_id, value:[question_target] (target_presence=any)
      questionRequiredTargets: {}, // key:question_id, value:[question_target] (target_presence=required)
      questionUnallowedTargets: {}, // key:question_id, value:[question_target] (target_presence=unallowed)

      // question local targets
      questionAnyLocalTargets: {}, // key:question_id, value:[question_localtarget] (target_presence=any)
      questionRequiredLocalTargets: {}, // key:question_id, value:[question_localtarget] (target_presence=required)
      questionUnallowedLocalTargets: {}, // key:question_id, value:[question_localtarget] (target_presence=unallowed)

      // answer global targets
      answerAnyTargets: {}, // key:answer_id, value:[answer_target] (target_presence=any)
      answerRequiredTargets: {}, // key:answer_id, value:[answer_target] (target_presence=required)
      answerUnallowedTargets: {}, // key:answer_id, value:[answer_target] (target_presence=unallowed)

      // answer local targets
      answerAnyLocalTargets: {}, // key:answer_id, value:[answer_localtarget] (target_presence=any)
      answerRequiredLocalTargets: {}, // key:answer_id, value:[answer_localtarget] (target_presence=required)
      answerUnallowedLocalTargets: {}, // key:answer_id, value:[answer_localtarget] (target_presence=unallowed)

      targetMap: {}, // key:targetId, value:target (dict with target_name and target_description)
      questionSurveysMap: {}, // key:questionId, value:[survey]
      surveyIdNameMap: {}, // key:surveyId, value:surveyName
      questionTags: {}, // key:questionId, value:[tagText]

      // store questions that are in-use to avoid erroneous archievals or deletions
      // e.g do not allow if the question is used in active predicates/surveys/localtargets
      questionsInUse: {}, // key:question_id, value:question

      // tags used on questions, provides tag_text lookup for question_tag relations fetched from db
      tagIdToTextMap: {}, // key:tag_id, value:tag_text

      // data table that contains returned questions
      singleSelect: false,
      headers: [
        {
          text: "Question",
          align: "start",
          sortable: true,
          value: "questionText",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "questionType",
        },
        {
          text: "Answers",
          align: "start",
          sortable: true,
          value: "answerCount",
        },
        {
          text: "Shuffle",
          align: "start",
          sortable: true,
          value: "answerShuffle",
        },
        {
          text: "gTargets:A/R/U",
          align: "start",
          sortable: true,
          value: "aruTargets",
        },
        {
          text: "lTargets:A/R/U",
          align: "start",
          sortable: true,
          value: "aruLocalTargets",
        },
        {
          text: "Surveys",
          align: "start",
          sortable: true,
          value: "surveys",
        },
        { text: "Actions", align: "start", sortable: false, value: "actions" },
      ],
      questionsDataTable: null,
      selectedQuestions: [],

      // dialog that shows question details, it allows deletion and can direct to edit page
      dialog: false,
      dialogQuestion: null,
      dialogQuestionId: null,
      dialogTitle: "",

      // snackbar to show a message based on actions taken in data table
      snackbarFlag: false,
      snackbarText: "",
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,

      // surveys to add the selected to questions to add to
      selectedSurveys: [],
      surveyIdNamePairs: [],
      selectedSurveyRules: [
        (v) => !!v || "Required",
        (v) => (v && v.length > 0) || "Required",
      ],
    };
  },
  created() {
    // TODO: can we identify and avoid unnecessary fetches? or just make a batch call
    this.updateQuestionTypeConstants();
    this.updateQuestionTargetConstants();
    this.updateQuestionLocalTargetConstants();
    this.updateAnswerOrderingConstants();
    this.updateAnswerTargetConstants();
    this.updateAnswerLocalTargetConstants();
    this.updateTargetMap();
    this.updateSurveyIdNamePairs();
    this.updateQuestionsInUse();
    this.updateQuestionTagConstants();
  },
  methods: {
    getResults(response, fieldName) {
      return response.success && response.result && response.result.length > 0
        ? response.result.map((item) => item[fieldName])
        : [];
    },
    async updateQuestionTypeConstants() {
      // make sure defult question types are included and the list starts with them (in the same order)
      let questionTypes = []
      for (const qType of Constants.QUESTION_TYPE_DEFAULT_LIST) {
        if (!questionTypes.includes(qType)) {
          questionTypes.push(qType);
        }
      }
      // query the DB and append any missing question types to the end (very uncommon above list not to contain all)
      const response = await API.getDistinctValuesApi(
        Constants.QUESTION_TABLE,
        Constants.QUESTION_TYPE
      );
      if (response.success) {
        for (let i = 0; i < response.result.length; i++) {
          const item = response.result[i];
          const qType = item[Constants.QUESTION_TYPE]
          if (!questionTypes.includes(qType)) {
            questionTypes.push(qType);
          }
        }
      }
      this.constantItemsMap["QuestionType"] = questionTypes;
      console.log("QuestionType, ", this.constantItemsMap);
    },
    async updateQuestionTargetConstants() {
      this.constantItemsMap["QuestionTarget*"] = []
      this.constantItemsMap["QuestionTargetAny"] = []
      this.constantItemsMap["QuestionTargetRequired"] = []
      this.constantItemsMap["QuestionTargetUnallowed"] = []
      const response = await API.getTargetsWithPresenceInProfileApi(Constants.QUESTION_TABLE);
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        if (!(this.constantItemsMap["QuestionTarget*"].includes(item.target_name))) {
          this.constantItemsMap["QuestionTarget*"].push(item.target_name)
        }
        if (item.target_presence_in_profile == Constants.ANY) {
          this.constantItemsMap["QuestionTargetAny"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.REQUIRED) {
          this.constantItemsMap["QuestionTargetRequired"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.UNALLOWED) {
          this.constantItemsMap["QuestionTargetUnallowed"].push(item.target_name)
        }
      }
      console.log("QuestionTarget*, ", this.constantItemsMap);
    },
    async updateQuestionLocalTargetConstants() {
      this.constantItemsMap["QuestionLocalTarget*"] = []
      this.constantItemsMap["QuestionLocalTargetAny"] = []
      this.constantItemsMap["QuestionLocalTargetRequired"] = []
      this.constantItemsMap["QuestionLocalTargetUnallowed"] = []
      const response = await API.getLocalTargetsWithPresenceInProfileApi(Constants.QUESTION_TABLE);
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        if (!(this.constantItemsMap["QuestionLocalTarget*"].includes(item.question_text))) {
          this.constantItemsMap["QuestionLocalTarget*"].push(item.question_text)
        }
        if (item.target_presence_in_profile == Constants.ANY) {
          this.constantItemsMap["QuestionLocalTargetAny"].push(item.question_text)
        } else if (item.target_presence_in_profile == Constants.REQUIRED) {
          this.constantItemsMap["QuestionLocalTargetRequired"].push(item.question_text)
        } else if (item.target_presence_in_profile == Constants.UNALLOWED) {
          this.constantItemsMap["QuestionLocalTargetUnallowed"].push(item.question_text)
        }
      }
      console.log("QuestionLocalTarget*, ", this.constantItemsMap);
    },
    async updateAnswerOrderingConstants() {
      // update AnswerOrdering values
      this.constantItemsMap["AnswerOrdering"] =
        Constants.QUESTION_ANSWER_ORDERING;
      console.log("AnswerOrdering, ", this.constantItemsMap);
    },
    async updateAnswerTargetConstants() {
      this.constantItemsMap["AnswerTarget*"] = []
      this.constantItemsMap["AnswerTargetAny"] = []
      this.constantItemsMap["AnswerTargetRequired"] = []
      this.constantItemsMap["AnswerTargetUnallowed"] = []
      const response = await API.getTargetsWithPresenceInProfileApi(Constants.ANSWER_TABLE);
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        if (!(this.constantItemsMap["AnswerTarget*"].includes(item.target_name))) {
          this.constantItemsMap["AnswerTarget*"].push(item.target_name)
        }
        if (item.target_presence_in_profile == Constants.ANY) {
          this.constantItemsMap["AnswerTargetAny"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.REQUIRED) {
          this.constantItemsMap["AnswerTargetRequired"].push(item.target_name)
        } else if (item.target_presence_in_profile == Constants.UNALLOWED) {
          this.constantItemsMap["AnswerTargetUnallowed"].push(item.target_name)
        }
      }
      console.log("AnswerTarget*, ", this.constantItemsMap);
    },
    async updateAnswerLocalTargetConstants() {
      this.constantItemsMap["AnswerLocalTarget*"] = []
      this.constantItemsMap["AnswerLocalTargetAny"] = []
      this.constantItemsMap["AnswerLocalTargetRequired"] = []
      this.constantItemsMap["AnswerLocalTargetUnallowed"] = []
      const response = await API.getLocalTargetsWithPresenceInProfileApi(Constants.ANSWER_TABLE);
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        if (!(this.constantItemsMap["AnswerLocalTarget*"].includes(item.question_text))) {
          this.constantItemsMap["AnswerLocalTarget*"].push(item.question_text)
        }
        if (item.target_presence_in_profile == Constants.ANY) {
          this.constantItemsMap["AnswerLocalTargetAny"].push(item.question_text)
        } else if (item.target_presence_in_profile == Constants.REQUIRED) {
          this.constantItemsMap["AnswerLocalTargetRequired"].push(item.question_text)
        } else if (item.target_presence_in_profile == Constants.UNALLOWED) {
          this.constantItemsMap["AnswerLocalTargetUnallowed"].push(item.question_text)
        }
      }
      console.log("AnswerLocalTarget*, ", this.constantItemsMap);
    },
    async updateTargetMap() {
      const response = await API.getTargetIdNamePairsApi();
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        this.targetMap[item.target_id] = {}
        this.targetMap[item.target_id].target_name = item.target_name;
        this.targetMap[item.target_id].target_description = item.target_description;
        this.targetMap[item.target_id].target_name_description = item.target_description.length == 0
          ? item.target_name
          : item.target_name + " (" + item.target_description + ")"
      }
      console.log("targetMap:", this.targetMap);
    },
    async updateSurveyIdNamePairs() {
      const response = await API.getSurveyIdNamePairsApi();
      var result = [];
      var surveyNames = [];
      for (let i = 0; i < response.result.length; i++) {
        const item = response.result[i];
        let pair = {
          text: item.survey_name,
          value: item.survey_id,
        };
        result.push(pair);
        surveyNames.push(item.survey_name);
        this.surveyIdNameMap[item.survey_id] = item.survey_name;
      }
      this.surveyIdNamePairs = result;
      this.constantItemsMap["SurveyName"] = surveyNames;
      //console.log("surveyIdNamePairs:", this.surveyIdNamePairs);
      //console.log("surveyIdNameMap:", this.surveyIdNameMap);
      //console.log("surveyNames:", surveyNames);
    },
    async updateQuestionsInUse() {
      // fetch questions that are used in other active surveys/predicates/localtargets
      this.questionsInUse = {};
      var response = await API.getQuestionsInUseApi();
      response.questions.forEach((question) => {
        this.questionsInUse[question.question_id] = question;
      });
      //console.log("this.questionsInUse:", this.questionsInUse);
    },
    async updateQuestionTagConstants() {
      // fetch and update tags that are entered for questions
      const response = await API.getTagsInUseApi(Constants.QUESTION_TAG_TABLE);
      this.constantItemsMap["QuestionTag"] = this.getResults(response, Constants.TAG_TEXT);
      //console.log("QuestionTag, ", this.constantItemsMap);
      for (let i = 0; i < response.result.length; i++) {
        const tag = response.result[i];
        this.tagIdToTextMap[tag.tag_id] = tag.tag_text;
      }
      //console.log("this.tagIdToTextMap, ", this.tagIdToTextMap);
    },
    async save() {
      // console.log("IN save()");
      let isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      // console.log("selectedSurveys:", this.selectedSurveys);
      // console.log("selectedQuestions:", this.selectedQuestions);
      let selectedQuestionIds = this.selectedQuestions.map((q) => q.questionId);
      // console.log("selectedQuestionIds:", selectedQuestionIds);
      const response = await API.addQuestionsToSurveysApi(
        selectedQuestionIds.toString(),
        this.selectedSurveys.toString()
      );
      console.log("save(), response:", response);
      this.resetDataTable();
      if (response.success) {
        this.snackbarText =
          "Successfully added selected questions to the surveys!";
        this.snackbarColor = this.snackbarColorSuccess;
      } else {
        this.snackbarText = "Failed to add selected questions to the surveys!";
        this.snackbarColor = this.snackbarColorFailure;
      }
      this.snackbarFlag = true;
    },
    getSqlColumn() {
      if (this.selectedSearchField == "QuestionText") {
        return "question_text";
      }
      if (this.selectedSearchField == "QuestionType") {
        return "question_type";
      }
      if (this.selectedSearchField == "AnswerOrdering") {
        return "question_answer_ordering";
      }
      if (this.selectedSearchField == "AnswerText") {
        return "answer_text";
      }
      if (this.selectedSearchField == "AnswerScore") {
        return "answer_score";
      }
      return "unknown_column"; // error
    },
    getSqlOperator() {
      const op = this.selectedOperator;
      if (op == "EqualTo" || op == "IsEqualTo") {
        return "=";
      }
      if (op == "Contains" || op == "StartsWith" || op == "EndsWith") {
        return "LIKE";
      }
      if (op == "LessThan") {
        return "<";
      }
      if (op == "LessThanOrEqualTo") {
        return "<=";
      }
      if (op == "GreaterThan") {
        return ">";
      }
      if (op == "GreaterThanOrEqualTo") {
        return ">=";
      }
      return "unknown_operator"; // error
    },
    getSqlSearchQuery() {
      this.searchQuery = this.searchQuery.trim();
      if (this.selectedOperator == "Contains") {
        return `%${this.searchQuery}%`;
      }
      if (this.selectedOperator == "StartsWith") {
        return `${this.searchQuery}%`;
      }
      if (this.selectedOperator == "EndsWith") {
        return `%${this.searchQuery}`;
      }
      return this.searchQuery;
    },
    async searchQuestions() {
      const field = this.selectedSearchField;
      var response;
      if (field === "All Fields") {
        response = await API.searchQuestionsByAllTextColumnsApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (
        field === "QuestionText" ||
        field === "QuestionType"
      ) {
        console.log("2, calling searchByColumnApi");
        response = await API.searchByColumnApi(
          Constants.QUESTION_TABLE,
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
        console.log("2, done");
      } else if (
        field === "AnswerText" ||
        field === "AnswerScore"
      ) {
        response = await API.searchQuestionsByAnswerApi(
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "AnswerOrdering") {
        response = await API.searchQuestionsByAnswerOrderingApi(
          this.getSqlSearchQuery()
        );
      } else if (field === "QuestionTarget*") {
        response = await API.searchQuestionsByQuestionTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          ""
        );
      } else if (field === "QuestionTargetAny") {
        response = await API.searchQuestionsByQuestionTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "any"
        );
      } else if (field === "QuestionTargetRequired") {
        response = await API.searchQuestionsByQuestionTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "required"
        );
      } else if (field === "QuestionTargetUnallowed") {
        response = await API.searchQuestionsByQuestionTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "unallowed"
        );
      } else if (field === "QuestionLocalTarget*") {
        response = await API.searchQuestionsByQuestionLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          ""
        );
      } else if (field === "QuestionLocalTargetAny") {
        response = await API.searchQuestionsByQuestionLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "any"
        );
      } else if (field === "QuestionLocalTargetRequired") {
        response = await API.searchQuestionsByQuestionLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "required"
        );
      } else if (field === "QuestionLocalTargetUnallowed") {
        response = await API.searchQuestionsByQuestionLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "unallowed"
        );
      } else if (field === "QuestionTag") {
        response = await API.searchQuestionsByTagApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "AnswerTarget*") {
        response = await API.searchQuestionsByAnswerTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          ""
        );
      } else if (field === "AnswerTargetAny") {
        response = await API.searchQuestionsByAnswerTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "any"
        );
      } else if (field === "AnswerTargetRequired") {
        response = await API.searchQuestionsByAnswerTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "required"
        );
      } else if (field === "AnswerTargetUnallowed") {
        response = await API.searchQuestionsByAnswerTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "unallowed"
        );
      } else if (field === "AnswerLocalTarget*") {
        response = await API.searchQuestionsByAnswerLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          ""
        );
      } else if (field === "AnswerLocalTargetAny") {
        response = await API.searchQuestionsByAnswerLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "any"
        );
      } else if (field === "AnswerLocalTargetRequired") {
        response = await API.searchQuestionsByAnswerLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "required"
        );
      } else if (field === "AnswerLocalTargetUnallowed") {
        response = await API.searchQuestionsByAnswerLocalTargetApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery(),
          "unallowed"
        );
      } else if (field === "SurveyName") {
        response = await API.searchQuestionsBySurveyNameApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else {
        console.log("Unknown field:", field);
      }
      console.log("\n response:", JSON.stringify(response));

      // clear questions dictionary; insert retrieved questions with key question_id
      this.questions = {};
      this.questionIds = [];
      response.questions.forEach((question) => {
        if (question.question_id > 0) { // to allow selecting only certain/recent items (if needed)
          if (!(question.question_id in this.questions)) {
            this.questionIds.push(question.question_id);
            this.questions[question.question_id] = question;
          }
        }
      });
      // console.log("\nthis.questions:", this.questions);

      // clear question*Targets dictionaries; insert received question_target items with key question_id
      this.questionAnyTargets = {};
      this.questionRequiredTargets = {};
      this.questionUnallowedTargets = {};
      response.questionTargets.forEach((target) => {
        target["target_name"] = this.targetMap[target.target_id].target_name;
        target["target_description"] = this.targetMap[target.target_id].target_description;
        target["target_name_description"] = this.targetMap[target.target_id].target_name_description;
        if (target.target_presence_in_profile == Constants.ANY) {
          if (!(target.question_id in this.questionAnyTargets)) {
            this.questionAnyTargets[target.question_id] = [];
          }
          this.questionAnyTargets[target.question_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.REQUIRED) {
          if (!(target.question_id in this.questionRequiredTargets)) {
            this.questionRequiredTargets[target.question_id] = [];
          }
          this.questionRequiredTargets[target.question_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.UNALLOWED) {
          if (!(target.question_id in this.questionUnallowedTargets)) {
            this.questionUnallowedTargets[target.question_id] = [];
          }
          this.questionUnallowedTargets[target.question_id].push(target);
        }
      });
      // console.log("\nthis.questionAnyTargets:", this.questionAnyTargets);
      // console.log("this.questionRequiredTargets:", this.questionRequiredTargets);
      // console.log("this.questionUnallowedTargets:", this.questionUnallowedTargets);

      // clear answers dictionary; insert retrieved answers with key answer_id
      this.answers = {};
      response.answers.forEach((answer) => {
        this.answers[answer.answer_id] = answer;
      });
      // console.log("\nthis.answers:", this.answers);

      // clear question*LocalTargets dictionaries; insert received question_localtarget items with key question_id
      this.questionAnyLocalTargets = {};
      this.questionRequiredLocalTargets = {};
      this.questionUnallowedLocalTargets = {};
      response.questionLocalTargets.forEach(localtarget => {
        if (localtarget.target_presence_in_profile == Constants.ANY) {
          if (!(localtarget.question_id in this.questionAnyLocalTargets)) {
            this.questionAnyLocalTargets[localtarget.question_id] = [];
          }
          this.questionAnyLocalTargets[localtarget.question_id].push(localtarget);
        }
        if (localtarget.target_presence_in_profile == Constants.REQUIRED) {
          if (!(localtarget.question_id in this.questionRequiredLocalTargets)) {
            this.questionRequiredLocalTargets[localtarget.question_id] = [];
          }
          this.questionRequiredLocalTargets[localtarget.question_id].push(localtarget);
        }
        if (localtarget.target_presence_in_profile == Constants.UNALLOWED) {
          if (!(localtarget.question_id in this.questionUnallowedLocalTargets)) {
            this.questionUnallowedLocalTargets[localtarget.question_id] = [];
          }
          this.questionUnallowedLocalTargets[localtarget.question_id].push(localtarget);
        }
      });
      // console.log("\nthis.questionAnyLocalTargets:", this.questionAnyLocalTargets);
      // console.log("this.questionRequiredLocalTargets:", this.questionRequiredLocalTargets);
      // console.log("this.questionUnallowedLocalTargets:", this.questionUnallowedLocalTargets);

      // update answers whose question have separated answer ids with a "-" character
      // note: this means answer's subcheck should be true (indicating subshuffle or suborder)
      // depending on if question's answer ordering is selected to be ordered or shuffle
      response.questions.forEach((question) => {
        let separatedAnswerIds = question.question_separated_answer_ids;
        if (separatedAnswerIds.includes("-")) {
          let answerIds = separatedAnswerIds.replace(/-/g, ",").split(",");
          // console.log("\n updating subchecks for answerIds:", answerIds);
          answerIds.forEach((answerId) => {
            this.answers[answerId]["answer_subcheck"] = false;
            if (
              separatedAnswerIds.includes("-" + answerId) ||
              separatedAnswerIds.includes(answerId + "-")
            ) {
              this.answers[answerId]["answer_subcheck"] = true;
            }
          });
        }
      });
      // console.log("updated, this.answers:", this.answers);

      // clear answer*Targets dictionaries; insert received answer_target items with key answer_id
      this.answerAnyTargets = {};
      this.answerRequiredTargets = {};
      this.answerUnallowedTargets = {};
      response.answerTargets.forEach((target) => {
        target["target_name"] = this.targetMap[target.target_id].target_name;
        target["target_description"] = this.targetMap[target.target_id].target_description;
        target["target_name_description"] = this.targetMap[target.target_id].target_name_description;
        if (target.target_presence_in_profile == Constants.ANY) {
          if (!(target.answer_id in this.answerAnyTargets)) {
            this.answerAnyTargets[target.answer_id] = [];
          }
          this.answerAnyTargets[target.answer_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.REQUIRED) {
          if (!(target.answer_id in this.answerRequiredTargets)) {
            this.answerRequiredTargets[target.answer_id] = [];
          }
          this.answerRequiredTargets[target.answer_id].push(target);
        }
        if (target.target_presence_in_profile == Constants.UNALLOWED) {
          if (!(target.answer_id in this.answerUnallowedTargets)) {
            this.answerUnallowedTargets[target.answer_id] = [];
          }
          this.answerUnallowedTargets[target.answer_id].push(target);
        }
      });
      // console.log("\nthis.answerAnyTargets:", this.answerAnyTargets);
      // console.log("this.answerRequiredTargets:", this.answerRequiredTargets);
      // console.log("this.answerUnallowedTargets:", this.answerUnallowedTargets);

      // clear answer*LocalTargets dictionaries; insert received answer_localtarget items with key answer_id
      this.answerAnyLocalTargets = {};
      this.answerRequiredLocalTargets = {};
      this.answerUnallowedLocalTargets = {};
      response.answerLocalTargets.forEach(localtarget => {
        if (localtarget.target_presence_in_profile == Constants.ANY) {
          if (!(localtarget.answer_id in this.answerAnyLocalTargets)) {
            this.answerAnyLocalTargets[localtarget.answer_id] = [];
          }
          this.answerAnyLocalTargets[localtarget.answer_id].push(localtarget);
        }
        if (localtarget.target_presence_in_profile == Constants.REQUIRED) {
          if (!(localtarget.answer_id in this.answerRequiredLocalTargets)) {
            this.answerRequiredLocalTargets[localtarget.answer_id] = [];
          }
          this.answerRequiredLocalTargets[localtarget.answer_id].push(localtarget);
        }
        if (localtarget.target_presence_in_profile == Constants.UNALLOWED) {
          if (!(localtarget.answer_id in this.answerUnallowedLocalTargets)) {
            this.answerUnallowedLocalTargets[localtarget.answer_id] = [];
          }
          this.answerUnallowedLocalTargets[localtarget.answer_id].push(localtarget);
        }
      });
      // console.log("\nthis.answerAnyLocalTargets:", this.answerAnyLocalTargets);
      // console.log("this.answerRequiredLocalTargets:", this.answerRequiredLocalTargets);
      // console.log("this.answerUnallowedLocalTargets:", this.answerUnallowedLocalTargets);

      // clear surveys dictionary; insert assigned surveys with key question_id
      this.surveys = {};
      response.surveys.forEach((survey) => {
        if (!(survey.question_id in this.surveys)) {
          this.surveys[survey.question_id] = [];
        }
        survey["survey_name"] = this.surveyIdNameMap[survey.survey_id];
        this.surveys[survey.question_id].push(survey);
      });
      // console.log("\nthis.surveys:", this.surveys);

      // clear questionTags and insert if they exist
      this.questionTags = {}; // key:questionId, value:[tag_text]
      response.tags.forEach(tag => {
        if (!(tag.question_id in this.questionTags)) {
          this.questionTags[tag.question_id] = [];
        }
        let tagText = this.tagIdToTextMap[tag.tag_id]
        this.questionTags[tag.question_id].push(tagText);
      });
      // console.log("\nthis.questionTags:", this.questionTags);

      // construct questions Data Table to be shown as rows in table
      this.questionsDataTable = [];
      this.questionIds.forEach((questionId) => {
        var row = {};
        let question = this.questions[questionId];
        row["questionId"] = question.question_id;
        row["questionText"] = question.question_text;
        row["questionType"] = question.question_type;
        row["answerShuffle"] =
          question.question_answer_ordering === "shuffle" ||
          question.question_separated_answer_ids.includes("-")
            ? "Yes"
            : "No";
        let answerIds = question.question_separated_answer_ids
          .replace(/-/g, ",")
          .split(",");
        row["answerCount"] = answerIds.length;

        // compute number of targets with any/required/unallowed presence (for both the question and its answers)
        let anyTargets = (this.questionAnyTargets[questionId] || []).length;
        let requiredTargets = (this.questionRequiredTargets[questionId] || []).length;
        let unallowedTargets = (this.questionUnallowedTargets[questionId] || []).length;

        // update above target counts with answer targets (if any)
        answerIds.forEach((answerId) => {
           anyTargets += (this.answerAnyTargets[answerId] || []).length;
           requiredTargets += (this.answerRequiredTargets[answerId] || []).length;
           unallowedTargets += (this.answerUnallowedTargets[answerId] || []).length;
        });
        row["aruTargets"] = anyTargets.toString()
          + "/" + requiredTargets.toString()
          + "/" + unallowedTargets.toString();

        // compute number of local targets with any/required/unallowed presence (for both the question and its answers)
        let anyLocalTargets = (this.questionAnyLocalTargets[questionId] || []).length;
        let requiredLocalTargets = (this.questionRequiredLocalTargets[questionId] || []).length;
        let unallowedLocalTargets = (this.questionUnallowedLocalTargets[questionId] || []).length;

        // update above target counts with answer targets (if any)
        answerIds.forEach((answerId) => {
           anyLocalTargets += (this.answerAnyLocalTargets[answerId] || []).length;
           requiredLocalTargets += (this.answerRequiredLocalTargets[answerId] || []).length;
           unallowedLocalTargets += (this.answerUnallowedLocalTargets[answerId] || []).length;
        });
        row["aruLocalTargets"] = anyLocalTargets.toString()
          + "/" + requiredLocalTargets.toString()
          + "/" + unallowedLocalTargets.toString();

        row["surveys"] =
          questionId in this.surveys ? this.surveys[questionId].length : 0;
        this.questionsDataTable.push(row);
      });
    },
    getDialogQuestionDetails(question) {
      // console.log("..dialogQuestionDetails \nquestion:", question);
      this.dialogQuestionId = question.questionId;
      this.dialogQuestion = { ...this.questions[this.dialogQuestionId] };
      this.dialogQuestion["ordered"] = false;
      if (this.dialogQuestion.question_answer_ordering == "ordered") {
        this.dialogQuestion["ordered"] = true;
      }
      this.dialogQuestion["shuffle"] = false;
      if (this.dialogQuestion.question_answer_ordering == "shuffle") {
        this.dialogQuestion["shuffle"] = true;
      }
      this.dialogTitle = "Question Details";
      this.dialog = true;
    },
    dialogClose() {
      this.dialog = false;
      this.dialogTitle = "";
    },
    async cloneQuestion(question) {
      if (question == null || !("questionId" in question)) {
        this.questionsDataTable.forEach((item) => {
          if (item.questionId === this.dialogQuestion.question_id) {
            question = item;
          }
        });
      }
      // console.log("..clone \nquestion:", question);
      var confirmationText = "Clone this question and create a new one?";
      if (
        confirm(`${confirmationText}\n\nQuestion: '${question.questionText}'`)
      ) {
        this.$router.push({
          name: "AddQuestion",
          params: { question_id: `${question.questionId}`, command: "clone" },
        });
      }
    },
    async editQuestion(question) {
      if (question == null || !("questionId" in question)) {
        this.questionsDataTable.forEach((item) => {
          if (item.questionId === this.dialogQuestion.question_id) {
            question = item;
          }
        });
      }
      // console.log("..edit \nquestion:", question);
      var confirmationText = "Edit this question?";
      if (
        confirm(`${confirmationText}\n\nQuestion: '${question.questionText}'`)
      ) {
        this.$router.push({
          name: "AddQuestion",
          params: { question_id: `${question.questionId}`, command: "edit" },
        });
      }
    },
    async archieveQuestion(question) {
      if (question == null || !("questionId" in question)) {
        this.questionsDataTable.forEach((item) => {
          if (item.questionId === this.dialogQuestion.question_id) {
            question = item;
          }
        });
      }
      // console.log("..archieveQuestion \nquestion:", question);

      var confirmationText = "Archieve this question and its answers?";
      if (confirm(`${confirmationText}\n\nQuestion: '${question.questionText}'`)) {
        // make sure this question is not in any of the surveys (including inactive ones as they may get activated)
        if (question.surveys > 0) {
          this.snackbarText = "This question is in included in a survey,\
            please remove it before deletion/archieval!";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          return;
        }

        // make sure this question is not in use in any of the active predicates/localtargets
        if (question.questionId in this.questionsInUse) {
          this.snackbarText = "This question is in-use, remove before deletion/archieval!!";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          return;
        }

        let questionIds = [question.questionId];
        // console.log("..archieveQuestion, questionIds:", questionIds.toString());
        const response = await API.archieveQuestionsAndTheirAnswersApi(
          questionIds.toString()
        );
        if (response.success) {
          this.snackbarText =
            "Successfully archieved the question and its answers!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText =
            "Failed to archieve the question and its answers!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
      }
    },
    resetDataTable() {
      this.questionsDataTable = null;
      this.selectedSearchField = "All Fields";
      this.selectedOperator = "Contains";
      this.searchQuery = "";
      this.searchType = "text";
      this.selectedQuestions = [];
    },
    emitAddSelectedQuestions() {
      this.resetDataTable();
      this.$emit("onAddSelectedQuestions");
    },
  },
  watch: {
    selectedSearchField: function (newField, oldField) {
      this.searchQuery = "";
      this.questionsDataTable = null;
      if (newField == "AnswerScore") {
        this.searchQuery = "1.0";
        this.searchType = "float";
        this.operators = this.floatOperators;
        this.selectedOperator = this.floatOperators[0];
      } else if (newField == "QuestionType" || newField == "AnswerOrdering") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "constant";
        this.operators = this.constantOperators;
        this.selectedOperator = this.constantOperators[0];
      } else if (
        newField != undefined &&
        (newField.includes("Target") ||
          newField.includes("SurveyName") ||
          newField.includes("Tag"))
      ) {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "searchableConstant";
        this.operators = this.searchableConstantOperators;
        this.selectedOperator = this.searchableConstantOperators[0];
      } else {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      }
    },
    selectedOperator: function (newOperator, oldOperator) {
      this.searchQuery = "";
      this.questionsDataTable = null;
      // console.log("selectedOperator update, this.searchType:", this.searchType);
      if (
        this.selectedSearchField != undefined &&
        (this.selectedSearchField.includes("Target") ||
          this.selectedSearchField.includes("SurveyName") ||
          this.selectedSearchField.includes("Tag"))
      ) {
        if (newOperator == "IsEqualTo") {
          this.constantLabel = this.selectedSearchField;
          this.constantItems =
            this.constantItemsMap[this.selectedSearchField] || [];
          this.searchType = "searchableConstant";
        } else if (
          newOperator == "Contains" ||
          newOperator == "StartsWith" ||
          newOperator == "EndsWith"
        ) {
          this.searchQuery = " ";
          this.searchType = "text";
        }
      }
    },
    searchQuery: function (newQuery, oldQuery) {
      if (this.questionsDataTable != null) {
        this.questionsDataTable = null;
      }
      if (
        (this.searchType == "constant" ||
          this.searchType == "searchableConstant") &&
        newQuery.trim().length > 0
      ) {
        this.searchQuestions();
      }
    },
    dialog(val) {
      val || this.dialogClose();
    },
    selectedQuestions: function (newValue, oldValue) {
      this.$emit("onUpdateSelectedQuestions", newValue);
    },
  },
};
</script>
