<template>
<v-main>
  <v-container>
    <v-row align="center" justify="center" class="my-0 py-0" dense>
      <v-col class="center" justify="center" cols="12" md="6" dense>

        <!-- usual signin after the user updates his/her password -->
        <v-form v-show="!newPasswordRequired && !confirmSigninRequired" ref="signinFormRef" lazy-validation dense @submit.prevent="signin">
          <h3>Sign in to Tibi Health</h3>
          <v-text-field
            v-model="form.email"
            label="Email"
            dense
            :rules="emailRules"
            :clearable="clearable"
            :filled="filled"
            :color="color"
            :background-color="backgroundColor"
            @keyup.enter="signin"
          ></v-text-field>
          <v-text-field
            v-model="form.password"
            label="Password"
            type="password"
            dense
            :rules="passwordRules"
            :clearable="clearable"
            :filled="filled"
            :color="color"
            :background-color="backgroundColor"
            @keyup.enter="signin"
          ></v-text-field>
          <v-btn color="pink" class="white--text" @click="signin">Sign in</v-btn>
          <!-- Add a button for "Forgot password" so we resend a temporary password -->
        </v-form>

        <!-- require a password change after the first login with temporary password (which was sent to the user's email during recruit) -->
        <v-form v-show="newPasswordRequired" ref="completeNewPasswordFormRef" dense lazy-validation @submit.prevent="completeNewPassword">
          <h3>Please update your account</h3>
          <v-text-field
            v-model="completeNewPasswordForm.newPassword"
            label="New Password"
            type="password"
            dense
            validate-on-blur
            :rules="newPasswordRules"
            :clearable="clearable"
            :filled="filled"
            :color="color"
            :background-color="backgroundColor"
          ></v-text-field>
          <v-text-field
            v-model="completeNewPasswordForm.confirmPassword"
            label="Confirm New Password"
            type="password"
            dense
            validate-on-blur
            :rules="confirmPasswordRules"
            :clearable="clearable"
            :filled="filled"
            :color="color"
            :background-color="backgroundColor"
          ></v-text-field>
          <v-text-field
            v-model="completeNewPasswordForm.phone_number"
            label="Phone Number (ex: 555-555-1212)"
            dense
            validate-on-blur
            :rules="phoneNumberRules"
            :clearable="clearable"
            :filled="filled"
            :color="color"
            :background-color="backgroundColor"
            @keyup.enter="completeNewPassword"
          ></v-text-field>
          <v-btn color="pink" class="white--text" @click="completeNewPassword">Change Password</v-btn>
        </v-form>

        <!-- require mfa based on code sent to the user's phone (which was obtained during recruit) -->
        <v-form v-show="confirmSigninRequired" ref="confirmSigninFormRef" dense lazy-validation @submit.prevent="confirmSignin">
          <h3>Please enter verification code (sms)</h3>
          <v-text-field
            v-model="confirmSigninForm.code"
            label="Verification Code"
            dense
            validate-on-blur
            :rules="confirmSigninRules"
            :clearable="clearable"
            :filled="filled"
            :color="color"
            :background-color="backgroundColor"
          ></v-text-field>
          <v-btn color="pink" class="white--text" @click="confirmSignin">Confirm</v-btn>
        </v-form>

        <!-- add forgot password https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#forgot-password -->

        <!-- add resend verification code https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#re-send-confirmation-code -->

        <v-dialog v-if="dialog" v-model="dialog" :max-width="dialogMaxWidth" @click:outside="dialogClosed">
          <v-card>
            <v-card-title class="headline my-0 py-3" style="background-color: #e50a5f">
              <span style="color: #fff">{{this.dialogTitle}}</span>
            </v-card-title>
             <v-card-text class="my-0 py-4">
              <strong>
                {{this.dialogText}}
                <br>
                {{this.dialogNext}}
              </strong>
            </v-card-text>
            <v-divider class="my-0 py-0"></v-divider>
            <v-card-actions v-if="!passwordChanged" class="my-0 pt-2" dense>
              <v-btn text @click="dialog = false" class="pink--text">
                <v-icon> mdi-close-circle-outline</v-icon>
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors'
import Home from './Home.vue'
import { mountVue } from '../main.js'
import { router } from "../router";
import { getUser, signin, completeNewPassword, confirmSignin, isUserAdmin } from "./../utils/Auth.js";

export default {
  data() {
    return {
      newPasswordRequired: false,
      form: {
        email: '',
        password: ''
      },
      emailRules: [
        v => !!v || "Required"
      ],
      passwordRules: [
        v => !!v || "Required"
      ],
      completeNewPasswordForm: {
        cognitoUser: null,
        newPassword: '',
        confirmPassword: '',
        phone_number: '',
      },
      newPasswordMin: 8,
      newPasswordRules: [
        v => !!v || "Required",
        v => (v && v.length >= this.newPasswordMin) || "Password must be at least " + this.newPasswordMin + " characters",
      ],
      confirmPasswordRules: [
        v => !!v || "Required",
        v => (v && v === this.completeNewPasswordForm.newPassword) || "Please make sure passwords match",
      ],
      phoneNumberRules: [
        (v) => !!v || "Required",
        (v) =>
          v == null ||
          v === "" ||
          /^[\d-)()+]+$/.test(v) || "Please enter a valid phone number"
      ],
      confirmSigninRequired: false,
      confirmSigninForm: {
        user: null,
        code: "",
        mfaType: "SMS_MFA"
      },
      confirmSigninRules: [
        v => !!v || "Required"
      ],
      passwordChanged: false,

      // store cognitoUser object if this is a new user who is required to enter a new password
      cognitoUser: null,

      // form input field config
      filled: true,
      clearable: false,
      color: "#e50a5f",
      backgroundColor: colors.grey.lighten4,

      // dialog that shows password change confirmation and directs to announcements
      dialog: false,
      dialogMaxWidth: 500,
      dialogTitle: "",
      dialogText: "",
      dialogNext: "",
    }
  },
  methods: {
    async signin() {
      if (this.$refs.signinFormRef.validate()) {
        try {
          const user = await signin(this.form);
          this.cognitoUser = user;
          console.log("signin(), user:", user);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.newPasswordRequired = true;
          } else if (user.challengeName === 'SMS_MFA') {
            this.newPasswordRequired = false;
            this.confirmSigninRequired = true;
          }
        } catch (error) {
          console.log({ error })
          if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
            this.dialogTitle = "Error";
            this.dialogText = "Incorrect username or password. Please try again!";
            this.dialogNext = "";
            this.dialog = true;
          }
        }
      }
    },
    async completeNewPassword() {
      if (this.$refs.completeNewPasswordFormRef.validate()) {
        this.completeNewPasswordForm.cognitoUser = this.cognitoUser;
        this.completeNewPasswordForm.phone_number = "+1" + this.completeNewPasswordForm.phone_number.replace(/\D/g,'');
        console.log("completeNewPasswordForm:", this.completeNewPasswordForm);
        const error = await completeNewPassword(this.completeNewPasswordForm);
        if (error == null) {
          this.newPasswordRequired = false;
          this.confirmSigninRequired = true;
        } else {
          this.passwordChanged = false;
          this.dialogTitle = "Error";
          this.dialogText = "Your password could not be changed, please try again!";
          this.dialogNext = "Error: " + error;
          this.dialog = true;
        }
      }
    },
    async confirmSignin() {
      if (this.$refs.confirmSigninFormRef.validate()) {
        this.confirmSigninForm.user = this.cognitoUser;
        console.log("confirmSigninForm:", this.confirmSigninForm);
        const error = await confirmSignin(this.confirmSigninForm);
        if (isUserAdmin(this.cognitoUser)) {
          mountVue();
          router.push({ name: 'Home' }, () => {});
        } else {
          this.passwordChanged = false;
          this.dialogTitle = "Error";
          this.dialogText = "Your account could not be verified to admin, please try again!";
          this.dialogNext = "Error: " + error;
          this.dialog = true;
        }
      }
    },
    showDialog() {
      this.passwordChanged = false;
      this.dialogTitle = "Error";
      this.dialogText = "Incorrect username or password. Please try again!";
      this.dialogNext = "";
      this.dialog = true;
    },
    dialogClosed() {
       console.log("dialogClosed clicked, passwordChanged:", this.passwordChanged);
    }
  },
}
</script>