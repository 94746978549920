import { API, Auth } from 'aws-amplify'

const CONTENTDB_API_NAME = 'contentdbapi'
const CONTENTDB_API_PATH = '/run'

const TCARE_API_NAME = 'tcareapi'
const TCARE_API_PATH = '/run'

/*
Create two DBs (dev, prod)
This API could infer which one to use based on git-branch and pass it to lambda function
Then lambda function can run the same sql commands against dev or prod DBs
https://stackoverflow.com/questions/62225567/get-current-git-branch-with-node-js
https://medium.com/@masnun/node-js-getting-current-git-commit-information-on-an-app-753a1835c57c

OR can this be an environment variable?
https://ssr.vuejs.org/guide/css.html#enabling-css-extraction
const isProduction = process.env.NODE_ENV === 'production'
*/

/*
Amplify bug:
  - https://github.com/aws-amplify/amplify-cli/issues/8414
  - https://github.com/aws-amplify/amplify-cli/issues/3390
Due to above issues, (as of 12/21) contentdbapi needs to be manually configured:
(1)
    mac: ~/code/web/content-web-admin $  amplify add api
    ? Select from one of the below mentioned services: REST
    ✔ Provide a friendly name for your resource to be used as a label for this category in the project: · contentdbapi
    ✔ Provide a path (e.g., /book/{isbn}): · /run
    ✔ Choose a Lambda source · Use a Lambda function already added in the current Amplify project
    Only one option for [Choose the Lambda function to invoke by this path]. Selecting [contentdbfunction].
    ✔ Restrict API access? (Y/n) · no
    ✔ Do you want to add another path? (y/N) · no
      Successfully added resource contentdbapi locally
(2)
    - Go API console and create (cognito) Authorizer
    - Configure Resources /run/ANY to use this Authorizer
    - Deploy api to dev/prod from connsolue
After this Amplify bug is fixed:
  - ampllify remove api (contentdbapi)
  - add contentdbap back by pointing to contentdbfunction
  - can remove headers: {} from API.get() calls below
Note:
  - as of 06/22 survey-web-admin is migrated to content-web-admin and it reuses (pulls) public-tibi-health backend
  - so it no longer has this issue, so removing all headers:
      headers: {
        Authorization: (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken
      }
*/

export async function addQuestionApi(question) {
  let questionString = JSON.stringify(question);
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'addQuestion',
      question: questionString
    }
  });
  return response;
}

export async function updateQuestionApi(originalQuestion, newQuestion) {
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'updateQuestion',
      originalQuestion: JSON.stringify(originalQuestion),
      newQuestion: JSON.stringify(newQuestion)
    }
  });
  return response;
}

export async function getDistinctValuesApi(table, column) {
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getDistinctValues',
      table: table,
      column: column
    }
  });
  return response;
}

export async function getTargetNamesApi(table) {
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getTargetNames',
      table: table
    }
  });
  return response;
}

export async function getTargetIdNamePairsApi() {
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getTargetIdNamePairs'
    }
  });
  return response;
}

export async function getSurveyIdNamePairsApi() {
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getSurveyIdNamePairs'
    }
  });
  return response;
}

export async function getPostIdTitlePairsApi() {
  const response = await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getPostIdTitlePairs'
    }
  });
  return response;
}

export async function searchByColumnApi(table, column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchByColumn',
      table: table,
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function searchByQuestionIdApi(table, operator, questionId) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchByQuestionId',
      table: table,
      operator: operator,
      questionId: questionId
    }
  });
}

export async function searchQuestionsByAnswerApi(column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByAnswer',
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function searchQuestionsByAnswerOrderingApi(query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByAnswerOrdering',
      query: query
    }
  });
}

export async function searchQuestionsByQuestionTargetApi(operator, query, targetPresenceInProfile) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByQuestionTarget',
      operator: operator,
      query: query,
      targetPresenceInProfile: targetPresenceInProfile
    }
  });
}

export async function searchQuestionsByAnswerTargetApi(operator, query, targetPresenceInProfile) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByAnswerTarget',
      operator: operator,
      query: query,
      targetPresenceInProfile: targetPresenceInProfile
    }
  });
}

export async function searchQuestionsByAllTextColumnsApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByAllTextColumns',
      operator: operator,
      query: query
    }
  });
}

export async function searchQuestionsBySurveyNameApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsBySurveyName',
      operator: operator,
      query: query
    }
  });
}

export async function archieveQuestionsAndTheirAnswersApi(questionIds) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'archieveQuestionsAndTheirAnswers',
      questionIds: questionIds
    }
  });
}

export async function addQuestionsToSurveysApi(questionIds, surveyIds) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'addQuestionsToSurveys',
      questionIds: questionIds,
      surveyIds: surveyIds
    }
  });
}

export async function addSurveyApi(survey) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'addSurvey',
      survey: JSON.stringify(survey)
    }
  });
}

export async function updateSurveyApi(originalSurvey, newSurvey) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'updateSurvey',
      originalSurvey: JSON.stringify(originalSurvey),
      newSurvey: JSON.stringify(newSurvey)
    }
  });
}

export async function searchSurveysByAllTextColumnsApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysByAllTextColumns',
      operator: operator,
      query: query
    }
  });
}

export async function searchSurveysByColumnApi(table, column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysByColumn',
      table: table,
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function searchSurveysBySurveyTabApi(query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysBySurveyTab',
      query: query
    }
  });
}

export async function searchSurveysByTargetApi(operator, query, targetPresenceInProfile) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysByTarget',
      operator: operator,
      query: query,
      targetPresenceInProfile: targetPresenceInProfile
    }
  });
}

export async function searchSurveysByQuestionOrderingApi(query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysByQuestionOrdering',
      query: query
    }
  });
}

export async function searchSurveysBySurveyIdApi(table, operator, surveyId) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysBySurveyId',
      table: table,
      operator: operator,
      surveyId: surveyId
    }
  });
}

export async function archieveSurveyApi(surveyId, surveyName) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'archieveSurvey',
      surveyId: surveyId,
      surveyName: surveyName
    }
  });
}

export async function updateColumnValueApi(table, column, value, queryColumn, queryValue) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'updateColumnValue',
      table: table,
      column: column,
      value: value,
      queryColumn: queryColumn,
      queryValue: queryValue
    }
  });
}
export async function addTargetApi(target) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'addTarget',
      target: JSON.stringify(target)
    }
  });
}

export async function getTargetPredicateColumnApi(column) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getTargetPredicateColumn',
      column: column
    }
  });
}

export async function searchTargetsByColumnApi(table, column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchTargetsByColumn',
      table: table,
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function  searchTargetsByPredicateApi(column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchTargetsByPredicate',
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function searchTargetsByTargetIdApi(table, operator, targetId) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchTargetsByTargetId',
      table: table,
      operator: operator,
      targetId: targetId
    }
  });
}

export async function updateTargetApi(originalTarget, newTarget) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'updateTarget',
      originalTarget: JSON.stringify(originalTarget),
      newTarget: JSON.stringify(newTarget)
    }
  });
}

export async function archieveTargetApi(targetId) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'archieveTarget',
      targetId: targetId
    }
  });
}

export async function getSkipQuestionTextsApi(table) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getSkipQuestionTexts',
      table: table
    }
  });
}

export async function searchQuestionsBySkipQuestionApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsBySkipQuestion',
      operator: operator,
      query: query
    }
  });
}

export async function searchQuestionsBySkipAnswerApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsBySkipAnswer',
      operator: operator,
      query: query
    }
  });
}

export async function getSkipQuestionsApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getSkipQuestions'
    }
  });
}

export async function getActivePredicatesApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getActivePredicates'
    }
  });
}

export async function getTargetsInUseApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getTargetsInUse'
    }
  });
}

export async function getQuestionsInUseApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getQuestionsInUse'
    }
  });
}

export async function getTagsApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getTags'
    }
  });
}

export async function getTagsInUseApi(table) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      table: table,
      command: 'getTagsInUse'
    }
  });
}

export async function searchQuestionsByTagApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByTag',
      operator: operator,
      query: query
    }
  });
}

export async function searchSurveysByTagApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchSurveysByTag',
      operator: operator,
      query: query
    }
  });
}

export async function getDerivedIdNamePairsApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getDerivedIdNamePairs'
    }
  });
}

export async function searchDerivedsByColumnApi(table, column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchDerivedsByColumn',
      table: table,
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function getActiveDerivedsApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getActiveDeriveds'
    }
  });
}

export async function addPostApi(post) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'addPost',
      post: JSON.stringify(post)
    }
  });
}

export async function getPostLinkKeysApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getPostLinkKeys'    }
  });
}

export async function deletePostApi(postId, postTitle) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'deletePost',
      postId: postId,
      postTitle: postTitle
    }
  });
}

export async function searchTargetsByAllTextColumnsApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchTargetsByAllTextColumns',
      operator: operator,
      query: query
    }
  });
}

export async function searchPostsByAllTextColumnsApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByAllTextColumns',
      operator: operator,
      query: query
    }
  });
}

export async function searchPostsByColumnApi(table, column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByColumn',
      table: table,
      column: column,
      operator: operator,
      query: query
    }
  });
}


export async function searchPostsByTargetApi(operator, query, targetPresenceInProfile) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByTarget',
      operator: operator,
      query: query,
      targetPresenceInProfile: targetPresenceInProfile
    }
  });
}

export async function searchPostsByTagApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByTag',
      operator: operator,
      query: query
    }
  });
}

export async function searchPostsByLinkTextApi(column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByLinkText',
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function searchPostsByLinkTypeApi(column, operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByLinkType',
      column: column,
      operator: operator,
      query: query
    }
  });
}

export async function searchPostsByPostIdApi(table, operator, postId) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByPostId',
      table: table,
      operator: operator,
      postId: postId
    }
  });
}

export async function archievePostApi(postId, postTitle) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'archievePost',
      postId: postId,
      postTitle: postTitle
    }
  });
}
export async function updateEditPostIdApi(newPostId, originalPostId) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'updateEditPostId',
      newPostId: newPostId,
      originalPostId: originalPostId
    }
  });
}

export async function getKeywordsApi() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getKeywords'
    }
  });
}

export async function getKeywordsInUseApi(table) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      table: table,
      command: 'getKeywordsInUse'
    }
  });
}

export async function searchPostsByKeywordApi(operator, query) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchPostsByKeyword',
      operator: operator,
      query: query
    }
  });
}

export async function ensureAutoGeneratedTargetsCreatedApi(postId, postTitle) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'ensureAutoGeneratedTargetsCreated',
      postId: postId,
      postTitle: postTitle
    }
  });
}

export async function getTargetsWithPresenceInProfileApi(table) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getTargetsWithPresenceInProfile',
      table: table
    }
  });
}

export async function getLocalTargetsWithPresenceInProfileApi(table) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getLocalTargetsWithPresenceInProfile',
      table: table
    }
  });
}

export async function searchQuestionsByQuestionLocalTargetApi(operator, query, targetPresenceInProfile) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByQuestionLocalTarget',
      operator: operator,
      query: query,
      targetPresenceInProfile: targetPresenceInProfile
    }
  });
}

export async function searchQuestionsByAnswerLocalTargetApi(operator, query, targetPresenceInProfile) {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'searchQuestionsByAnswerLocalTarget',
      operator: operator,
      query: query,
      targetPresenceInProfile: targetPresenceInProfile
    }
  });
}

export async function getPublicTibiProviderApi() {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'getPublicTibiProvider'
    }
  });
  return response;
}

export async function getTibiAppointmentTypeScanApi() {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'getTibiAppointmentTypeScan'
    }
  });
  return response;
}

export async function getAllTibiCareEventsApi() {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'getAllTibiCareEvents'
    }
  });
  return response;
}

export async function updateTibiProviderFieldApi(id, fieldName, fieldValue) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'updateTibiProviderField',
      id: id,
      fieldName: fieldName,
      fieldValue: fieldValue
    }
  });
  return response;
}

export async function updateTibiCareEventFieldApi(id, fieldName, fieldValue) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'updateTibiCareEventField',
      id: id,
      fieldName: fieldName,
      fieldValue: fieldValue
    }
  });
  return response;
}

export async function getAcuityAppointmentTypesApi() {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'getAcuityAppointmentTypes'
    }
  });
  return response;
}

export async function getAcuityClassAvailabilityApi(appointmentTypeID) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'getAcuityClassAvailability',
      appointmentTypeID: appointmentTypeID,
      includePrivate: 'true', // fixed as required to be true for the validation
      includeUnavailable: 'true', // fixed as required to be true for the validation
      timezone: 'UTC' // fixed as required to be UTC when setting sessionTimeUtcs
    }
  });
  return response;
}

export async function approveTibiCareEventApi(id, appointmentTypeId, sessionTimeUtcs) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'approveTibiCareEvent',
      id: id,
      appointmentTypeId: appointmentTypeId,
      sessionTimeUtcs: sessionTimeUtcs
    }
  });
  return response;
}

export async function checkZoomLicenseAvailability(timeUtc, duration) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'checkZoomLicenseAvailability',
      timeUtc: timeUtc,
      duration: duration
    }
  });
  return response;
}

export async function allocateTibiZoomLicense(
    providerSub, calendarId, appointmentTypeId, appointmentTypeName, type, timeUtc, duration) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'allocateTibiZoomLicense',
      providerSub: providerSub,
      calendarId: calendarId,
      appointmentTypeId: appointmentTypeId,
      appointmentTypeName: appointmentTypeName,
      type: type,
      timeUtc: timeUtc,
      duration: duration
    }
  });
  return response;
}

export async function getProviderSubscriptionStatusData(sub) {
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'getProviderSubscriptionStatusData',
      sub: sub
    }
  });
  return response;
}

export async function startProviderSubscription(sub, email) {
  let params = {
    command: 'startProviderSubscription',
    sub: sub,
    email: email
  }
  console.log(`startProviderSubscription.params: ${JSON.stringify(params)}`)
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: {
      command: 'startProviderSubscription',
      sub: sub,
      email: email
    }
  });
  return response;
}

export async function updateCareData(params) {
  params.command = 'updateCareData';
  const response = await API.get(TCARE_API_NAME, TCARE_API_PATH, {
    queryStringParameters: params
  });
  return response;
}

export async function isDbAvailable() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'isDbAvailable'
    }
  });
}

export async function getDbInstance() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getDbInstance'
    }
  });
}

export async function stopDbInstance() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'stopDbInstance'
    }
  });
}

export async function startDbInstance() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'startDbInstance'
    }
  });
}

export async function getUserContentTargetingPipelineName() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'getUserContentTargetingPipelineName'
    }
  });
}

export async function activateUserContentTargetingPipeline() {
  return await API.get(CONTENTDB_API_NAME, CONTENTDB_API_PATH, {
    queryStringParameters: {
      command: 'activateUserContentTargetingPipeline'
    }
  });
}