<template>
  <v-container fluid>
    <template v-if="!showProgressCircular">
      <v-row align="start">
        <b class="mr-1 pr-0">datapipelineName:</b>{{ userContentTargetingPipelineName }}
      </v-row>
      <v-row align="start">
        <v-btn
          v-if="userContentTargetingPipelineName.length > 0"
          color="blue darken-2"
          @click="activateUserContentTargetingPipeline"
          class="mt-2 pt-0"
          dark
          dense
        >Run</v-btn>
        <v-btn
          v-if="userContentTargetingPipelineName.length == 0"
          color="blue darken-2"
          @click="getUserContentTargetingPipelineName"
          class="mt-2 pt-0"
          dark
          dense
        >Refresh</v-btn>
      </v-row>
    </template>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarText }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div class="text-center" v-if="showProgressCircular">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  name: "manageDatabase",
  data() {
    return {
      userContentTargetingPipelineName: "",

      // spinner to show while waiting for API calls
      showProgressCircular: true,

      // snackbar to show error message
      snackbarText: "",
      snackbarFlag: false,
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,
    };
  },
  async created() {
    console.time("runUserContentTargeting");
    await this.getUserContentTargetingPipelineName();
    console.timeEnd("runUserContentTargeting");
  },
  methods: {
    async getUserContentTargetingPipelineName() {
      this.showProgressCircular = true;
      let response = await API.getUserContentTargetingPipelineName();
      this.showProgressCircular = false;
      if (response.status == 0 && response.result && response.result.length > 0) {
        this.userContentTargetingPipelineName = response.result;
      } else {
        this.snackbarText = "Datapipeline user-content-targeting does not exist, please make sure it is set up.";
        this.snackbarColor = this.snackbarColorFailure;
        this.snackbarFlag = true;
      }
    },
    async activateUserContentTargetingPipeline() {
      if (confirm(`Run ${this.userContentTargetingPipelineName} now?\n\nNote: It takes about 25 minutes to finish and costs $0.10/run`)) {
        this.showProgressCircular = true;
        let response = await API.activateUserContentTargetingPipeline();
        console.log(`activateUserContentTargetingPipeline, response:${JSON.stringify(response)}`);
        this.showProgressCircular = false;
        if (response.status == 0) {
          this.snackbarText = "Successfully started user-content-targeting run.";
          this.snackbarColor = this.snackbarColorSuccess;
          this.snackbarFlag = true;
        } else if (response.status == 2) {
          this.snackbarText = "Datapipeline user-content-targeting does not exist, please make sure it is set up.";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
        } else if (response.status == 3) {
          this.snackbarText = "Failed to start user-content-targeting, please make sure it is set up correctly.";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
        }
      }
    },
  },
}
</script>