<template>
  <v-container fluid>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="questions"
      :single-select="singleSelect"
      item-key="question_id"
      class="elevation-1"
      dense
    >
      <template v-slot:body="props">
        <draggable :list="props.items" tag="tbody" @change="someHandler(props.items)">
          <tr v-for="(question, index) in props.items" :key="index">
            <td>
              <v-icon small>mdi-drag-vertical</v-icon>
              {{ question.question_id }}
            </td>
            <td>{{ question.question_text }}</td>
            <td>
              <v-simple-checkbox v-model="question.subcheck"></v-simple-checkbox>
            </td>
            <td>
              <v-icon small class="mr-2" @click="remove(question)">mdi-delete</v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "dataTableExample",
  components: {
    Draggable,
  },
  data() {
    return {
      singleSelect: true,
      survey_question_ordering: "ordered",
      selected: [],
      questions: [
        {
          question_id: 1,
          question_text: "a",
          subcheck: false,
        },
        {
          question_id: 2,
          question_text: "b",
          subcheck: false,
        },
        {
          question_id: 3,
          question_text: "c",
          subcheck: false,
        },
        {
          question_id: 4,
          question_text: "d",
          subcheck: false,
        },
        {
          question_id: 5,
          question_text: "e",
          subcheck: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      let subcheckName =
        this.survey_question_ordering === "ordered" ? "Subshuffle" : "Suborder";
      return [
        { text: "QuestionID", value: "question_id", sortable: false },
        { text: "QuestionText", value: "question_text", sortable: false },
        { text: subcheckName, value: "subcheck", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    remove(question) {},
    someHandler(items) {
      console.log("items:", items);

    }
  },
};
</script>