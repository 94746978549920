<template>
  <v-container fluid class="ml-12 my-0 py-0" dense>
    <!--p>Link: {{value}}</p-->
    <v-row v-if="isMainLink" align="start" justify="start" class="my-0 py-0" dense>
      <v-col cols="3" dense class="my-0 py-0">
        <v-file-input
          v-if="!value.upload_disabled"
          v-model="value.link_file"
          chips
          prepend-icon=""
          append-icon="mdi-paperclip"
          dense
          height=35
          class="my-0 py-0"
          clearable
          show-size
          :rules="mainImageRules"
          :disabled="isDisabled"
          :label="linkFileLabel"
          :accept="filesAccepted"
      ></v-file-input>
      <v-file-input
        v-if="value.upload_disabled"
        v-model="value.link_file"
        chips
        prepend-icon=""
        append-icon="mdi-paperclip"
        dense
        height=35
        class="my-0 py-0"
        clearable
        show-size
        :disabled="isDisabled"
        :label="linkFileLabel"
        :accept="filesAccepted"
      ></v-file-input>
      </v-col>
      <v-col cols="3" dense class="my-0 py-0">
        <v-text-field
          v-model="value.link_text"
          :label="linkTextLabel"
          lazy-validation
          dense
          height=35
          class="my-0 py-0"
          :rules="mainCaptionRules"
        ></v-text-field>
      </v-col>
      <v-btn
        v-if="isDisabled"
        class="mx-1 px-1 mb-0 pb-0"
        color="blue darken-2"
        height="35px"
        width="35px"
        dark
        fab
        @click="$emit('onEmptyMainLink', value)">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>

    <v-row v-if="!isMainLink" align="start" justify="start" class="my-0 py-0" dense>
      <v-col class="my-0 py-0" cols="1" dense>
        <v-text-field
          v-model="value.link_ref"
          label="Reference"
          lazy-validation
          disabled
          dense
          height=35
          class="my-0 py-0"
        ></v-text-field>
      </v-col>
      <v-col class="my-0 py-0" cols="2" dense>
        <v-select
          v-model="value.link_type"
          :items="linkTypes"
          :disabled="isDisabled"
          label="Type"
          dense
          height=35
          class="my-0 py-0"
        ></v-select>
      </v-col>
      <v-col v-if="isTypeUrl" class="my-0 py-0" cols="3" dense>
        <v-text-field
          v-model="value.link_url"
          label="URL"
          lazy-validation
          dense
          height=35
          class="my-0 py-0"
        ></v-text-field>
      </v-col>
      <v-col v-if="!isTypeUrl" class="my-0 py-0" cols="3" dense>
        <v-file-input
          v-model="value.link_file"
          chips
          prepend-icon=""
          append-icon="mdi-paperclip"
          dense
          height=35
          class="my-0 py-0"
          clearable
          show-size
          :disabled="isDisabled"
          :label="linkFileLabel"
          :accept="filesAccepted"
        ></v-file-input>
      </v-col>
      <v-col class="my-0 py-0" cols="3" dense>
        <v-text-field
          v-model="value.link_text"
          :label="linkTextLabel"
          lazy-validation
          dense
          height=35
          class="my-0 py-0"
        ></v-text-field>
      </v-col>
      <v-btn
        v-if="!value.first_link || !value.last_link"
        class="mx-1 px-1 mb-0 pb-0"
        color="blue darken-2"
        height="35px"
        width="35px"
        dark
        fab
        @click="$emit('onRemoveLink', value)">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="value.last_link"
        class="mx-1 px-1 mb-0 pb-0"
        color="blue darken-2"
        height="35px"
        width="35px"
        dark
        fab
        @click="$emit('onAddLink')">
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  data: () => ({
    // Links can be of type Constants.LINK_TYPES_FIXED = {Image, Video, Url, Document}
    linkTypes: [],

    // link text labels depending on the link type:
    // for type="Url", label: "Anchor Text"
    // for type="Document", label: "Anchor Text"
    // for type="Image", label: "Image Caption"
    // for type="Video", label: "Video Caption"

    // set the default link text label to be an image
    linkTextLabel: "",

    // set the default file label to be an image
    linkFileLabel: "",

    // file formats accepted depending on the type
    documentFilesAccepted: "application/pdf,application/msword,\
      text/plain,application/vnd.ms-excel,application/vnd.ms-powerpoint,.doc,.docx",
    imageFilesAccepted: "image/*,.pdf,.eps,.ai",
    videoFilesAccepted: ".m3u8,.mp4",

    // main image is required (unless the change/upload is disabled, e.g in inital edit mode)
    // note: main image cannot be re-uploaded directly in edit, main image must be deleted and created to re-upload
    mainImageRules: [
      v => !!v || "Required"
    ],
    // main image caption is required
    mainCaptionRules: [
      v => !!v || "Required",
      v => (v && v.length <= 127) || "Caption cannot have more than 127 characters"
    ],
  }),
  created() {
    this.updateLinkTypeConstants();
    this.updateLinkFields(this.value.link_type);
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isMainLink: Boolean
  },
  methods: {
    updateLinkTypeConstants() {
      this.linkTypes = Constants.LINK_TYPES_FIXED;
    },
    getUploadedFileName() {
      // return the original name of the file uploaded (as the file label
      // note link_key has format: _post_timestamp_<fileName>
      return this.value.link_key.split("_")[3];
    },
    updateLinkFields() {
      let linkType = this.value.link_type;
      if (this.isMainLink) {
        linkType = Constants.LINK_TYPE_IMAGE;
        this.linkFileLabel =  this.value.upload_disabled ? this.getUploadedFileName() : "*Main Image";
        this.linkTextLabel = "*Image Caption";
        this.filesAccepted = this.imageFilesAccepted;
      } else if (linkType === Constants.LINK_TYPE_IMAGE) {
        this.linkTextLabel = "Image Caption";
        this.linkFileLabel =  this.value.upload_disabled ? this.getUploadedFileName() : "Upload Image";
        this.filesAccepted = this.imageFilesAccepted;
      } else if (linkType === Constants.LINK_TYPE_VIDEO) {
        this.linkTextLabel = "Video Caption";
        this.linkFileLabel =  this.value.upload_disabled ? this.getUploadedFileName() : "Upload Video";
        this.filesAccepted = this.videoFilesAccepted;
      } else if (linkType === Constants.LINK_TYPE_DOCUMENT) {
        this.linkTextLabel = "Anchor Text";
        this.linkFileLabel =  this.value.upload_disabled ? this.getUploadedFileName() : "Upload Document";
        this.filesAccepted = this.documentFilesAccepted;
      } else if (linkType === Constants.LINK_TYPE_URL) {
        this.linkTextLabel = "Anchor Text";
      }
    }
  },
  watch: {
    value: {
      // any (deep) change on the link
      deep: true,
      handler() {
        // console.log("WATCHER for LINK, value:", this.value);
        this.updateLinkFields();
      }
    }
  },
  computed: {
    isTypeUrl() {
      return (this.value.link_type === Constants.LINK_TYPE_URL) ? true : false;
    },
    isDisabled() {
      if (this.isTypeUrl) {
        return false;
      }
      return this.value.upload_disabled;
    }
  }
};
</script>

<style>
.material-icons { vertical-align: bottom }
</style>
