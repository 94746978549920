<template>
  <v-container fluid>
    <v-row align="start" dense>
      <v-col class="d-flex" cols="3" xs="2" dense>
        <v-select
          v-model="selectedSearchField"
          :items="searchFields"
          label="Search Field"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="2" xs="2" dense>
        <v-select v-model="selectedOperator" :items="operators" label="Operator" outlined dense></v-select>
      </v-col>
      <v-col v-if="searchType === 'text'" class="d-flex" cols="4" xs="4" dense>
        <v-text-field
          v-model.trim="searchQuery"
          label="Search Text"
          outlined
          dense
          @keyup.enter="searchTargets"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="searchType === 'constant' || searchType === 'searchableConstant'"
        class="d-flex"
        cols="5"
        xs="4"
        dense
      >
        <v-autocomplete
          v-model="searchQuery"
          :items="constantItems"
          :label="constantLabel"
          outlined
          dense
          @keyup.enter="searchTargets"
        ></v-autocomplete>
      </v-col>
      <v-col align="start" justify="start" class="d-flex" dense>
        <v-btn
          v-if="searchType === 'text'"
          color="blue darken-2"
          @click="searchTargets"
          dark
          dense
        >Search</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="targetsDataTable != null"
      :headers="headers"
      :items="targetsDataTable"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, -1]
      }"
      :items-per-page="100"
      dense
      item-key="targetName"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDialogTargetDetails(item)">mdi-magnify</v-icon>
        <v-icon small class="mr-2" @click="cloneTarget(item)">mdi-content-copy</v-icon>
        <v-icon v-if="!isTargetAutoGenerated(item)" small class="mr-2" @click="editTarget(item)">mdi-pencil</v-icon>
        <v-icon v-if="!isTargetAutoGenerated(item)" small class="mr-2" @click="archieveTarget(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <!--p>dialogTarget: {{dialogTarget}} </p>
          <p>activePredicateIdsInTarget: {{activePredicateIdsInTarget}} </p-->
          <strong>Name:</strong>
          {{this.dialogTarget.target_name}}
          <br />
          <template v-if="this.dialogTarget.target_description && this.dialogTarget.target_description.length > 0">
            <strong>Description:</strong>
            {{this.dialogTarget.target_description}}
            <br />
          </template>
          <strong>Predicates:</strong>
          <ul>
            <li v-for="predicateId in activePredicateIdsInTarget" :key="predicateId">
             <!--p> isTarget: {{activePredicates[predicateId].predicate_type === 'Target'}} </p>
             <p> isQuestion: {{activePredicates[predicateId].predicate_type === 'Question'}} </p>
             <p> {{activePredicates[predicateId]}} </p-->
              <template v-if="activePredicates[predicateId].predicate_type === 'Question'">
                <strong>Question:</strong>
                {{ activeQuestions[activePredicates[predicateId].predicate_key].question_text }}
                <strong>Comparison:</strong>
                {{ activePredicates[predicateId].predicate_comparison_function }}
                <strong>Value:</strong>
                {{ getAnswerTextCsvInDialogBox(predicateId) }}
              </template>
              <template v-if="activePredicates[predicateId].predicate_type === 'Derived'">
                <strong>Derived:</strong>
                {{ activeDeriveds[activePredicates[predicateId].predicate_key].derived_name_description }}
                <strong>Comparison:</strong>
                {{ activePredicates[predicateId].predicate_comparison_function }}
                <strong>Value:</strong>
                {{ activePredicates[predicateId].predicate_value }}
              </template>
              <template v-if="activePredicates[predicateId].predicate_type === 'Target'">
                <strong>Target:</strong>
                {{ activeTargets[activePredicates[predicateId].predicate_key].target_name_description }}
                <strong>Comparison:</strong>
                {{ activePredicates[predicateId].predicate_comparison_function }}
                <strong>Value:</strong>
                {{ activePredicates[predicateId].predicate_value }}
              </template>
              <template v-if="activePredicates[predicateId].predicate_operation === '/'">
                <br><strong>OR</strong>
              </template>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon small class="mr-3" @click="cloneTarget">mdi-content-copy</v-icon>
          <v-icon v-if="!isTargetAutoGenerated(this.dialogTarget)" small class="mr-3" @click="editTarget">mdi-pencil</v-icon>
          <v-icon v-if="!isTargetAutoGenerated(this.dialogTarget)" small class="mr-3" @click="archieveTarget">mdi-delete</v-icon>
          <v-icon small class="mr-3" @click="dialogClose">mdi-cancel</v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar app bottom v-model="snackbarFlag" :timeout="snackbarTimeout" :color="snackbarColor">
      {{ snackbarText }}
      <template>
        <v-btn :color="snackbarColor + ' darken-1'" dark @click="snackbarFlag = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import * as API from "../utils/Api.js";
import * as Constants from "../utils/Constants.js";

export default {
  name: "searchTargets",
  data() {
    return {
      /*
      TargetName
      This already available in DataTable (based on counts) Type -> {Question, Target, Health, Derived} (IsEqualTo)
      TypeQuestion -> targets that contain this question (IsEqualTo)
      TypeTarget   -> targets that contain this target name (IsEqualTo)
      TODO: TypeHealth   -> targets that contain this health predicate key/name (IsEqualTo)
      TODO: TypeDerived  -> targets that contain this derived predicate key/name (IsEqualTo)
      NOTE: do not allow archieval/deletion of targets that are in use
      */
      searchFields: [
        "All Fields",
        "TargetName",
        "TargetDescription",
        "TypeQuestion",
        "TypeTarget",
        "TypeDerived"
      ],
      selectedSearchField: "All Fields",

      // placeholder operators, updates the selected operator via v-model
      operators: ["Contains", "IsEqualTo", "StartsWith", "EndsWith"],
      selectedOperator: "Contains",

      // applicable operators depending on they type, e.g text, float and constants
      textOperators: ["Contains", "StartsWith", "EndsWith", "IsEqualTo"],
      constantOperators: ["IsEqualTo"],
      searchableConstantOperators: [
        "IsEqualTo",
        "Contains",
        "StartsWith",
        "EndsWith",
      ],

      // initiallize search type and query
      // and also constants used for fixed fields (e.g surveyName, target etc)
      searchType: "text",
      searchQuery: "",
      constantItems: [],
      constantLabel: "",
      constantItemsMap: {},

      // targets returned based on above search
      targetIds: [], // array of target ids returned from db
      targets: {}, // key:target_id, value:target (fetched from db and predicate_ids attached)
      predicates: {}, // key:predicate_id, value:predicate (from db)

      // store all active questions, answers and targets to easily look them up in dialog box
      activeQuestions: {}, // key:question_id, value:question
      activeAnswers: {}, // key:answer_id, value:answer
      activeTargets: {}, // key:target_id, value:target
      activePredicates: {}, // key:predicate_id, value:predicate
      activeDeriveds: {}, // key:derived_id, value:derived

      // store targets that are in-use to avoid erroneous archievals or deletions
      // e.g do not allow if the target is used in question/answer (global) targets or in active surveys/predicates
      targetsInUse: {}, // key:target_id, value:target

      // create inverted indexes to lookup predicates that contain an item
      // where the item could be question, target, health or derived
      // so the keys can be [Question, QuestionText], [Target, TargetName], etc
      itemToPredicateMap: {}, // key:[type, text], value:set(predicate_id)

      // inverted index to lookup targets that contain a given predicate
      predicateToTargetMap: {}, // key:predicate_id, value:set(target_id)

      // inverted index to lookup targets that contain a given target
      targetToContainingTargetMap: {}, // key:target_id, value:set(target_id)

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "targetName",
        },
        {
          text: "Questions",
          align: "start",
          sortable: true,
          value: "targetQuestions",
        },
        {
          text: "Targets",
          align: "start",
          sortable: true,
          value: "targetTargets",
        },
        {
          text: "Health",
          align: "start",
          sortable: true,
          value: "targetHealth",
        },
        {
          text: "Derived",
          align: "start",
          sortable: true,
          value: "targetDerived",
        },
        {
          text: "ORs",
          align: "start",
          sortable: true,
          value: "orCount",
        },
        { text: "Actions", align: "start", sortable: false, value: "actions" },
      ],
      targetsDataTable: null,

      // dialog that shows target details, it allows deletion and can direct to edit page
      dialog: false,
      dialogTarget: null,
      dialogTargetId: null,
      dialogTitle: "Target Details",

      // snackbar to show a message based on actions taken in data table
      snackbarFlag: false,
      snackbarText: "",
      snackbarColor: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorSuccess: Constants.SNACKBAR_COLOR_SUCCESS,
      snackbarColorFailure: Constants.SNACKBAR_COLOR_FAILURE,
      snackbarTimeout: Constants.SNACKBAR_TIMEOUT,
    };
  },
  computed: {
    activePredicateIdsInTarget() {
      let activePredicateIds = [];
      let predicateIdStrings = this.dialogTarget.target_separated_predicate_ids.replace(/\//g, "+").split("+");
      predicateIdStrings.forEach((predicateIdString) => {
        activePredicateIds.push(parseInt(predicateIdString));
      });
      //console.log("..activePredicateIdsInTarget, activePredicateIds:", activePredicateIds);
      return activePredicateIds;
    }
  },
  created() {
    this.updateTargetNameConstants();
    this.updateTargetDescriptionConstants();
    this.updateActiveObjects();
    this.updateTargetsInUse();
  },
  methods: {
    getResults(response, fieldName) {
      return response.success && response.result && response.result.length > 0
        ? response.result.map((item) => item[fieldName])
        : [];
    },
    async updateTargetNameConstants() {
      // fetch and update Target Name values
      const response = await API.getDistinctValuesApi(
        Constants.TARGET_TABLE,
        Constants.TARGET_NAME
      );
      const results = this.getResults(response, Constants.TARGET_NAME);
      this.constantItemsMap["TargetName"] = results;
      //console.log("TargetName, ", this.constantItemsMap);
    },
    async updateTargetDescriptionConstants() {
      // fetch and update Target Name values
      const response = await API.getDistinctValuesApi(
        Constants.TARGET_TABLE,
        Constants.TARGET_DESCRIPTION
      );
      const results = this.getResults(response, Constants.TARGET_DESCRIPTION);
      this.constantItemsMap["TargetDescription"] = results;
      //console.log("TargetName, ", this.constantItemsMap);
    },
    async updateTargetsInUse() {
      // fetch targets that are used in other active answers/questions/surveys/predicates
      this.targetsInUse = {};
      var response = await API.getTargetsInUseApi();
      response.targets.forEach((target) => {
        this.targetsInUse[target.target_id] = target;
      });
      //console.log("this.targetsInUse:", this.targetsInUse);
    },
    async updateActiveObjects() {
      await this.searchActiveTargets();
      await this.searchActiveQuestions();
      await this.searchActivePredicates();
      await this.searchActiveDeriveds();
      // TODO: later addd searchActiveHealth()

      // populate this.constantItemsMap["TypeQuestions"] and other Type* here
      // so the drop down can show all questionTexts that belong to active predicates
      let typeQuestionIds = new Set();
      let typeTargetIds = new Set();
      let typeHealthIds = new Set();
      let typeDerivedIds = new Set();

      // populate item to predicate map so we can compute targets that contain this item
      let itemKey = [];
      this.itemToPredicateMap = {};
      for (var [predicateId, predicate] of Object.entries(this.activePredicates)) {
        if (predicate.predicate_type === "Question") {
          let questionId = predicate.predicate_key;
          let questionText = this.activeQuestions[questionId].question_text
          typeQuestionIds.add(questionId);
          itemKey = [predicate.predicate_type, questionText];
        } else if (predicate.predicate_type === "Target") {
          let targetId = predicate.predicate_key;
          let targetName = this.activeTargets[targetId].target_name;
          typeTargetIds.add(targetId);
          itemKey = [predicate.predicate_type, targetName];
        } else if (predicate.predicate_type === "Health") {
          typeHealthIds.add(predicate.predicate_key);
          // TODO: update for Health
        } else if (predicate.predicate_type === "Derived") {
          let derivedId = predicate.predicate_key;
          let derivedName = this.activeDeriveds[derivedId].derived_name
          typeDerivedIds.add(derivedId);
          itemKey = [predicate.predicate_type, derivedName];
        } else {
          throw new Error("Unknown target type:", predicate.predicate_type);
        }

        // update item (e.g type={question, health, target, derived}, text) to containing predicateIDs inverted index
        if (!(itemKey in  this.itemToPredicateMap)) {
          this.itemToPredicateMap[itemKey] = new Set();
        }
        this.itemToPredicateMap[itemKey].add(predicate.predicate_id);
      }
      //console.log("\nthis.itemToPredicateMap:", this.itemToPredicateMap);

      // populate target to containing targets map so we can support recursive target search
      this.updateContainedTargets();
      //console.log("\nthis.targetToContainingTargetMap:", this.targetToContainingTargetMap);

      // update constantItemsMap so questions/targets/etc show up in drop down menus in Type* lookups
      let typeQuestions = [];
      Array.from(typeQuestionIds).sort().forEach(questionId => {
        typeQuestions.push(this.activeQuestions[questionId].question_text);
      });
      //console.log("\ntypeQuestions:", typeQuestions);
      this.constantItemsMap["TypeQuestion"] = typeQuestions;

      let typeDeriveds = [];
      Array.from(typeDerivedIds).sort().forEach(derivedId => {
        typeDeriveds.push(this.activeDeriveds[derivedId].derived_name);
      });
      this.constantItemsMap["TypeDerived"] = typeDeriveds;

      let typeTargets = [];
      Array.from(typeTargetIds).sort().forEach(targetId => {
        typeTargets.push(this.activeTargets[targetId].target_name);
      });
      this.constantItemsMap["TypeTarget"] = typeTargets;
      //console.log("\ntypeTargets:", typeTargets);
      //console.log("\ntypeQuestions:", typeQuestions);
      //console.log("\ntypeDeriveds:", typeDeriveds);

      // TODO: later add support for TypeHealth
    },
    updateContainedTargets() {
      // add other targets if they contain any of these targets (recursive search)
      let updateFound = false;
      do {
        updateFound = false;
        for (var [containingTargetId, target] of Object.entries(this.activeTargets)) {
          let predicateIdStrings = target.target_separated_predicate_ids.replace(/\//g, "+").split("+");
          predicateIdStrings.forEach((predicateIdString) => {
            let predicateId = parseInt(predicateIdString);
            let predicate = this.activePredicates[predicateId];
            if (predicate.predicate_type === Constants.PREDICATE_TYPE_TARGET) {
              // targetId contains a Target predicate
              let containedTargetId = predicate.predicate_key;
              if (!(containedTargetId in this.targetToContainingTargetMap)) {
                this.targetToContainingTargetMap[containedTargetId] = new Set();
              }
              let containingTargets = this.targetToContainingTargetMap[containedTargetId];
              if (!(this.targetToContainingTargetMap[containedTargetId].has(parseInt(containingTargetId)))) {
                updateFound = true;
                this.targetToContainingTargetMap[containedTargetId].add(parseInt(containingTargetId));
              }
            }
          });
        }
      } while (updateFound);
    },
    async searchActiveTargets() {
      //  fetch all active targets (to easily look them up in dialog box)
      this.activeTargets = {};
      this.predicateToTargetMap = {};
      var response = await API.searchTargetsByColumnApi(
        Constants.TARGET_TABLE, "target_name", "LIKE", `%%`);
      response.targets.forEach((target) => {
        // update active targets map
        this.activeTargets[target.target_id] = target;

        // add special target_name_description which combines the two if description exists
        if (target.target_description && target.target_description.length > 0) {
          this.activeTargets[target.target_id].target_name_description = target.target_name +
            " (" + target.target_description + ")";
        } else {
          this.activeTargets[target.target_id].target_name_description = target.target_name;
        }

        // update predicate to containing targets inverted index
        let predicateIdStrings = target.target_separated_predicate_ids.replace(/\//g, "+").split("+");
        predicateIdStrings.forEach((predicateIdString) => {
          let predicateId = parseInt(predicateIdString);
          if (!(predicateId in this.predicateToTargetMap)) {
            this.predicateToTargetMap[predicateId] = new Set();
          }
          this.predicateToTargetMap[predicateId].add(target.target_id);
        });
      });
      //console.log("\nthis.activeTargets:", this.activeTargets);
      //console.log("\nthis.predicateToTargetMap:", this.predicateToTargetMap);
    },
    async searchActivePredicates() {
      //  fetch all active predicates (to easily look them up in dialog box)
      this.activePredicates = {};
      var response = await API.getActivePredicatesApi();
      response.result.forEach((predicate) => {
        // update active predicates
        this.activePredicates[predicate.predicate_id] = predicate;
      });
      //console.log("\nthis.activePredicates:", this.activePredicates);
    },
    async searchActiveQuestions() {
      //  fetch all active questions and answers (to easily look them up in dialog box)
      this.activeQuestions = {};
      var response = await API.searchQuestionsByAllTextColumnsApi("LIKE", `%%`);
      response.questions.forEach((question) => {
        this.activeQuestions[question.question_id] = question;
      });

      this.activeAnswers = {};
      response.answers.forEach((answer) => {
        this.activeAnswers[answer.answer_id] = answer;
      });
      //console.log("\nthis.activeAnswers:", this.activeAnswers);
    },
    async searchActiveDeriveds() {
      //  fetch all active derived predicates (to easily look them up in dialog box)
      this.activeDeriveds = {};
      var response = await API.getActiveDerivedsApi();
      response.result.forEach((derived) => {
        // update active derived predicates
        this.activeDeriveds[derived.derived_id] = derived;

        // add special derived_description which combines the two if description exists
        if (derived.derived_description && derived.derived_description.length > 0) {
          this.activeDeriveds[derived.derived_id].derived_name_description = derived.derived_name +
            " (" + derived.derived_description + ")";
        } else {
          this.activeDeriveds[derived.derived_id].derived_name_description = derived.derived_name;
        }
      });
      //console.log("\nthis.activeDeriveds:", this.activeDeriveds);
    },
    getSqlColumn() {
      if (this.selectedSearchField == "TargetName") {
        return "target_name";
      }
      if (this.selectedSearchField == "TargetDescription") {
        return "target_description";
      }
      return "unknown_column"; // error
    },
    getSqlOperator() {
      const op = this.selectedOperator;
      if (op == "EqualTo" || op == "IsEqualTo") {
        return "=";
      }
      if (op == "Contains" || op == "StartsWith" || op == "EndsWith") {
        return "LIKE";
      }
      return "unknown_operator"; // error
    },
    getSqlSearchQuery() {
      this.searchQuery = this.searchQuery.trim();
      if (this.selectedOperator == "Contains") {
        return `%${this.searchQuery}%`;
      }
      if (this.selectedOperator == "StartsWith") {
        return `${this.searchQuery}%`;
      }
      if (this.selectedOperator == "EndsWith") {
        return `%${this.searchQuery}`;
      }
      return this.searchQuery;
    },
    computeTargets() {
      // note: since Type* lookups require recursive searches, we compute them here vs in db query

      // first construct the item key
      console.assert(this.selectedSearchField.indexOf("Type") == 0);
      let predicateType = this.selectedSearchField.slice(4); // remove "Type" prefix
      let itemKey = [predicateType, this.searchQuery];
      let predicateIds = this.itemToPredicateMap[itemKey]

      // find all target ids that contain these predicates
      let targetIds = new Set();
      predicateIds.forEach(predicateId => {
        this.predicateToTargetMap[predicateId].forEach(targetId => {
          targetIds.add(targetId);
        });
      });

      // expand targets with containing targets (if any)
      let containingTargetsFound = false;
      do {
        containingTargetsFound = false;
        let containingTargetIds = new Set();
        targetIds.forEach(targetId => {
          if (targetId in this.targetToContainingTargetMap) {
            // this target is contained in other targets, check if they are already included
            this.targetToContainingTargetMap[targetId].forEach(containingTargetId => {
              if (!(targetIds.has(containingTargetId))) {
                // this is a new (containing) target
                containingTargetIds.add(containingTargetId);
              }
            });
          }
        });
        if (containingTargetIds.size > 0) {
          // add newly found containing to the output, it will recursively search for other containing ones (if any)
          containingTargetsFound = true;
          containingTargetIds.forEach(containingTargetId => {
            targetIds.add(containingTargetId);
          });
          containingTargetIds.clear();
        }
      } while (containingTargetsFound);

      // clear targets dictionary; insert retrieved targets with key target_id
      this.targets = {};
      this.targetIds = [];
      Array.from(targetIds).sort().forEach(targetId => {
        let target = this.activeTargets[targetId];
        this.targetIds.push(target.target_id);
        this.targets[target.target_id] = target;
      });
      this.predicates = this.activePredicates;
      //console.log("\n...computeTargets, this.targets:", this.targets);

      this.constructTargetsDataTable();
    },
    async searchTargets() {
      const field = this.selectedSearchField;
      var response;
      if (field === "All Fields") {
        console.log("calling searchTargetsByAllTextColumnsApi");
        response = await API.searchTargetsByAllTextColumnsApi(
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field === "TypeQuestion" ||
        field === "TypeTarget" ||
        field === "TypeHealth" ||
        field === "TypeDerived") {
          this.computeTargets();
          this.constructTargetsDataTable();
          return;
      } else if (field === "TargetName" || field === "TargetDescription") {
        response = await API.searchTargetsByColumnApi(
          Constants.TARGET_TABLE,
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      } else if (field.startsWith("Predicate")) {
        response = await API.searchTargetsByPredicateApi(
          this.getSqlColumn(),
          this.getSqlOperator(),
          this.getSqlSearchQuery()
        );
      }

      // clear targets dictionary; insert retrieved targets with key target_id
      this.targets = {};
      this.targetIds = [];
      response.targets.forEach((target) => {
        this.targetIds.push(target.target_id);
        this.targets[target.target_id] = target;
      });
      //console.log("\n...searchTargets, this.targets:", this.targets);

      // sort targetIds by target_name
      this.targetIds.sort((a,b)=> (this.targets[b].target_name > this.targets[a].target_name ? 1 : -1))

      // insert retrieved predicates with key predicate_id
      response.predicates.forEach((predicate) => {
        this.predicates[predicate.predicate_id] = predicate;
      });

      this.constructTargetsDataTable();
    },
    constructTargetsDataTable() {
      // construct targets Data Table to be shown as rows in table
      this.targetsDataTable = [];
      this.targetIds.forEach((targetId) => {
        var row = {};
        let target = this.targets[targetId];
        row["targetId"] = target.target_id;
        row["targetName"] = target.target_name;

        var targetQuestions = 0;
        var targetTargets = 0;
        var targetHealth = 0;
        var targetDerived = 0;
        var orCount = target.target_separated_predicate_ids.split("/").length - 1;

        let predicateIdStrings = target.target_separated_predicate_ids.replace(/\//g, "+").split("+");
        predicateIdStrings.forEach((predicateIdString) => {
          let predicateId = parseInt(predicateIdString);
          let predicate = this.predicates[predicateId];
          if (predicate.predicate_type === Constants.PREDICATE_TYPE_QUESTION) {
            targetQuestions += 1;
          } else if (predicate.predicate_type === Constants.PREDICATE_TYPE_TARGET) {
            targetTargets += 1;
          } else if (predicate.predicate_type === Constants.PREDICATE_TYPE_HEALTHKIT) {
            targetHealth += 1;
          } else if (predicate.predicate_type === Constants.PREDICATE_TYPE_DERIVED) {
            targetDerived += 1;
          } else {
            throw new Error("Unknown target type:", predicate.predicate_type);
          }
        });

        row["targetQuestions"] = targetQuestions;
        row["targetTargets"] = targetTargets;
        row["targetHealth"] = targetHealth;
        row["targetDerived"] = targetDerived;
        row["orCount"] = orCount;
        this.targetsDataTable.push(row);
      });
    },
    getDialogTargetDetails(target) {
      this.dialogTargetId = target.targetId;
      this.dialogTarget = { ...this.targets[this.dialogTargetId] };
      this.dialog = true;
      //console.log("..getDialogTargetDetails dialogTarget:", this.dialogTarget);
    },
    getAnswerTextCsvInDialogBox(predicateId) {
      let answerTexts = "";
      let predicateValues = this.activePredicates[predicateId].predicate_value.split(",");
      predicateValues.forEach(answerIdString => {
        let answerId = parseInt(answerIdString);
        let answerText = this.activeAnswers[answerId].answer_text || "";
        if (answerTexts.length > 0) {
          answerTexts += ", ";
        }
        answerTexts += answerText;
      });
      return answerTexts;
    },
    dialogClose() {
      this.dialog = false;
    },
    async cloneTarget(target) {
      if (target == null || !("targetId" in target)) {
        this.targetsDataTable.forEach((item) => {
          if (item.targetId === this.dialogTarget.target_id) {
            target = item;
          }
        });
      }
      //console.log("..clone \ntarget:", target);
      var confirmationText = "Clone this target and create a new one?";
      if (confirm(`${confirmationText}\n\nTarget: '${target.targetName}'`)) {
        this.$router.push({
          name: "AddTarget",
          params: { target_id: `${target.targetId}`, command: "clone" },
        });
      }
    },
    async editTarget(target) {
      if (target == null || !("targetId" in target)) {
        this.targetsDataTable.forEach((item) => {
          if (item.targetId === this.dialogTarget.target_id) {
            target = item;
          }
        });
      }
      //console.log("..clone \ntarget:", target);
      var confirmationText = "Edit this target?";
      if (confirm(`${confirmationText}\n\nTarget: '${target.targetName}'`)) {
        this.$router.push({
          name: "AddTarget",
          params: { target_id: `${target.targetId}`, command: "edit" },
        });
      }
    },
    async archieveTarget(target) {
      if (target == null || !("targetId" in target)) {
        this.targetsDataTable.forEach((item) => {
          if (item.targetId === this.dialogTarget.target_id) {
            target = item;
          }
        });
      }
      //console.log("..archieveTarget \ntarget:", target);
      var confirmationText = "Archieve this target?";
      if (confirm(`${confirmationText}\n\nTarget: '${target.targetName}'`)) {
        // first make sure this target is not in-use in active answers/questions/surveys/predicates
        if (target.targetId in this.targetsInUse) {
          this.snackbarText = "This target is in-use, remove before deletion/archieval!!";
          this.snackbarColor = this.snackbarColorFailure;
          this.snackbarFlag = true;
          return;
        }
        const response = await API.archieveTargetApi(
          target.targetId.toString()
        );
        //console.log("response:", JSON.stringify(response));
        if (response.success) {
          this.snackbarText = "Successfully archieved the target!";
          this.snackbarColor = this.snackbarColorSuccess;
        } else {
          this.snackbarText = "Failed to archieve the target!";
          this.snackbarColor = this.snackbarColorFailure;
        }
        this.snackbarFlag = true;
        this.resetDataTable();
        this.dialogClose();
      }
    },
    resetDataTable() {
      this.targetsDataTable = null;
      this.selectedSearchField = "All Fields";
      this.selectedOperator = "Contains";
      this.searchQuery = "";
      this.searchType = "text";
    },
    isTargetAutoGenerated(target) {
      if (target.targetName) {
        return target.targetName.startsWith("_");
      }
      if (target.target_name) {
        return target.target_name.startsWith("_");
      }
      return false;
    }
  },
  watch: {
    selectedSearchField: function (newField, oldField) {
      this.searchQuery = "";
      this.targetsDataTable = null;
      if (newField == "All Fields") {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      } else if (newField == "TargetName" || newField == "TargetDescription") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "searchableConstant";
        this.operators = this.searchableConstantOperators;
        this.selectedOperator = this.searchableConstantOperators[0];
      } else if (newField == "TypeQuestion" || newField == "TypeTarget" || newField == "TypeDerived") {
        this.constantLabel = newField;
        this.constantItems = this.constantItemsMap[newField] || [];
        this.searchType = "constant";
        this.operators = this.constantOperators;
        this.selectedOperator = this.constantOperators[0];
      } else {
        this.searchQuery = " ";
        this.searchType = "text";
        this.operators = this.textOperators;
        this.selectedOperator = this.textOperators[0];
      }
    },
    selectedOperator: function (newOperator, oldOperator) {
      this.searchQuery = "";
      this.targetsDataTable = null;
      //console.log("selectedOperator update, this.searchType:", this.searchType);
      if (newOperator == "IsEqualTo") {
        this.constantLabel = this.selectedSearchField;
        this.constantItems =
          this.constantItemsMap[this.selectedSearchField] || [];
        if (this.selectedSearchField == "TargetName") {
          this.searchType = "searchableConstant";
          this.operators = this.searchableConstantOperators;
        } else if (this.selectedSearchField == "TypeQuestion" || this.selectedSearchField == "TypeTarget") {
          this.searchType = "constant";
          this.operators = this.constantOperators;
        }
      } else if (
        newOperator == "Contains" ||
        newOperator == "StartsWith" ||
        newOperator == "EndsWith"
      ) {
        this.searchQuery = " ";
        this.searchType = "text";
      }
    },
    searchQuery: function (newQuery, oldQuery) {
      if (this.targetsDataTable != null) {
        this.targetsDataTable = null;
      }
      if (
        (this.searchType == "constant" ||
          this.searchType == "searchableConstant") &&
          newQuery &&
          newQuery.trim().length > 0
      ) {
        this.searchTargets();
      }
    },
  },
};
</script>
